import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getAllListData } from 'services/CommonServices';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';

// Sample data
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const CustomLegend = ({ payload, data }: any) => {
  const mergedArray = data.map(
    (item: { [s: string]: unknown } | ArrayLike<unknown>, index: string | number) => {
      const [status, count] = Object.entries(item)[0];
      return {
        ...payload[index],
        [status.toLowerCase()]: count,
      };
    },
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
      {mergedArray.map((entry: any, index: any) => (
        <div
          key={`item-${index}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 20,
            borderRadius: '5px',
            padding: '10px',
            backgroundColor: '#F2F2F7',
          }}>
          <div
            style={{
              width: 12,
              height: 12,
              backgroundColor: entry.color,
              marginRight: 5,
              borderRadius: '20%',
              marginBottom: '30px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <span style={{ color: '#000', fontWeight: 'bold', marginRight: 5 }}>{entry.value}</span>
            <span
              style={{
                color: '#EE4B22',
                fontWeight: 'bold',
                marginRight: 5,
                display: 'flex',
                justifyContent: 'center',
              }}>
              {entry.total}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const OfferData = ({ setLd, option }: any) => {
  const Today = new Date();
  const navigate = useNavigate();
  const [offerData, setOfferData] = useState<any>([]);
  const [totalCount, settotalCount] = useState<any>([
    {
      total: '',
    },
    { total: '' },
    { total: '' },
  ]);

  const OfferData = () => {
    setLd(true);
    const oneWeekBefore = new Date(Today);
    oneWeekBefore.setDate(Today.getDate() - 6);
    getAllListData(
      `${DASHBOARD_ENDPOINT.Offers}?type=${option.toLowerCase()}&fromDate=${moment(
        oneWeekBefore,
      ).format('YYYY-MM-DD')}&toDate=${moment(Today).format('YYYY-MM-DD')}`,
      true,
    )
      .then((val: any) => {
        const options: any = [];
        val.data.map((e: any) => {
          options.push({
            name: e.dayName,
            pending: Number(e.offerPending),
            accepted: Number(e.offerAccepted),
            rejected: Number(e.offerRejected),
          });
        });
        // const filteredResume = options.filter(
        //   (item: any) => item.offerPending > 0 && item.offerAccepted > 0 && item.offerRejected > 0,
        // );
        setOfferData(options);
        setLd(false);
        settotalCount([
          {
            total: val.total[0].offerPending,
          },
          { total: val.total[0].offerAccepted },
          { total: val.total[0].offerRejected },
        ]);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(false);
      });
  };
  //   const ResumeFinalData = offerData && offerData.length > 0;

  React.useEffect(() => {
    OfferData();
  }, [option]);
  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Offers</div>
      </header>
      {/* {!ResumeFinalData ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '225px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          No data available
        </p> // Placeholder message
      ) : ( */}
      <main style={{ marginTop: '50px' }}>
        <div
          className='donutchartout'
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '50px',
            height: '300px',
          }}>
          <ResponsiveContainer width='100%' height={400}>
            <BarChart
              data={offerData}
              barSize={50}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis axisLine={false} />
              <Tooltip />
              <Legend content={<CustomLegend data={totalCount} />} />
              <Bar
                dataKey='pending'
                stackId='a'
                fill='#4A90E2'
                name='Offer Pending'
                label={{ position: 'center', fill: '#000', fontSize: '14px', fontWeight: '600' }}
              />
              <Bar
                dataKey='accepted'
                stackId='a'
                fill='#7ED321'
                name='Offer Accepted'
                label={{ position: 'center', fill: '#000', fontSize: '14px', fontWeight: '600' }}
              />
              <Bar
                dataKey='rejected'
                stackId='a'
                fill='#F5A623'
                name='Offer Rejected'
                label={{ position: 'center', fill: '#000', fontSize: '14px', fontWeight: '600' }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </main>
      {/* )} */}
    </div>
  );
};

export default OfferData;
