import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material';
import Closepng from 'assets/png/close.svg';
import _ from 'lodash';
import { useEffect, useState } from 'react';
type InputProps = {
  open: boolean;
  handleClose: () => void;
  setIsIgnore?: (val: boolean) => void;
  data: any;
  message: string;
  duplicationStatus: string;
  setResumeUploadOpen?: any;
  setAddResumeModalOpen?: any;
  id?: any;
};

const ResumeDuplication = (props: InputProps) => {
  const {
    open,
    handleClose,
    data,
    message,
    duplicationStatus,
    setResumeUploadOpen,
    setAddResumeModalOpen,
    id,
  } = props;
  const [duplicationData, setDuplicationData] = useState<{
    newArr: Array<{ key: string; value: string }>;
    existArr: Array<{ key: string; value: string }>;
  }>({
    newArr: [],
    existArr: [],
  });

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const { duplicateResume, newResume } = data;

      // new data
      const newArr = [
        {
          key: 'Client Name',
          value: newResume?.client?.corporateName,
        },
        {
          key: 'Position',
          value: newResume?.position?.title,
        },
        {
          key: 'Recruiter Name',
          value: newResume?.userInfo?.firstName
            ? `${newResume?.userInfo?.firstName} ${
                newResume?.userInfo?.lastName ? newResume?.userInfo?.lastName : ''
              }`
            : '-',
        },
        {
          key: 'Recruiter Location',
          value: newResume?.userInfo?.city,
        },
        {
          key: 'Recruiter Contact',
          value: newResume?.userInfo?.phoneNumber
            ? `${newResume?.userInfo?.countryCode} ${newResume?.userInfo?.phoneNumber}`
            : '-',
        },
        {
          key: 'Sent Date',
          value: '--',
        },
        {
          key: 'Current Status',
          value: 'Ready For Review',
        },
      ];

      // existdata
      const existArr = [
        {
          key: 'Client Name',
          value: duplicateResume?.client?.corporateName,
        },
        {
          key: 'Position',
          value: duplicateResume?.position?.title,
        },
        {
          key: 'Recruiter Name',
          value: duplicateResume?.user?.firstName
            ? `${duplicateResume?.user?.firstName} ${
                duplicateResume?.user?.lastName ? duplicateResume?.user?.lastName : ''
              }`
            : '-',
        },
        {
          key: 'Recruiter Location',
          value: duplicateResume?.user?.city,
        },
        {
          key: 'Recruiter Contact',
          value: duplicateResume?.user?.phoneNumber
            ? `${duplicateResume?.user?.countryCode} ${duplicateResume?.user?.phoneNumber}`
            : '-',
        },
        {
          key: 'Sent Date',
          value: '--',
        },
        {
          key: 'Current Status',
          value: message,
        },
      ];

      setDuplicationData({ newArr, existArr });
    }
  }, [data]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className='resume-duplication-modal'>
        <Typography component={'header'}>
          <img src={Closepng} alt='close' className='closepng' onClick={handleClose} />
        </Typography>
        <Typography
          sx={{
            width: '779px',
            height: '40px',
            marginTop: '16px',
            backgroundColor: '#FDEDE9',
            borderRadius: '8px',
            color: '#EE4B22',
            paddingTop: '9px',
            paddingLeft: '12px',
            fontSize: '15px',
            fontWeight: 'bold',
          }}>
          {duplicationStatus === 'others'
            ? `Oops! The resume is already in ${message} status`
            : 'Oops! The resume is already uploaded by someone'}
        </Typography>

        <Typography
          sx={{ marginTop: '32px', fontSize: '20px', fontWeight: '600', color: '#344051' }}>
          Resume Status
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: '24px', paddingTop: 0 }} md={12}>
          {!_.isEmpty(duplicationData.newArr) &&
            duplicationData.newArr.map((e, i) => (
              <Grid
                item
                md={6}
                sm={12}
                sx={{ display: 'flex', paddingTop: '0px !important', marginBottom: '8px' }}
                key={i}>
                <Typography
                  sx={{
                    border: '1px solid #E4E7EC',
                    display: 'flex',
                    alignItems: 'center',
                    width: '170px',
                    height: '40px',
                    fontSize: '12px',
                    justifyContent: 'flex-start',
                    fontWeight: 500,
                    color: '#344051',
                    backgroundColor: '#F2F4F7',
                    paddingLeft: '5px',
                  }}>
                  {e.key}
                </Typography>
                <Typography
                  sx={{
                    border: '1px solid #E4E7EC',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    paddingLeft: '10px',
                    fontSize: '12px',
                    color: '#344051',
                    textTransform: 'capitalize',
                  }}>
                  {e.value}
                </Typography>
              </Grid>
            ))}
        </Grid>

        <Divider sx={{ marginTop: '16px' }} />

        <Grid container spacing={2} sx={{ marginTop: '24px' }} md={12}>
          {!_.isEmpty(duplicationData.existArr) &&
            duplicationData.existArr.map((e, i) => (
              <Grid
                item
                md={6}
                sm={12}
                sx={{ display: 'flex', paddingTop: '0px !important', marginBottom: '8px' }}
                key={i}>
                <Typography
                  sx={{
                    border: '1px solid #E4E7EC',
                    display: 'flex',
                    alignItems: 'center',
                    width: '170px',
                    height: '40px',
                    fontSize: '12px',
                    justifyContent: 'flex-start',
                    fontWeight: 500,
                    color: '#344051',
                    backgroundColor: '#F2F4F7',
                    paddingLeft: '5px',
                  }}>
                  {e.key}
                </Typography>
                <Typography
                  sx={{
                    border: '1px solid #E4E7EC',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    paddingLeft: '10px',
                    fontSize: '12px',
                    color: '#344051',
                    textTransform: 'capitalize',
                  }}>
                  {e.value}
                </Typography>
              </Grid>
            ))}
        </Grid>

        <Divider sx={{ marginTop: '16px' }} />

        <Typography className='d-flex-a flex-end mt-30'>
          <Button
            className='s-cancel-btn mr-16'
            onClick={() => {
              // setIsIgnore(false);
              handleClose();
            }}>
            Cancel
          </Button>
          {duplicationStatus === 'rejected' && (
            <Button
              className='resume-ignore-btn mr-16'
              onClick={() => {
                // setIsIgnore(true);
                setAddResumeModalOpen({ open: true, id: id });
                handleClose();
              }}>
              Ignore and Proceed
            </Button>
          )}
          <Button
            className='resume-save-btn'
            onClick={() => {
              // setIsIgnore(false);
              setResumeUploadOpen({ open: true, id: id });
              handleClose();
            }}>
            Let me choose another resume
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ResumeDuplication;
