import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  // Pagination,
  // PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { VERSIONHISTORYENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { useDebounce } from 'hooks';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import Paginate from 'components/Pagination/Pagination';
import moment from 'moment';
import ExcelIcon from 'assets/iconcomponents/excelicon';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  sortby?: string | null;
  take?: number | null;
}

const Defaultparam = {
  search: null,
  order: 'desc',
  sortby: 'createdAt',
  skip: 0,
  take: 50,
};

export const VersionHistoryList = () => {
  const {
    userInfo: { organizationId: orgdy, roleId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [versionGetData, setVersionGetData] = useState<any>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [loader, setLoader] = useState<any>(false);
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);

  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${debouncedParams.search}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${VERSIONHISTORYENDPOINT.versionHistory}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res) => {
        setpagetotal(Number(res.total));
        setVersionGetData(res && res.data);
        setLoader(false);
      })
      .catch(() => {
        // console.log(e);
        setLoader(false);
      });
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  useEffect(() => {
    getList();
  }, [debouncedParams]);
  useEffect(() => {
    if (roleId != 15) {
      navigate('/');
    }
  }, []);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Version History
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'> Version History</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <div className='expot-icon'>
              <span>
                <ExcelIcon
                  url={`${VERSIONHISTORYENDPOINT.versionHistory}${
                    Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
                  }${[
                    `${orgdy ? `organizationId=${orgdy}` : null}`,
                    `${debouncedParams.search ? `search=${debouncedParams.search}` : null}`,
                    `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
                    `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
                    `${
                      debouncedParams.skip
                        ? `skip=${debouncedParams.skip}`
                        : debouncedParams.take
                          ? 'skip=0'
                          : null
                    }`,
                    `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
                    'isExport=true',
                  ]
                    .filter((ea: any) => ea !== 'null')
                    .join('&')}`}
                  fileName={'Version History'}
                  setLoader={setLoader}
                />
              </span>
            </div>
            <Button className='add-btn' onClick={() => navigate('/version-history/add')}>
              <img src={AddIcon} alt='plus' /> Add Version
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <TextField
              className='custom-search'
              placeholder='Search'
              id='outlined-start-adornment'
              value={params.search}
              sx={{ width: '320px' }}
              onChange={(e: any) =>
                setParams((prev: any) => ({
                  ...prev,
                  search: e.target.value,
                  skip: 0,
                  take: 50,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='search' />
                  </InputAdornment>
                ),
                endAdornment: params.search ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => {
                        setParams(() => ({ ...Defaultparam, search: '' }));
                      }}>
                      <CloseIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Typography component={'div'} className='mt-32'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No.</TableCell>
                      <TableCell>
                        Version Name{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('versionId', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('versionId', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Description
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('releaseNotes', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('releaseNotes', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Release Date{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('createdAt', 'desc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('createdAt', 'asc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {versionGetData && versionGetData.length ? (
                      versionGetData.map((row: any, i: number) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell sx={{ textTransform: 'capitalize' }}>{i + 1}</TableCell>
                          <TableCell
                            sx={{
                              textTransform: 'capitalize',
                            }}>
                            <div
                              style={{
                                cursor: 'pointer',
                                color: '#5F2EE5',
                                fontSize: '14px',
                                maxWidth: '200px',
                              }}
                              onClick={() =>
                                navigate(
                                  `${AdminPrivateRoutes.VERSIONHISTORY.VERSIONHISTORYVIEW}/${row.id}`,
                                )
                              }>
                              {row.versionId ? `Version ${row.versionId}` : '-'}
                            </div>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                fontSize: '14px',
                              }}>
                              {row.releaseNotes
                                ? row?.releaseNotes?.slice(0, 50).concat('...')
                                : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                fontSize: '14px',
                              }}>
                              {row.updatedAt ? moment(row?.updatedAt).format('DD-MM-YYYY') : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <img
                              src={EditIcon}
                              alt='Edit'
                              className='pointer'
                              onClick={() =>
                                navigate(
                                  `${AdminPrivateRoutes.VERSIONHISTORY.VERSIONHISTORYEDIT}/${row.id}`,
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={6} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Grid container className='mt-24'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
