export const PublicRoutes = {
  LOGIN: '/login',
  RESET: '/reset',
  RESETPASSWORD: '/password',
  FORGOTPASSWORD: '/forgot-password',
  VERIFYOTP: '/verify-otp',
  GENERATEOTP: '/generate-otp',
  CREATEPASSWORD: '/create-password',
};

export const PrivateRoutes = {
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  CHANGEPASSWOD: '/change-password',
};

export const AdminPrivateRoutes = {
  PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  CHANGEPASSWOD: '/change-password',
  CLIENTS: {
    CLIENTVIEW: '/clients',
    CLIENTADD: '/clients/add',
    CLIENTAPPROVE: '/clients/approve',
    CLIENTVIEWDETAILS: '/clients/view',
  },
  RESUMES: {
    RESUMESLIST: '/resumes',
    RESUMESEDIT: '/resumes/edit',
    RESUMESVIEW: '/resumes/view',
    RESUMESADD: '/resumes/add',
    RESUMESAPPROVE: '/resumes/approve',
  },
  INTERVIEW: {
    INTERVIEWLIST: '/interviews',
    INTERVIEWVIEW: '/interviews/view',
  },
  MAILBOX: {
    MAILBOXLIST: '/mail-box',
    MAILBOXVIEW: '/mail-box/view',
  },
  PAYMENTMAIL: {
    PAYMENTMAILLIST: '/payment-mail',
    PAYMENTMAILVIEW: '/payment-mail/view',
  },
  CLIENTAGREEMENT: {
    CLIENTAGREEMENTLIST: '/client-agreement',
    CLIENTAGREEMENTVIEW: '/client-agreement/view',
  },
  BILLING: {
    BILLINGLIST: '/billing',
    BILLINGADD: '/billing/add',
    BILLINGVIEW: '/billing/view',
    BILLINGEDIT: '/billing/edit',
    BILLINGAPPROVE: '/billing/approve',
  },
  MyPlan: {
    MyPlanVIEW: '/myplan',
    MyPlanADD: '/myplan/add',
    MyPlanAPPROVE: '/myplan/approve',
  },
  MyLeave: {
    ApproveDetails: '/myleave/approve-list',
    ViewList: '/myleave',
  },
  MyEvents: {
    EventsList: '/events',
    EventsView: '/events/view',
    EventsEdit: '/events/edit',
    EventsAdd: '/events/add',
  },
  CvBank: {
    CvBankDetails: '/CvBank',
  },
  POSITIONS: {
    POSITIONSVIEW: '/positions',
    POSITIONSADD: '/positions/add',
    VIEWPOSITIONS: '/positions/view',
    POSITIONSAPPROVE: '/positions/approve',
    CRMAPPROVAL: '/positions/CRM-Approval',
  },
  SETTINGS: {
    DailyLoginDetails: '/settings/dailylogindetails',
    FUNCTIONAL_AREA: {
      FUNCTIONALAREALIST: '/settings/functional-area',
      FUNCTIONALAREAADD: '/settings/functional-area/add',
      FUNCTIONALAREAEDIT: '/settings/functional-area/edit',
    },
    Designation: {
      DesignationLIST: '/settings/designation',
      DesignationADD: '/settings/designation/add',
      DesignationEDIT: '/settings/designation/edit',
    },
    QUALIFICATION: {
      QUALIFICATIONLIST: '/settings/qualification',
      QUALIFICATIONADD: '/settings/qualification/add',
      QUALIFICATIONEDIT: '/settings/qualification/edit',
    },
    BRANCHES: {
      BRANCHESLIST: '/settings/branches',
      BRANCHESADD: '/settings/branches/add',
      BRANCHESEDIT: '/settings/branches/edit',
    },
    ROLE: {
      ROLELIST: '/settings/roles',
      ROLEADD: '/settings/roles/add',
      ROLEEDIT: '/settings/roles/edit',
    },
    USERS: {
      USERSLIST: '/settings/users',
      USERSADD: '/settings/users/add',
      USERSEDIT: '/settings/users/edit',
    },
    EMAILTEMPLATE: {
      EMAILTEMPLATELIST: '/settings/email-template',
    },
    PRODUCTADMINISTRATOR: {
      PRODUCTADMINISTRATORLIST: '/settings/product-administrator',
      PRODUCTADMINISTRATORADD: '/settings/product-administrator/add',
      PRODUCTADMINISTRATOREDIT: '/settings/product-administrator/edit',
    },
    LEAVE: {
      HOLIDAY: {
        HOLIDAYLIST: '/settings/leave/holiday',
        HOLIDAYADD: '/settings/leave/holiday/add',
        HOLIDAYEDIT: '/settings/leave/holiday/edit',
      },
      IMPORTLEAVE: {
        IMPORTLEAVELIST: '/settings/leave/import',
      },
      LEAVEPOLICY: {
        LIST: '/settings/leave/leave-policy',
        ADD: '/settings/leave/leave-policy/add',
        EDIT: '/settings/leave/leave-policy/edit',
      },
    },
    INDUSTRY: {
      INDUSTRYLIST: '/settings/industry',
      INDUSTRYADD: '/settings/industry/add',
      INDUSTRYEDIT: '/settings/industry/edit',
    },
  },
  VERSIONHISTORY: {
    VERSIONHISTORYLIST: '/version-history',
    VERSIONHISTORYADD: '/version-history/add',
    VERSIONHISTORYEDIT: '/version-history/edit',
    VERSIONHISTORYVIEW: '/version-history/view',
  },
  REPORTS: {
    OPENINGHANDLE: '/reports/opening-handle',
    CVSTATUS: '/reports/cv-status',
    TAKTIME: '/reports/tak-time',
  },
};
