/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Link,
  Typography,
  Box,
  Modal,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomSelect from 'components/CustomSelect/customSelect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { addNewData, getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { rolesFormSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import _ from 'lodash';
import { SIDE_MENUS } from 'store/types/UserTypes';
import Storage from 'utils/Storage';
import Loader from 'components/loader';
import CloseIcon from 'assets/png/close.svg';
import { alertOptions } from 'utils/Config';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AccessDenied } from 'utils/helper';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #C7CDD8',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #C7CDD8',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const RoleAdd = () => {
  const { menuList }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const [userFunctionalList, setUserFunctionalList] = useState([]);
  const [userFunctionaId, setUserFunctionalId] = useState('');
  const [roleId, setRolelId] = useState('');
  const [userRolesList, setUserRolesList] = useState([]);
  const [moduleScreenList, setModuleScreenList] = useState([]);
  const [permission, setPermission] = useState<any>([]);
  const [createRole, setCreateRole] = useState<any>('');
  const [loader, setLoader] = useState<any>(false);
  const [adminopen, setAdminOpen] = useState(false);
  const [addrole, setAddrole] = useState(false);
  const handleOpen = () => setAdminOpen(true);
  const handleClose = () => setAdminOpen(false);
  const location = useLocation();
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    // getValues,
    // setError,
    // watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      userFunctionalroleId: '',
      roleId: '',
      description: '',
      status: 'active',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(rolesFormSchema),
  });
  const userFunctionalRoles = () => {
    getAllListData(
      `${SETTING_ENDPOINT.userFunctionalRoles}?code&name&search&status&order=asc&sortby=name&skip&take`,
      true,
    )
      .then((res) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setUserFunctionalList(options);
      })
      .catch((e) => {
        // console.log(e);
      });
  };
  const getInitialStatus = () => {
    if (editId) {
      getAllListData(
        `${SETTING_ENDPOINT.roles}?id=${editId}&userFunctionalRoleId&name&search&status&order&sortby&skip&take`,
        true,
      )
        .then((resp: any) => {
          // console.log(resp.data[0]);

          if (resp && !_.isEmpty(resp.data)) {
            setValue('userFunctionalroleId', resp.data[0].userFunctionalRoleId);
            setUserFunctionalId(resp.data[0].userFunctionalRoleId);
            setValue('roleId', resp.data[0].id);
            setRolelId(resp.data[0].id);
            setValue('description', resp.data[0].description);
            setValue('status', resp.data[0].status ? 'active' : 'inactive');
          }
          setLoader(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
          console.log(e);
        });
    }
  };
  const valueUpdate = (checked: boolean, moduleId: number, screenId: number, type: string) => {
    const fIndex =
      permission?.length && permission.findIndex((item: any) => item.screenId === screenId);
    if (fIndex !== -1 && permission?.length) {
      permission[fIndex].access = type === 'access' ? checked : false;
      permission[fIndex].deny = type === 'deny' ? checked : false;
    } else {
      permission.push({
        moduleId: moduleId,
        screenId: screenId,
        access: type === 'access' ? checked : false,
        deny: type === 'deny' ? checked : false,
      });
    }
    setPermission([...permission]);
  };
  const module = () => {
    if (roleId !== 'addRole' && roleId !== '') {
      setLoader(true);
      getAllListData(
        `${SETTING_ENDPOINT.moduleScreenList}?roleId=${roleId}&order=asc&sortby=order&skip&take`,
        true,
      )
        .then((res: any) => {
          setModuleScreenList(res ? res.data : []);
          const Arr: any = [];
          res.data.map((re: any) => {
            re.screens.map((se: any) => {
              if (se.permission && Object.keys(se.permission).length) Arr.push(se.permission);
            });
          });
          setPermission(Arr);
          setLoader(false);
        })
        .catch((e: any) => {
          // console.log(e);
          setLoader(false);
        });
    }
  };
  useEffect(() => {
    module();
  }, [roleId]);

  const onSubmitForm = (data: any) => {
    if (!_.isEmpty(permission) && permission.filter((re: any) => re.access || re.deny)?.length) {
      setLoader(true);
      addNewData(
        {
          roleId: data.roleId ? data.roleId : '',
          userFunctionalRoleId: data.userFunctionalroleId,
          description: data.description,
          status: data.status === 'active' ? true : false,
          permissions: permission,
          toastType: location?.state?.toastType,
        },
        `${String(SETTING_ENDPOINT.rolesPermission)}`,
        undefined,
        true,
      )
        .then(() => {
          resetForm();
          if (Number(data.roleId) !== Number(roleId)) {
            menuUpdate();
          }
          getInitialStatus();
          module();
          setLoader(false);
          setPermission('');
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          // console.log(e);
          setLoader(false);
        });
    } else {
      toast.error('Role cannot be created without any permission', alertOptions);
    }
  };
  const menuUpdate = () => {
    setLoader(true);
    getAllListData(
      `${SETTING_ENDPOINT.moduleScreenList}?roleId=${roleId}&access=true&order=asc&sortby=order&skip&take`,
      true,
    )
      .then((res: any) => {
        if (!menuList || menuList?.length === 0 || !_.isEqual(res?.data, menuList)) {
          dispatch({
            type: SIDE_MENUS,
            payload: res?.data,
          });
          Storage.setItem(Storage.KEYS.MENUS, JSON.stringify(res?.data));
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const roleCreate = () => {
    setLoader(true);
    if (createRole) {
      const value = createRole.trim();
      addNewData(
        {
          userFunctionalRoleId: userFunctionaId,
          name: createRole,
          code: value.toLowerCase(),
        },
        `${String(SETTING_ENDPOINT.roles)}`,
        {},
        true,
      )
        .then(() => {
          setLoader(false);
          setAddrole(true);
          handleClose();
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    } else {
      setLoader(false);
      toast.error('Role is required', alertOptions);
    }
  };
  useEffect(() => {
    if (userFunctionaId) {
      setLoader(true);
      getAllListData(
        `${SETTING_ENDPOINT.roles}?userFunctionalRoleId=${userFunctionaId}&name&search&status&order=asc&sortby=name&skip&take`,
        true,
      )
        .then((res) => {
          const options: any = [];
          res.data.map((e: any) => {
            options.push({ label: e.id === 15 ? 'Administrator' : e.name, value: e.id });
          });
          options.push({
            label: '+ Add Role',
            value: 'addRole',
            isDisabled: true,
            isFixed: true,
            isSelectable: false,
            custmdwn: true,
          });
          setUserRolesList(options);
          setLoader(false);
          setAddrole(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          // console.log(e);
          setLoader(false);
        });
    }
  }, [userFunctionaId, addrole]);
  useEffect(() => {
    userFunctionalRoles();
    // getModuleScreen();
    getInitialStatus();
  }, [userFunctionaId]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Link
              underline='hover'
              color='inherit'
              href={''}
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.ROLE.ROLELIST)}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Role
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              {editId ? 'Edit' : 'Add'} Role
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>{editId ? 'Edit' : 'Add'} Role</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='cancel-btn mr-16'
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.ROLE.ROLELIST)}>
              {' '}
              Back
            </Button>
            <Button className='s-add-btn' type='submit' form={'role-submit'}>
              {' '}
              Save
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <form className='private-form' id={'role-submit'} onSubmit={handleSubmit(onSubmitForm)}>
              <Grid container>
                <Grid item md={7} sm={12} xs={12}>
                  <Grid container columnSpacing={4.25}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          User Functional Role <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='userFunctionalroleId'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={userFunctionalList}
                                disabled={editId ? true : false}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e), setUserFunctionalId(e.target.value);
                                  setRolelId('');
                                }}
                                placeHolder='Select your functional role type'
                                error={errors.userFunctionalroleId?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Role <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='roleId'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={userRolesList}
                                disabled={editId ? true : false}
                                name={name}
                                value={value}
                                custmfn={handleOpen}
                                onChange={(e) => {
                                  onChange(e), setRolelId(e.target.value);
                                }}
                                placeHolder='Select your role type'
                                error={errors.roleId?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Description <span>*</span>
                        </Typography>
                        <Controller
                          name='description'
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <CustomInput
                              value={value}
                              name={name}
                              isTextArea={true}
                              className='custom-input'
                              placeHolder='Write your content here'
                              error={errors.description?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Status <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=''
                          name='status'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel
                                  value='active'
                                  control={<Radio />}
                                  label='Active'
                                  checked={value === 'active'}
                                />
                                <FormControlLabel
                                  value='inactive'
                                  control={<Radio />}
                                  label='Inactive'
                                  checked={value === 'inactive'}
                                />
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {roleId && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography component={'div'} className='mt-32'>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          className='custom-table role-tbody'
                          aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: '31%' }} className='pl-123'>
                                Module
                              </TableCell>
                              <TableCell sx={{ paddingLeft: 'unset !important', width: '26%' }}>
                                Screens
                              </TableCell>

                              <TableCell
                                // align='center'
                                sx={{
                                  width: '21%',
                                }}>
                                Access
                              </TableCell>
                              {/* <TableCell
                                // align='center'
                                sx={{
                                  width: '22%',
                                }}>
                                Deny
                              </TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={4} sx={{ padding: 'unset !important' }}>
                                {moduleScreenList && moduleScreenList.length
                                  ? moduleScreenList.map((row: any) => (
                                      <Grid container className='cel-bor' key={row}>
                                        <Grid item md={4.75}>
                                          <Typography
                                            component={'div'}
                                            className='cell-per-box'
                                            sx={{ paddingLeft: '120px' }}>
                                            {row.moduleName ? row.moduleName : '-'}
                                          </Typography>
                                        </Grid>
                                        <Grid item md={7.25} className='pad-bor'>
                                          {row.screens &&
                                            row.screens.map((data: any) => {
                                              const fIndex =
                                                permission?.length &&
                                                permission.findIndex(
                                                  (item: any) => item.screenId === data.id,
                                                );
                                              let checkedAccess = false;
                                              let checkedDeny = false;
                                              if (fIndex !== -1) {
                                                checkedAccess = permission[fIndex]?.access;
                                                checkedDeny = permission[fIndex]?.deny;
                                              }
                                              return (
                                                <Grid
                                                  container
                                                  key={data}
                                                  className='inpad-bor'
                                                  md={12}>
                                                  <Grid item md={7}>
                                                    <Typography
                                                      component={'div'}
                                                      className='cell-per-box'>
                                                      <Typography>{data.screenName}</Typography>
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item md={5}>
                                                    <Typography
                                                      component={'div'}
                                                      className='cell-per-box'>
                                                      <Typography className='d-flex-a'>
                                                        <Checkbox
                                                          sx={{ padding: 'unset' }}
                                                          checked={checkedAccess}
                                                          checkedIcon={<CheckedIcon />}
                                                          icon={<CheckIcon />}
                                                          onChange={(e) =>
                                                            valueUpdate(
                                                              e.target.checked,
                                                              data.moduleId,
                                                              data.id,
                                                              'access',
                                                            )
                                                          }
                                                        />
                                                      </Typography>
                                                    </Typography>
                                                  </Grid>
                                                  {/* <Grid item md={3.5}>
                                                    <Typography
                                                      component={'div'}
                                                      className='cell-per-box'>
                                                      <Typography className='d-flex-a'>
                                                        <Checkbox
                                                          sx={{ padding: 'unset' }}
                                                          checked={checkedDeny}
                                                          checkedIcon={<CheckedIcon />}
                                                          icon={<CheckIcon />}
                                                          onChange={(e) =>
                                                            valueUpdate(
                                                              e.target.checked,
                                                              data.moduleId,
                                                              data.id,
                                                              'deny',
                                                            )
                                                          }
                                                        />
                                                      </Typography>
                                                    </Typography>
                                                  </Grid> */}
                                                </Grid>
                                              );
                                            })}
                                        </Grid>
                                      </Grid>
                                    ))
                                  : ''}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Typography>
        </Typography>
        <Modal
          open={adminopen}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box className='logout-modal'>
            <Typography className='text-right'>
              <img src={CloseIcon} alt='close' onClick={handleClose} />
            </Typography>
            <Typography className='l-title'>Add Role</Typography>
            <Grid container className='private-form'>
              <Grid item md={12} sm={12} xs={12} sx={{ padding: '20px 100px 16px 100px' }}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'} sx={{ marginRight: '10px' }}>
                    New Role
                  </Typography>
                  <CustomInput
                    className='custom-input'
                    placeHolder='Enter the New Role'
                    onChange={(e) => {
                      setCreateRole(e.target.value);
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Typography className='d-flex-ja mb-24'>
              <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                Cancel
              </Button>
              <Button className='l-save-btn' onClick={roleCreate}>
                save
              </Button>
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default RoleAdd;
