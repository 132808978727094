import * as React from 'react';
const CandidateJoined = () => (
  <svg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_12086_12413)'>
      <path
        d='M8.6211 21.3248C8.61735 17.6297 11.6087 14.6421 15.3113 14.6421C18.999 14.6421 21.994 17.6222 22.0015 21.2949C22.009 24.9863 19.0177 27.9925 15.3263 28C11.6311 28.0075 8.62858 25.0162 8.6211 21.3248ZM13.7651 23.2304C13.2297 22.5827 12.7281 21.9613 12.2114 21.3548C12.1141 21.2424 11.9643 21.1226 11.8333 21.1114C11.721 21.1002 11.5226 21.22 11.4926 21.3211C11.4552 21.4558 11.4963 21.6655 11.5862 21.7778C12.1253 22.4629 12.6906 23.1331 13.2447 23.807C13.5817 24.215 13.84 24.2263 14.2293 23.8481C15.4498 22.6726 16.6703 21.497 17.887 20.3177C18.2764 19.9396 18.6695 19.5652 19.0551 19.1796C19.2236 19.0111 19.2386 18.8164 19.0701 18.6405C18.9016 18.4683 18.7069 18.4832 18.5272 18.633C18.4299 18.7154 18.34 18.809 18.2502 18.8988C16.7639 20.3364 15.2776 21.7703 13.7651 23.2304Z'
        fill='#637083'
      />
      <path
        d='M7.91817 22.4067C7.78714 22.4067 7.69354 22.4067 7.59995 22.4067C5.19267 22.4067 2.78166 22.403 0.374382 22.4142C0.082364 22.4142 0 22.3356 0 22.0399C0.0112314 19.7711 0 17.4986 0.00748763 15.2299C0.0112314 12.4707 1.99171 10.4902 4.74341 10.4864C6.94852 10.4864 9.15737 10.4752 11.3625 10.4864C13.3467 10.4977 15.0239 11.6545 15.6978 13.4515C15.7465 13.5788 15.7802 13.7136 15.8326 13.8783C13.4628 13.7885 11.4448 14.5447 9.84249 16.2669C8.24763 17.9853 7.6299 20.0369 7.91817 22.4105V22.4067Z'
        fill='#637083'
      />
      <path
        d='M12.303 4.31658C12.2805 6.75755 10.3637 8.64818 7.93394 8.62946C5.56785 8.607 3.68845 6.65647 3.70717 4.24171C3.72963 1.90182 5.68016 -0.0150102 8.02753 -3.49168e-05C10.3936 0.0149403 12.3254 1.96547 12.303 4.31658Z'
        fill='#637083'
      />
    </g>
    <defs>
      <clipPath id='clip0_12086_12413'>
        <rect width='22.0024' height='28' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default CandidateJoined;
