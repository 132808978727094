import React from 'react';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';

const CustomLabel = (props: any) => {
  const { x, y, value, name } = props;
  console.log(props, 'props');

  return (
    <text
      x={x + 8} // Adjust X position to the center of the bar
      y={y + 10}
      dy={8} // Adjust position
      textAnchor='start'
      fill={'white'}
      fontSize='12px' // Font size
      fontFamily='Arial' // Font family
      fontWeight='bold' // Font weight
    >
      {`${value} - ${name}`}
    </text>
  );
};

// Custom bar component
const CustomBar = (props: any) => {
  const { x, y, width, height, color } = props;
  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill={color} />
    </g>
  );
};

function HorizontalBarChart({ DailyActivityCardData }: any) {
  return (
    <BarChart
      width={672}
      height={57 * DailyActivityCardData.length}
      data={DailyActivityCardData}
      layout='vertical'
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <XAxis type='number' axisLine={false} display='none' />
      <YAxis
        type='category'
        axisLine={true}
        label={undefined} // Hide X-axis label
        tick={false}
        stroke={'#D0D5DD'}
      />
      <Bar dataKey='value' fill='#B37FEB' barSize={26} shape={<CustomBar />} label={CustomLabel} />
      {/* <LabelList
          dataKey={'name' + 'value'}
          position='insideLeft'
          fill='#FFFFFF'
          content={<CustomLabel />}
        /> */}
      {/* </Bar> */}
    </BarChart>
  );
}

export default HorizontalBarChart;
