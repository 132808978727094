export const fileApiUrl =
  process.env.REACT_APP_FILE_URL || `${window.location.origin}/api/aws-files/`;

export const alertOptions: any = {
  zIndex: 9999,
  autoClose: 3000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: false,
  pauseOnHover: true,
  position: 'top-right',
  progress: undefined,
};

import { useLayoutEffect, useState } from 'react';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import linkedin from 'assets/png/linkedin.svg';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    height,
    width,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const drawSignatureInfo = {
  height: '75px',
  width: '350px',
};
export const experienceYear: any = [
  { label: 'Fresher', value: '0' },
  { label: '1 Year', value: '1' },
  { label: '2 Year', value: '2' },
  { label: '3 Year', value: '3' },
  { label: '4 Year', value: '4' },
  { label: '5 Year', value: '5' },
  { label: '6 Year', value: '6' },
  { label: '7 Year', value: '7' },
  { label: ' 8Year', value: '8' },
  { label: '9 Year', value: '9' },
  { label: '10 Year', value: '10' },
  { label: '11 Year', value: '11' },
  { label: '12 Year', value: '12' },
  { label: '13 Year', value: '13' },
  { label: '14 Year', value: '14' },
  { label: '15 Year', value: '15' },
  { label: '16 Year', value: '16' },
  { label: '17 Year', value: '17' },
  { label: '18 Year', value: '18' },
  { label: '19 Year', value: '19' },
  { label: '20 Year', value: '20' },
  { label: '21 Year', value: '21' },
  { label: '22 Year', value: '22' },
  { label: '23 Year', value: '23' },
  { label: '24 Year', value: '24' },
  { label: '25 Year', value: '25' },
];
export const experienceMonth: any = [
  { label: '0 Month', value: '0' },
  { label: '1 Month', value: '1' },
  { label: '2 Month', value: '2' },
  { label: '3 Month', value: '3' },
  { label: '4 Month', value: '4' },
  { label: '5 Month', value: '5' },
  { label: '6 Month', value: '6' },
  { label: '7 Month', value: '7' },
  { label: '8 Month', value: '8' },
  { label: '9 Month', value: '9' },
  { label: '10 Month', value: '10' },
  { label: '11 Month', value: '11' },
];
export const age: any = [
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
  { label: '31', value: '31' },
  { label: '32', value: '32' },
  { label: '33', value: '33' },
  { label: '34', value: '34' },
  { label: '35', value: '35' },
  { label: '36', value: '36' },
  { label: '37', value: '37' },
  { label: '38', value: '38' },
  { label: '39', value: '39' },
  { label: '40', value: '40' },
  { label: '41', value: '41' },
  { label: '42', value: '42' },
  { label: '43', value: '43' },
  { label: '44', value: '44' },
  { label: '45', value: '45' },
  { label: '46', value: '46' },
  { label: '47', value: '47' },
  { label: '48', value: '48' },
  { label: '49', value: '49' },
  { label: '50', value: '50' },
  { label: '51', value: '51' },
  { label: '52', value: '52' },
  { label: '53', value: '53' },
  { label: '54', value: '54' },
  { label: '55', value: '55' },
  { label: '56', value: '56' },
  { label: '57', value: '57' },
  { label: '58', value: '58' },
  { label: '59', value: '59' },
  { label: '60', value: '60' },
];
export const noticePeriod: any = [
  { label: 'Immediate', value: '0' },
  { label: '15 Days', value: '15' },
  { label: '30 Days', value: '30' },
  { label: '45 Days', value: '45' },
  { label: '2 Months', value: '60' },
  { label: '3 Months', value: '90' },
  { label: '4 Months', value: '120' },
  { label: '5 Months', value: '150' },
  { label: '6 Months', value: '180' },
];

export const cvRejectReason: { label: string; value: string }[] = [
  { label: 'Already Interviewed Candidate', value: 'Already Interviewed Candidate' },
  { label: 'Barred Candidate', value: 'Barred Candidate' },
  { label: 'Client Changed JD Specification', value: 'Client Changed JD Specification' },
  { label: 'Client put the Position on Hold', value: 'Client put the Position on Hold' },
  {
    label: 'Expectation on the Candidate on Designation is not matching',
    value: 'Expectation on the Candidate on Designation is not matching',
  },
  {
    label: 'Expectation on the Candidate on Job Profile is not matching',
    value: 'Expectation on the Candidate on Job Profile is not matching',
  },
  {
    label: 'Expectation on the Candidate on Work Location is not matching',
    value: 'Expectation on the Candidate on Work Location is not matching',
  },
  { label: 'Gender Mismatch', value: 'Gender Mismatch' },
  { label: 'Inadequate Domain Exposure', value: 'Inadequate Domain Exposure' },
  { label: 'Inadequate Experience', value: 'Inadequate Experience' },
  { label: 'Inadequate Knowledge/Skill', value: 'Inadequate Knowledge/Skill' },
  { label: 'Inadequate Linguistic Ability', value: 'Inadequate Linguistic Ability' },
  { label: 'Non-poachable Candidate', value: 'Non-poachable Candidate' },
  {
    label: 'Notice Period Demanded by the Candidate',
    value: 'Notice Period Demanded by the Candidate',
  },
  {
    label: 'Potential Offer & Expectation Mismatch',
    value: 'Potential Offer & Expectation Mismatch',
  },
  { label: 'Qualification Mismatch', value: 'Qualification Mismatch' },
  { label: 'Unaffordable Notice Period', value: 'Unaffordable Notice Period' },
  { label: 'Other Reasons', value: 'Other Reasons' },
];
export const interviewRejectReason: { label: string; value: string }[] = [
  { label: 'Candidate No-Show For Interview', value: 'Candidate No-Show For Interview' },
  { label: 'Candidate not Flexiable', value: 'Candidate not Flexiable' },
  { label: 'Culture Compatibility Misfit', value: 'Culture Compatibility Misfit' },
  { label: 'Inadequate Experience', value: 'Inadequate Experience' },
  { label: 'Inadequate Exposure', value: 'Inadequate Exposure' },
  { label: 'Inadequate Knowledge/Skill', value: 'Inadequate Knowledge/Skill' },
  {
    label: 'Inappropriate Behaviours/Professionalism',
    value: 'Inappropriate Behaviours/Professionalism',
  },
  { label: 'Potential Offer & Expection Mismatch', value: 'Potential Offer & Expection Mismatch' },
  { label: 'Reason not Shared', value: 'Reason not Shared' },
  { label: 'Unaffordable Notice Period', value: 'Unaffordable Notice Period' },
];
export const ResechduleReason: { label: string; value: string }[] = [
  { label: 'Candidate No-Show For Interview', value: 'Candidate No-Show For Interview' },
  { label: 'Client requested Resechduling', value: 'Client requested Resechduling' },
  { label: 'Problem in Online Connectivity', value: 'Problem in Online Connectivity' },
];
export const interviewSchedule: { label: string; value: string }[] = [
  { label: 'First Interview', value: 'firstInterview' },
  { label: 'Second Interview', value: 'secondInterview' },
  { label: 'Third Interview', value: 'thirdInterview' },
  { label: 'Fourth Interview', value: 'fourthInterview' },
  { label: 'Final Interview', value: 'finalInterview' },
];
export const interviewType: { label: string; value: string }[] = [
  { label: 'Face to Face', value: 'faceToFace' },
  { label: 'TeleCon', value: 'teleCon' },
  { label: 'Video Conference', value: 'videoConference' },
];
export const interviewDuration: { label: string; value: string }[] = [
  { label: '30 Mins.', value: '30' },
  { label: '45 Mins.', value: '45' },
  { label: '1 Hr', value: '60' },
  { label: '2 Hr', value: '120' },
  { label: '3 Hr', value: '180' },
];

export const offerrejectReason: { label: string; value: string }[] = [
  {
    label: 'Cannot join within the Notice Period Offered',
    value: 'Cannot join within the Notice Period Offered',
  },
  {
    label: 'Changed his/her Mind in the Last Minute w/o and reason',
    value: 'Changed his/her Mind in the Last Minute w/o and reason',
  },
  {
    label: 'Company changed its Decision in the Last Minute w/o any reason',
    value: 'Company changed its Decision in the Last Minute w/o any reason',
  },
  {
    label: 'Expectation of the Candidate on Benefits & Allowances',
    value: 'Expectation of the Candidate on Benefits & Allowances',
  },
  {
    label: 'Expectation of the Candidate on Designation',
    value: 'Expectation of the Candidate on Designation',
  },
  {
    label: 'Expectation of the Candidate on job Profile',
    value: 'Expectation of the Candidate on job Profile',
  },
  {
    label: 'Expectation of the Candidate on Reporting Structure',
    value: 'Expectation of the Candidate on Reporting Structure',
  },
  {
    label: 'Expectation of the Candidate on Work Location',
    value: 'Expectation of the Candidate on Work Location',
  },
  { label: 'Failure in Medical Test', value: 'Failure in Medical Test' },
  { label: 'Failure in Reference Check', value: 'Failure in Reference Check' },
  { label: 'Found Better Candidate', value: 'Found Better Candidate' },
  { label: 'Found Better Oppertunity Within', value: 'Found Better Oppertunity Within' },
  { label: 'Found Candidate from Within', value: 'Found Candidate from Within' },
  { label: 'Got Better Offer Elsewhere', value: 'Got Better Offer Elsewhere' },
  {
    label: 'Inappropriate/Inconsistent Response from Candidate',
    value: 'Inappropriate/Inconsistent Response from Candidate',
  },
  {
    label: 'Not Satisfied with Benefits & Allowances',
    value: 'Not Satisfied with Benefits & Allowances',
  },
  {
    label: 'Not Satisfied with Company Reputation',
    value: 'Not Satisfied with Company Reputation',
  },
  { label: 'Not Satisfied with CTC', value: 'Not Satisfied with CTC' },
  { label: 'Not Satisfied with CTC Break-up', value: 'Not Satisfied with CTC Break-up' },
  { label: 'Not Satisfied with Designation', value: 'Not Satisfied with Designation' },
  {
    label: 'Not Satisfied with Employment Terms & Conditions',
    value: 'Not Satisfied with Employment Terms & Conditions',
  },
  { label: 'Not Satisfied with job profile', value: 'Not Satisfied with job profile' },
  {
    label: 'Not Satisfied with Reporting Structure',
    value: 'Not Satisfied with Reporting Structure',
  },
  { label: 'Not Satisfied with Work Location', value: 'Not Satisfied with Work Location' },
  {
    label: 'Not Submitted Relavent Documents in-time',
    value: 'Not Submitted Relavent Documents in-time',
  },
  {
    label: 'Notice Period Demanded by the Candidate',
    value: 'Notice Period Demanded by the Candidate',
  },
  {
    label: 'Present Employer not willing to Relieve',
    value: 'Present Employer not willing to Relieve',
  },
  { label: 'Reason not shared', value: 'Reason not shared' },
];

export const joiningDeferred: { label: string; value: string }[] = [
  { label: 'Candidate requested Change of Date', value: 'Candidate requested Change of Date' },
  { label: 'Client requested Change of Date', value: 'Client requested Change of Date' },
];

export const mimeTypes = `.rtf, application/rtf, application/pdf, image/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
   application/vnd.openxmlformats-officedocument.presentationml.presentation`;
// export const defaultText = `<table border="0" cellpadding="0" cellspacing="0" width="500">
//    <tbody>
//      <tr>
//        <td border="0" cellpadding="0" cellspacing="0" height="38" width="222">
//          <img src=${CTLogo} alt="Logo Icon">
//        </td>
//      </tr>
//      <tr>
//        <td height="64" style="font-family:Helvetica, Arial, sans-serif; font-size:18px; font-style:bold;">
//          <strong>Name</strong>

//      </tr>
//      <tr>
//        <td height="64" style="font-family:Helvetica, Arial, sans-serif; font-size:18px; font-style:bold;">
//          <em style="font-size:17px; font-weight:400;">Designation</em>
//        </td>
//      </tr>
//      <tr>
//        <td height="64" style="font-family:Helvetica, Arial, sans-serif; font-size:18px; font-style:bold;">
//          <em style="font-size:17px; font-weight:400;">Email</em>
//        </td>
//      </tr>
//    </tbody>
//  </table>`;
export const defaultText = (fname: any, lname: any, role: any, email: any, phoneNumber: any) => {
  return `
<p style="width: 242px; height:184px;">
<span style="font-family: 'Poppins', sans-serif; color:#EE4B22; font-size:14px !important;padding-bottom:1px; font-weight:600;">${
    fname && lname ? `${fname} ${lname}` : fname ? fname : lname ? lname : 'Name'
  } </span>
<br/>
<span style="padding-bottom:1px; font-family: 'Poppins', sans-serif; color:#6B6B6B;font-size:12px; font-weight:400;">${
    role ? role : 'Role Name'
  }</span>

<br/>
<span  style="font-family: 'Poppins', sans-serif;color:#2E162E;font-size:12px; padding-bottom:5px; border-bottom: 1px solid #DCDCDC;">Career Tree HR Solutions Pvt. Ltd.</span>

<hr/>

<span style="font-family: 'Poppins', sans-serif;color:#EE4B22;font-size:11px;padding-top:15.5px">E : 
<span style="color:#8E8E8E;">${email ? email : 'Your email'}</span>
</span>

<br/>


<span style="padding-top:4px;font-family: 'Poppins', sans-serif; color:#EE4B22;font-size:11px;">P : 
<span style="color:#8E8E8E;">${phoneNumber ? phoneNumber : 'Your phone number'}</span>

</span>

<br/>

<span style="font-family: 'Poppins', sans-serif; padding-top:4px;color:#2E162E;font-size:11px;" >www.career-tree.in |
<img src=${linkedin} alt="Girl in a jacket" width="15" height="15" style="padding-right:10px; padding-left:7px;">
</span>

<br/>

<span style="padding-top:8.5px;">
<img src=${CTLogo} alt="Logo Icon" width="109" height="20">
</span>

</p>
`;
};
