
function FailureLinechart() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="121"
        height="39"
        fill="none"
        viewBox="0 0 121 39"
      >
        <path
          stroke="#BDBDBD"
          strokeDasharray="4 4"
          strokeLinecap="round"
          d="M1.5 22.5L118.5 22.5"
        ></path>
        <path
          stroke="#EE4B22"
          strokeLinecap="round"
          strokeWidth="2"
          d="M1 2.75c7.5 3 14 18.75 19 18.75 5.5 0 5-12 12-12S39.5 1 43.5 1C47 1 47 18 53 18s6.5 11 11 11c5 0 6.5-21 12-21 6.5 0 11 22.5 17.5 23 4 0 6.5-9.5 11-9.5s10 16 15.5 16"
        ></path>
      </svg>
    );
  }
  
  export default FailureLinechart;
  