import * as React from 'react';
const OpeningsWorked = () => (
  <svg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_12086_12352)'>
      <path
        d='M0.678976 20.1732C0.678976 14.8637 0.678976 9.55431 0.678976 4.24487C0.710922 4.31515 0.749257 4.38544 0.774814 4.45891C1.59263 6.91237 2.41045 9.36583 3.22827 11.8193C3.58287 12.8863 4.34638 13.4358 5.4645 13.4358C6.68484 13.4358 7.90198 13.4358 9.12232 13.4358H9.39067C9.39067 13.6849 9.38747 13.899 9.39067 14.1162C9.40025 14.573 9.68777 14.8765 10.1414 14.8765C12.0422 14.8797 13.943 14.8797 15.8438 14.8765C16.3102 14.8765 16.6009 14.573 16.6073 14.1002C16.6105 13.8862 16.6073 13.6722 16.6073 13.4326H16.8884C18.0992 13.4326 19.3099 13.4326 20.5207 13.4326C21.6548 13.4326 22.4087 12.8831 22.7697 11.8033C23.5588 9.4393 24.3446 7.07849 25.1305 4.71448C25.1784 4.57392 25.2295 4.43655 25.2774 4.29918C25.3094 4.33752 25.3158 4.36946 25.3158 4.40141C25.3158 9.56709 25.319 14.7328 25.3062 19.8984C25.3062 20.2243 25.2199 20.5757 25.0826 20.8696C24.6705 21.7321 23.9357 22.0995 22.9965 22.0995C16.3325 22.0995 9.6654 22.0995 3.00145 22.0995C2.94395 22.0995 2.88964 22.0995 2.83214 22.0995C2.11655 22.0676 1.50638 21.8024 1.11025 21.2018C0.908988 20.8984 0.816344 20.5214 0.675781 20.1764L0.678976 20.1732Z'
        fill='#637083'
      />
      <path
        d='M16.6071 11.9918C16.6071 11.7362 16.6135 11.5126 16.6071 11.289C16.5944 10.8481 16.3005 10.551 15.8596 10.5478C13.9524 10.5446 12.042 10.5446 10.1349 10.5478C9.69401 10.5478 9.4033 10.8513 9.39371 11.2953C9.38732 11.519 9.39371 11.7394 9.39371 11.979C9.31065 11.9822 9.24995 11.9886 9.18926 11.9886C7.93697 11.9886 6.68788 11.9886 5.43559 11.9886C4.99474 11.9886 4.76153 11.8288 4.62416 11.4135C3.73926 8.76202 2.85755 6.1137 1.97264 3.46217C1.95986 3.42703 1.95347 3.3887 1.9375 3.33119H7.94975C7.94975 3.09799 7.94975 2.87756 7.94975 2.65713C7.96572 1.59972 8.57589 0.791481 9.58539 0.516744C9.78984 0.459242 10.0135 0.443268 10.2275 0.443268C12.0804 0.436879 13.9332 0.436879 15.7861 0.443268C17.1247 0.443268 18.0479 1.37609 18.0575 2.71463C18.0575 2.90631 18.0575 3.09799 18.0575 3.31522H24.0825C24.0474 3.42703 24.0218 3.5069 23.9963 3.58676C23.1401 6.16161 22.2808 8.73647 21.4246 11.3113C21.2489 11.8416 21.0444 11.9918 20.495 11.9918C19.2906 11.9918 18.0894 11.9918 16.8851 11.9918H16.6135H16.6071ZM9.39371 3.31203H16.6103C16.6103 3.12674 16.6103 2.96062 16.6103 2.7913C16.6103 2.1428 16.3548 1.88404 15.7158 1.88404C14.882 1.88404 14.0483 1.88404 13.2177 1.88404C12.1986 1.88404 11.1827 1.88404 10.1636 1.88404C9.73553 1.88404 9.42566 2.15558 9.4001 2.5549C9.38413 2.80089 9.4001 3.05007 9.4001 3.30883L9.39371 3.31203Z'
        fill='#637083'
      />
      <path d='M10.8555 11.9949H15.149V13.4165H10.8555V11.9949Z' fill='#637083' />
    </g>
    <defs>
      <clipPath id='clip0_12086_12352'>
        <rect
          width='24.64'
          height='21.6594'
          fill='white'
          transform='translate(0.679688 0.439941)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default OpeningsWorked;
