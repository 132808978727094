import * as React from 'react';
const CandidateInterviewed = () => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_12086_2219)'>
      <path
        d='M0 2.44282C0.121728 1.97804 0.254522 1.51879 0.558842 1.12871C1.11492 0.417708 1.83699 0.0193253 2.73611 0.0137922C5.22324 -0.00280705 7.71036 -0.00280705 10.1975 0.0137922C11.7025 0.0248584 12.9142 1.23937 12.9391 2.74991C12.9585 3.97549 12.9391 5.20383 12.9502 6.42941C12.9502 6.57051 13 6.7282 13.0775 6.84716C13.498 7.50007 13.9323 8.14467 14.3694 8.78928C14.5631 9.07423 14.6544 9.36748 14.4773 9.69117C14.303 10.0149 14.0043 10.0979 13.664 10.0979C10.0785 10.0979 6.49032 10.1006 2.90487 10.0979C1.422 10.0979 0.334752 9.19596 0.0470313 7.76012C0.0414982 7.72969 0.0193658 7.70202 0.00276655 7.67436C0.00276655 5.93143 0.00276655 4.18851 0.00276655 2.44559L0 2.44282ZM6.47372 4.37663C7.25942 4.37663 8.04512 4.37663 8.83082 4.37663C9.33156 4.37663 9.66908 4.07508 9.67738 3.63243C9.68568 3.20085 9.33709 2.86057 8.85018 2.8578C7.26218 2.85227 5.67419 2.85227 4.08619 2.86057C3.91743 2.86057 3.73207 2.92143 3.58821 3.00996C3.31156 3.17595 3.20643 3.52454 3.29772 3.83439C3.38902 4.14424 3.67121 4.3711 4.01702 4.37387C4.83592 4.38217 5.65482 4.37387 6.47648 4.37387L6.47372 4.37663ZM5.24537 7.22341C5.68525 7.22341 6.12513 7.24001 6.56225 7.21788C6.90807 7.19851 7.17919 6.91356 7.21792 6.58434C7.26218 6.22192 7.07959 5.89547 6.74761 5.77651C6.63141 5.73501 6.50138 5.71841 6.37689 5.71841C5.62439 5.71288 4.87465 5.71288 4.12215 5.71841C3.99766 5.71841 3.86763 5.73501 3.75144 5.77651C3.43052 5.88994 3.22856 6.23022 3.27006 6.55667C3.31432 6.90802 3.57991 7.19851 3.93126 7.21788C4.36838 7.24278 4.80826 7.22341 5.24814 7.22341H5.24537Z'
        fill='#637083'
      />
      <path
        d='M0 24.3732C0.132794 23.8338 0.204724 23.2694 0.409449 22.7603C1.31688 20.5139 2.98234 19.2247 5.4003 18.9314C5.42796 18.9287 5.4584 18.9231 5.49436 18.901C4.20238 18.6797 3.21749 18.024 2.60885 16.8565C2.18834 16.0542 2.09151 15.1994 2.29347 14.3196C2.70845 12.5269 4.32135 11.3373 6.186 11.4341C7.92615 11.5254 9.42009 12.9419 9.64141 14.6958C9.85997 16.4249 8.75612 18.5358 6.46818 18.8816C6.9634 19.0117 7.5499 19.1002 8.08108 19.3187C10.1366 20.1625 11.3788 21.6897 11.7772 23.8863C11.8242 24.1436 11.8298 24.4064 11.8602 24.6665C11.9321 25.2696 11.7661 25.7482 11.1658 26H0.711002C0.37625 25.8616 0.138327 25.6237 0 25.289C0 24.9846 0 24.6775 0 24.3732Z'
        fill='#637083'
      />
      <path
        d='M9.96875 18.558C11.2082 16.5799 11.3105 16.1262 11.0339 13.8134H11.3133C15.8892 13.8134 20.465 13.8134 25.0409 13.8134C25.7215 13.8134 25.9926 14.0845 25.9926 14.765C25.9926 17.6506 25.9926 20.5333 25.9926 23.4188C25.9926 23.5295 25.9981 23.6401 25.976 23.748C25.9041 24.1215 25.5555 24.3899 25.1903 24.3677C24.8113 24.3428 24.4986 24.033 24.4765 23.6374C24.4599 23.3524 24.4737 23.0647 24.4737 22.741C24.3493 22.741 24.2441 22.741 24.1362 22.741C20.5425 22.741 16.946 22.741 13.3522 22.741C13.1862 22.741 13.0783 22.7272 13.0147 22.5335C12.4835 20.9123 11.5042 19.6231 10.0988 18.6576C10.0573 18.6299 10.0185 18.5967 9.96875 18.558Z'
        fill='#637083'
      />
      <path
        d='M20.3398 7.96486C22.8103 8.36601 24.2655 9.77418 24.7579 12.2253H15.1055C15.5896 9.77695 17.0476 8.37154 19.5596 7.95933C18.6549 7.80994 17.9633 7.42539 17.4432 6.76142C16.9203 6.09191 16.6824 5.32834 16.7848 4.48455C16.9867 2.81909 18.3257 1.62671 19.9469 1.63777C21.5626 1.64884 22.8905 2.84122 23.0814 4.48731C23.2419 5.85952 22.4119 7.57201 20.3398 7.96486Z'
        fill='#637083'
      />
    </g>
    <defs>
      <clipPath id='clip0_12086_2219'>
        <rect width='25.9945' height='26' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default CandidateInterviewed;
