/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Breadcrumbs,
  Button,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Link,
  Typography,
  TextField,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { MyplanType } from 'types';
import { MyplanSchema } from 'utils/ValidatorSchema';
import { Fragment, useRef, useState, useEffect } from 'react';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
// import { toast } from 'react-toastify';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import { InferType } from 'yup';
import AddIcon from 'assets/iconcomponents/addicon';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import {
  ClientsENDPOINT,
  LEAVE_ENDPOINT,
  PlanENDPOINT,
  PositionENDPOINT,
  SETTING_ENDPOINT,
} from 'utils/Constant';
import _ from 'lodash';
import Deleteimgicon from 'assets/png/Deleteblack.svg';
import DateTimepicker from 'components/Datepicker/AntdDateTimePicker';
import dayjs from 'dayjs';
import zIndex from '@mui/material/styles/zIndex';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
// import { alertOptions } from 'utils/Config';

const Reasonvalues = [
  {
    label: 'Client Meeting',
    value: 'client meeting',
  },
  {
    label: 'Internal Meeting',
    value: 'internal meeting',
  },
  {
    label: 'Internal Review',
    value: 'internal review',
  },
  {
    label: 'Internal Training',
    value: 'internal training',
  },
  {
    label: 'External Training',
    value: 'external training',
  },
  {
    label: 'Events / Celebration',
    value: 'events / celebration',
  },
  {
    label: 'On Special Assignment',
    value: 'on special assignment',
  },
  {
    label: 'Unwell at Office',
    value: 'unwell at office',
  },
  {
    label: 'On Leave',
    value: 'on leave',
  },
  {
    label: 'Holiday',
    value: 'holiday',
  },
  {
    label: 'Weekly Off',
    value: 'weekly off',
  },
  {
    label: 'Others',
    value: 'others',
  },
] as const;

const AddMyplan = () => {
  const planTasksdefault1: any = {
    date: moment().format('DD-MM-YYYY'),
    session: '',
    workPlanType: 'sourcing',
    clientId: '',
    positionId: '',
  };
  const planTasksdefault2: any = {
    date: '',
    session: '',
    workPlanType: 'others',
    reason: '',
    comments: '',
  };
  const today = new Date().toISOString().split('T')[0];
  const {
    menuList,
    userInfo: { organizationId: orgdy, id: Userids, joiningDate, branchId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [reload, setreload] = useState(false);
  const navigate = useNavigate();
  const [Ld, setLd] = useState(false);
  const subtgref: React.MutableRefObject<any> = useRef();
  const [crmId, setcrmId] = useState<any>([]);
  const [posId, setposId] = useState<any>([]);
  const [leaveList, setLeaveList] = useState<any>([]);
  const [customerror, setcustomerror] = useState<any>([]);
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const [searchParams] = useSearchParams();
  const Mode: any = searchParams.get('mode');
  const Type: any = searchParams.get('type');
  const planid: any = searchParams.get('planid');
  const ids: any = searchParams.get('ids');
  const planTasksdefault = Type === 'previous' ? planTasksdefault2 : planTasksdefault1;
  const {
    handleSubmit,
    control: control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<MyplanType>({
    defaultValues: {
      //   // planTasks: [{ ...planTasksdefault,workPlanType:Type === 'previous' ? 'others':'sourcing',date:Type === 'current'?moment(new Date()).format('DD-MM-YYYY'):'' }],
      //   // planTasks: [{ workPlanType:Type === 'previous' ? planTasksdefault1:planTasksdefault2 }],
      planTasks: [planTasksdefault],
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(MyplanSchema),
  });

  const {
    fields,
    append,
    replace: replaceform,
    update,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'planTasks',
  });

  const getallapi = async () => {
    try {
      setLd(true);
      const getapi = await getAllListData(
        `${ClientsENDPOINT.Client}?organizationId=${orgdy}&recruiterId=${Userids}&approvalStatus=${'approved'}&status=true`,
        true,
      );

      if (!_.isEmpty(getapi) && !_.isEmpty(getapi.data)) {
        const mappedcrm: any = getapi.data.map((p2: any) => {
          if (!_.isEmpty(p2)) {
            return {
              label: p2 && p2.corporateName ? `${p2.corporateName}` : '',
              value: p2.id ? p2.id : '',
            };
          }
        });
        !_.isEmpty(mappedcrm) ? setcrmId(mappedcrm) : '';
      }
    } catch (er) {
      setLd(!true);
    } finally {
      setLd(!true);
    }
  };
  const getLeaveList = () => {
    setLd(true);
    getAllListData(`${SETTING_ENDPOINT.OverAllLeaves}?organizationId=${orgdy}`, true)
      .then((res: any) => {
        setLeaveList(res ? res : []);
        setLd(false);
      })
      .catch(() => {
        setLd(false);
      });
  };
  const getPositionvalues = async () => {
    try {
      setLd(true);
      const getapi = await getAllListData(
        `${PositionENDPOINT.position}?organizationId=${orgdy}&recruiterId=${Userids}&approvalStatus=assigned`,
        true,
      );
      if (!_.isEmpty(getapi) && !_.isEmpty(getapi.data)) {
        const mappedcrm: any = getapi.data.map((p2: any) => {
          if (!_.isEmpty(p2)) {
            return {
              label: p2 && p2.title ? `${p2.title}` : '',
              value: p2.id ? p2.id : '',
              data: p2,
            };
          }
        });
        !_.isEmpty(mappedcrm) ? setposId(mappedcrm) : '';
      }
    } catch (e) {
      setLd(!true);
    } finally {
      setLd(!true);
    }
  };

  async function editget() {
    remove(0);
    await getAllListData(`${PlanENDPOINT.plan}?id=${ids}`, true)
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          const prepopulate = val.data.map((ro: any) => {
            setValue('type', ro && ro.plan && ro.plan.type ? ro.plan.type : '');
            // ro && ro.client && ro.client.id ? getPositionvalues(ro.client.id) : '';
            return ro && ro.workPlanType && ro.workPlanType === 'others'
              ? {
                  date: moment(ro.date).format('YYYY-MM-DD'),
                  session: ro.session,
                  workPlanType: 'others',
                  reason: ro.reason ? ro.reason : '',
                  comments: ro.comments ? ro.comments : '',
                  id: ro.id ? ro.id : '',
                }
              : {
                  date: moment(ro.date).format('YYYY-MM-DD'),
                  session: ro.session,
                  workPlanType: ro.workPlanType,
                  clientId: ro.client && ro.client.id ? ro.client.id : '',
                  positionId: ro.position && ro.position.id ? ro.position.id : '',
                  id: ro.id ? ro.id : '',
                };
          });

          prepopulate.map((f: any, i: number) => {
            update(Number(i), f);
          });

          // val.totalInReview ? setapprovetotal(Number(val.totalInReview)):'';
        } else {
          console.log('es');
        }
        setLd(!true);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(!true);
      });
  }

  useEffect(() => {
    setValue('type', Type ? Type : '');
    setValue('userId', Userids ? Userids : '');
  }, []);

  useEffect(() => {
    errors && errors.planTasks ? setcustomerror(errors.planTasks) : '';
  }, [errors]);

  useEffect(() => {
    getLeaveList();
    getPositionvalues();
    getallapi();
    Mode === 'edit' ? editget() : '';
  }, []);

  // getPositionvalues(e.target.value)

  const location = useLocation();
  const locationstate = location.state;
  const locationpathname = location.pathname;
  const locationbool: boolean = locationpathname === AdminPrivateRoutes.MyPlan.MyPlanADD;
  const onSubmit = async (data: MyplanType | any) => {
    const staticdate =
      data.type === 'current'
        ? {
            ...data,
            planTasks:
              data.planTasks &&
              data.planTasks.map((rt1: any) => {
                return Mode !== 'edit'
                  ? { ...rt1, date: moment().format('YYYY-MM-DD') }
                  : { ...rt1, date: rt1.date };
              }),
          }
        : data;

    try {
      setLd(true);
      const endresult =
        Mode === 'edit'
          ? await updateData(ids, staticdate.planTasks[0], 'plan', true)
          : await addNewData(staticdate, 'plan', undefined, true);
      gotoNavigate(`${AdminPrivateRoutes.MyPlan.MyPlanVIEW}`);
    } catch (e) {
      setLd(!true);
      if (e === 'Forbidden resource') {
        toast.error(AccessDenied);
        navigate('/dashboard');
      }
    } finally {
      setLd(!true);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList
        .find((e: any) => e?.moduleName === 'My Plan')
        .screens.find((a: any) => a?.screenName === 'Create Plan');
      if (!settingData) {
        navigate('/');
      }
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href={AdminPrivateRoutes.MyPlan.MyPlanVIEW}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                My Plan
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {Mode === 'edit' ? 'Edit' : 'Add'} Task Plan
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>
              {Mode === 'edit' ? 'Edit' : 'Add'} Task Plan
            </Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => gotoNavigate(`${AdminPrivateRoutes.MyPlan.MyPlanVIEW}`)}>
                {' '}
                Cancel
              </Button>
              <Button
                className='s-add-btn'
                onClick={() => {
                  subtgref.current.click();
                }}>
                {' '}
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form className='private-form' onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component={'p'} className='side-client-text title-myplan'>
                          {Type === 'current'
                            ? 'Current Day’s Work Plan'
                            : 'Previous Day Work Plan'}
                        </Typography>
                        {Mode !== 'edit' && watch(`planTasks.${0}.session`) !== 'fullDay' ? (
                          <AddIcon
                            onClick={() => {
                              if (fields?.length === 2) {
                                ('');
                              } else if (getValues(`planTasks.${0}.session`) === 'fullDay') {
                                ('');
                              } else {
                                append(planTasksdefault);
                              }
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {fields?.map((ers: any, idx: number) => {
                        return (
                          <Fragment key={idx}>
                            <Grid
                              container
                              columnGap={3.25}
                              sx={{ marginTop: '24px' }}
                              direction={'row'}
                              justifyContent={'flex-start'}>
                              {idx !== 0 ? (
                                <Grid container flexDirection={'column'}>
                                  {' '}
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '1px',
                                      backgroundColor: '#edededb7',
                                      marginTop: '10px',
                                      marginBottom: '10px',
                                    }}></div>
                                  <div
                                    style={{
                                      padding: '30px',
                                      textAlign: 'end',
                                      width: '100%',
                                      paddingRight: '10px',
                                    }}>
                                    <img
                                      src={Deleteimgicon}
                                      alt='delete'
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => remove(idx)}
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid item md={3.5} sm={12} xs={12}>
                                <Typography component={'div'} className='custom-field'>
                                  <Typography component={'p'}>
                                    Task Date <span>*</span>
                                  </Typography>
                                  <Controller
                                    render={({
                                      field: { onChange, value, name, ref: refField },
                                    }) => (
                                      <>
                                        {/* <TextField
                                          value={value}
                                          name={name}
                                          id='date'
                                          type={watch('type') === 'current' ? 'text' : 'date'}
                                          disabled={watch('type') === 'current'}
                                          placeholder={'Select Date'}
                                          className={`clientfilter-time-from-to ${
                                            watch('type') === 'current' ? 'Mui-disabled1' : ''
                                          } ${
                                            errors?.planTasks?.[idx]?.session?.message &&
                                            errors?.planTasks?.[idx]?.session?.message
                                              ? 'clientfilter-time-from-to-error'
                                              : ''
                                          }`}
                                          onChange={(e) => {
                                            // trigger('expectedJoiningDate');
                                            setValue(
                                              `planTasks.${idx}.date`,
                                              moment(e?.target?.value).format('YYYY-MM-DD'),
                                            );
                                            onChange(e);
                                          }}
                                          // inputProps={{ min: today, }}
                                          inputProps={{
                                            max: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                                            ...(joiningDate
                                              ? { min: moment(joiningDate).format('YYYY-MM-DD') }
                                              : ''),
                                          }}
                                          // className={classes.textField}
                                        /> */}
                                        <DateTimepicker
                                          disabled={watch('type') === 'current' || Mode === 'edit'}
                                          defaultValue={
                                            watch('type') === 'current'
                                              ? moment().format('YYYY-MM-DD')
                                              : value
                                          }
                                          ref={refField}
                                          name={name}
                                          disableDate={leaveList}
                                          style={{ width: '100%', padding: '8px 11px' }}
                                          onChange={(date: any, dateString: string) => {
                                            // trigger('expectedJoiningDate');
                                            setValue(
                                              `planTasks.${idx}.date`,
                                              dateString ? dateString : '',
                                            );
                                            onChange(dateString ? dateString : null);
                                          }}
                                          max={moment().subtract(1, 'day').format('YYYY-MM-DD')}
                                          min={
                                            joiningDate ?? moment(joiningDate).format('YYYY-MM-DD')
                                          }
                                        />
                                        <div className='errorposition'>
                                          {errors?.planTasks?.[idx]?.date?.message
                                            ? errors?.planTasks?.[idx]?.date?.message
                                            : ''}
                                        </div>
                                      </>
                                    )}
                                    name={`planTasks.${idx}.date`}
                                    control={control}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item md={3.5} sm={12} xs={12}>
                                <Typography component={'div'} className='custom-field'>
                                  <Typography component={'p'}>
                                    Session <span>*</span>
                                  </Typography>
                                  <Controller
                                    render={({ field }) => (
                                      <>
                                        <RadioGroup
                                          className='custom-radio'
                                          row
                                          {...field}
                                          sx={{ marginTop: '20px' }}>
                                          {Mode === 'edit' ? (
                                            <FormControlLabel
                                              value={getValues(`planTasks.${0}.session`)}
                                              control={<Radio />}
                                              label={
                                                getValues(`planTasks.${0}.session`) === 'firstHalf'
                                                  ? '1st half'
                                                  : getValues(`planTasks.${0}.session`) ===
                                                      'secondHalf'
                                                    ? '2nd half'
                                                    : getValues(`planTasks.${0}.session`) ===
                                                        'fullDay'
                                                      ? 'Full Day'
                                                      : ''
                                              }
                                            />
                                          ) : (
                                            <>
                                              <FormControlLabel
                                                value={'firstHalf'}
                                                control={<Radio />}
                                                label='1st half'
                                              />
                                              <FormControlLabel
                                                value={'secondHalf'}
                                                control={<Radio />}
                                                label='2nd half'
                                              />
                                              <FormControlLabel
                                                value={'fullDay'}
                                                disabled={
                                                  getValues(`planTasks.${1}`)
                                                    ? getValues(`planTasks.${0}.session`) ===
                                                        'firstHalf' ||
                                                      getValues(`planTasks.${0}.session`) ===
                                                        'secondHalf'
                                                      ? true
                                                      : false
                                                    : false
                                                }
                                                control={<Radio />}
                                                label='Full Day'
                                              />
                                            </>
                                          )}
                                        </RadioGroup>
                                        {errors?.planTasks?.[idx]?.session?.message && (
                                          <div className='errorposition'>
                                            {errors?.planTasks?.[idx]?.session?.message
                                              ? errors?.planTasks?.[idx]?.session?.message
                                              : ''}
                                          </div>
                                        )}
                                      </>
                                    )}
                                    name={`planTasks.${idx}.session`}
                                    control={control}
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container columnGap={3.25} sx={{ marginTop: '24px' }}>
                              <Grid item md={3.5} sm={12} xs={12}>
                                <Typography component={'div'} className='custom-field'>
                                  <Typography component={'p'}>
                                    Work Plan Type<span>*</span>
                                  </Typography>
                                  <Controller
                                    render={({ field }) => {
                                      return (
                                        <>
                                          <RadioGroup
                                            className='custom-radio'
                                            row
                                            {...field}
                                            sx={{
                                              marginTop: '20px',
                                              pointerEvents:
                                                watch('type') === 'previous' ? 'none' : 'auto',
                                            }}>
                                            <FormControlLabel
                                              disabled={watch('type') === 'previous'}
                                              value={'sourcing'}
                                              control={<Radio />}
                                              label='Sourcing'
                                            />
                                            <FormControlLabel
                                              value={'others'}
                                              control={<Radio />}
                                              label='Others'
                                            />
                                          </RadioGroup>
                                          {errors?.planTasks?.[idx]?.workPlanType?.message && (
                                            <span>
                                              {errors?.planTasks?.[idx]?.workPlanType?.message}
                                            </span>
                                          )}
                                        </>
                                      );
                                    }}
                                    name={`planTasks.${idx}.workPlanType`}
                                    control={control}
                                    // disabled={watch('type') === 'previous' ? true : false}
                                  />
                                </Typography>
                              </Grid>
                              {watch(`planTasks.${idx}.workPlanType`) === 'others' ? (
                                <Grid item md={3.5} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography component={'p'}>
                                      Reason<span>*</span>
                                    </Typography>
                                    <Controller
                                      render={({ field: { onChange, value, name } }) => {
                                        return (
                                          <CustomSelect
                                            labelKey={'label'}
                                            valueKey={'value'}
                                            options={Reasonvalues}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                              onChange(e);
                                            }}
                                            placeHolder='Select'
                                            // isMulti={true}
                                            // error={watch(`planTasks.${idx}.workPlanType`) === 'others' && errors&&errors.planTasks&&errors.planTasks[idx] && errors.planTasks[idx].reason && errors.planTasks[idx].reason.message ? errors.planTasks[idx].reason.message:''}
                                            error={
                                              !_.isEmpty(customerror) &&
                                              customerror?.[idx]?.reason?.message
                                            }
                                          />
                                        );
                                      }}
                                      name={`planTasks.${idx}.reason`}
                                      control={control}
                                    />
                                  </Typography>
                                </Grid>
                              ) : (
                                ''
                              )}
                              {watch(`planTasks.${idx}.workPlanType`) === 'others' ? (
                                <Grid item md={3.5} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography
                                      component={'p'}
                                      // sx={{ opacity: 0 }}
                                    >
                                      Comments<span>*</span>
                                    </Typography>
                                    <Controller
                                      render={({ field: { onChange, value, name } }) => (
                                        <CustomInput
                                          className='custom-input'
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          value={value}
                                          name={name}
                                          placeHolder='Comments'
                                          error={
                                            !_.isEmpty(customerror) &&
                                            customerror?.[idx]?.comments?.message
                                          }
                                        />
                                      )}
                                      name={`planTasks.${idx}.comments`}
                                      control={control}
                                    />
                                  </Typography>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            {watch(`planTasks.${idx}.workPlanType`) === 'sourcing' ? (
                              <Grid container columnGap={3.25} sx={{ marginTop: '24px' }}>
                                <Grid item md={3.5} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography component={'p'}>
                                      Client Name<span>*</span>
                                    </Typography>
                                    <Controller
                                      render={({ field: { onChange, value, name } }) => (
                                        <CustomSelect
                                          labelKey={'label'}
                                          valueKey={'value'}
                                          options={crmId}
                                          name={name}
                                          value={value}
                                          onChange={(e) => {
                                            // e.target.value ? getPositionvalues(e.target.value) : '';
                                            onChange(e);
                                            setreload(!reload);
                                          }}
                                          placeHolder='Select'
                                          // isMulti={true}
                                          error={
                                            !_.isEmpty(customerror) &&
                                            customerror?.[idx]?.clientId?.message
                                          }
                                        />
                                      )}
                                      name={`planTasks.${idx}.clientId`}
                                      control={control}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item md={3.5} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography component={'p'}>
                                      Position<span>*</span>
                                    </Typography>
                                    <Controller
                                      render={({ field: { onChange, value, name } }) => {
                                        const data = posId.filter((re: any) => {
                                          return (
                                            getValues(`planTasks.${idx}.clientId`) ===
                                            re.data.clientId
                                          );
                                        });
                                        return (
                                          <CustomSelect
                                            labelKey={'label'}
                                            valueKey={'value'}
                                            options={data}
                                            name={name}
                                            value={value}
                                            onChange={(e) => {
                                              onChange(e);
                                            }}
                                            placeHolder='Select'
                                            // isMulti={true}
                                            error={
                                              !_.isEmpty(customerror) &&
                                              customerror?.[idx]?.positionId?.message
                                            }
                                          />
                                        );
                                      }}
                                      name={`planTasks.${idx}.positionId`}
                                      control={control}
                                    />
                                  </Typography>
                                </Grid>
                                {/* {idx !== fields.length - 1 ? (
                                    <div
                                      style={{
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: '#edededb7',
                                        marginTop: '32px',
                                        marginBottom: '32px',
                                      }}></div>
                                  ) : (
                                    <></>
                                  )} */}
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Fragment>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                {/* this is for dum,m,y to trigger form */}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  ref={subtgref}
                  sx={{ display: 'none' }}>
                  Submit
                </Button>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default AddMyplan;
