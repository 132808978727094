import React from 'react';
import Donutreactchart from './icon/donutreactchart';
import TagsAnnotator from './icon/tagsAnnotator';
import { useNavigate } from 'react-router-dom';

export const ResumeData = ({ resumeData }: any) => {
  const navigate = useNavigate();
  const ResumeFinalData = resumeData && resumeData.length > 0;

  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Resumes</div>
        <div className='viewdetail' onClick={() => navigate('/resumes')}>
          View Details
        </div>
      </header>
      {!ResumeFinalData ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '80px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          No data available
        </p> // Placeholder message
      ) : (
        <main>
          <div className='donutchartout'>
            <Donutreactchart resumeData={resumeData} interview={false} />
          </div>
          <div className='labelcon'>
            {resumeData.map((er: any, idx: number) => {
              return (
                <div className='labelItem' key={idx}>
                  <TagsAnnotator txt={er.name} bg={er.color} />
                </div>
              );
            })}
          </div>
        </main>
      )}
    </div>
  );
};
