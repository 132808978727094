import { Grid, Box } from '@mui/material';
import React, { useState } from 'react';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import './index.scss';
import FilterSvgIcon from './icon/filtericon';
import ProductivityRecruiterChart from './icon/productivityrecruiterchart';
import TagsAnnotator from './icon/tagsAnnotator';
import BusinessConversionChart from './icon/businessconversionchart';
import { CategoryCount } from './CategoryCount';
import { CalculationTable } from './CalculationTable';
import { CalenderEvents } from './CalenderEvents';
import { DailyActivity } from './DailyActivity';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import { ResumeData } from './ResumeData';
import { InterviewData } from './InterviewData';
import Joinee from './Joinee';
import { RecentClients } from './RecentClients';
import { RecentPosition } from './RecentPosition';
import FinacialData from './FinacialData';
import OffersData from './OffersData';

const Dashboard = () => {
  const [option, setoption] = useState('CRM');
  const Today = new Date();
  const userDetail: any = useAppSelector((store: any) => store.userLogin.userInfo);
  const [Ld, setLd] = useState(false);
  const [resumeData, setResumeData] = useState<any>([]);
  const [interviewData, setInterviewData] = useState<any>([]);
  const [joineesData, setJoineesData] = useState<any>([]);
  const [dweropen, setdweropen] = useState(false);
  const navigate = useNavigate();
  const WeeklyActivityData = () => {
    setLd(true);
    const oneWeekBefore = new Date(Today);
    oneWeekBefore.setDate(Today.getDate() - 7);
    getAllListData(
      `${DASHBOARD_ENDPOINT.Category}?type=${option.toLowerCase()}&fromDate=${moment(
        oneWeekBefore,
      ).format('YYYY-MM-DD')}&toDate=${moment(Today).format('YYYY-MM-DD')}`,
      true,
    )
      .then((val: any) => {
        const data = [
          {
            name: 'CV Shortlisted',
            color: '#14B8A6',
            value: Number(val.data.cvShortlisted),
          },
          {
            name: 'CRM Pending',
            color: '#F042BC',
            value: Number(val.data.crmValidationPending),
          },
          {
            name: 'CV Sent',
            color: '#3B82F6',
            value: Number(val.data.cvSent),
          },
          {
            name: 'CRM Rejected',
            color: '#F59E0B',
            value: Number(val.data.crmRejected),
          },
          {
            name: 'CV Rejected',
            color: '#6366F1',
            value: Number(val.data.cvRejected),
          },
          {
            name: 'CRM Validated',
            color: '#FACC15',
            value: Number(val.data.crmValidated),
          },

          {
            name: 'CV On Hold',
            color: '#A855F7',
            value: Number(val.data.cvOnHold),
          },
        ];
        const interviewData = [
          {
            name: 'Interview Scheduled',
            color: '#3B82F6',
            value: Number(val.data.candidateInterviewScheduled),
          },
          {
            name: 'Interview Selected',
            color: '#F59E0B',
            value: Number(val.data.candidateInterviewSelected),
          },
          {
            name: 'Interview Rejected',
            color: '#3B82F6',
            value: Number(val.data.candidateInterviewRejected),
          },
          {
            name: 'Interview Rescheduled',
            color: '#A855F7',
            value: Number(val.data.candidateInterviewRescheduled) ?? 0,
          },
        ];
        const joineesData = [
          { name: 'Joined', color: '#00C49F', value: Number(val.data.joined) },
          { name: 'Not Joined', color: '#0088FE', value: Number(val.data.notJoined) },
          { name: 'Deferred', color: '#FFBB28', value: Number(val.data.joiningDeferred) },
        ];
        const filteredResume = data.filter((item) => item.value > 0);
        const filteredInterview = interviewData.filter((item) => item.value > 0);
        const filteredJoinees = joineesData.filter((item) => item.value > 0);
        setResumeData(filteredResume);
        setInterviewData(filteredInterview);
        setJoineesData(filteredJoinees);
        setLd(false);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(false);
      });
  };

  React.useEffect(() => {
    WeeklyActivityData();
  }, [option]);
  const ProductivityItems: Array<{
    title: string;
    color: string;
  }> = [
    {
      title: 'Productivity',
      color: '#3B82F6',
    },
    {
      title: 'CV Sent',
      color: '#F59E0B',
    },
    {
      title: 'CV Target',
      color: '#14B8A6',
    },
    {
      title: 'CTC (₹ )',
      color: '#A855F7',
    },
  ];
  const BusinessConversion: Array<{
    title: string;
    color: string;
  }> = [
    {
      title: 'Joined',
      color: '#3b82f6',
    },
    {
      title: 'Deferred',
      color: '#facc15',
    },
  ];

  function HandleOptions(opt: string) {
    setoption(opt);
  }
  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Box className='commonFlex headercon'>
            <Box className='commonFlex welcome-text'>
              <div className='heading'>
                Welcome {`${userDetail.firstName} ${userDetail.lastName}!`}
              </div>
              <div className='description'>An aggregated view of all your data.</div>
            </Box>
            <Box className='commonFlex right-container'>
              <div className='filter-icon' style={{ height: '44px' }}>
                <FilterSvgIcon />
              </div>
              <div className='commonFlex slider'>
                <div
                  className={`options ${option && option === 'CRM' ? 'Activoptions' : ''}`}
                  onClick={() => HandleOptions('CRM')}>
                  CRM
                </div>
                <div
                  className={`options ${option && option === 'Recruiter' ? 'Activoptions' : ''}`}
                  onClick={() => HandleOptions('Recruiter')}>
                  Recruiter
                </div>
              </div>
            </Box>
          </Box>

          <CategoryCount
            setLd={setLd}
            option={option}
            setdweropen={setdweropen}
            dweropen={dweropen}
          />
          <CalculationTable setLd={setLd} option={option} />
          {option === 'Recruiter' ? (
            <div className='productivitygraph'>
              <div className='productivitycards'>
                <header>
                  <div className='title'>Productivity</div>
                  <div className='viewdetail'>View Details</div>
                </header>
                <main>
                  <div className='circleloadercon'>
                    <ProductivityRecruiterChart c1={0.72} c2={0.7} c3={0.65} c4={0.42} />
                  </div>
                  <div className='labelcon'>
                    <div className='labelwrap'>
                      {ProductivityItems.map((er: any, idx: number) => {
                        return (
                          <div className='labelItem' key={idx}>
                            <TagsAnnotator txt={er.title} bg={er.color} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </main>
              </div>
              <div className='productivitycards'>
                <header>
                  <div className='title'>Business Conversion</div>
                  <div className='viewdetail'>View Details</div>
                </header>
                <main>
                  <div className='circleloadercon circleloadercon2'>
                    <BusinessConversionChart />
                  </div>
                  <div className='labelcon labelcon2'>
                    <div className='labelwrap'>
                      {BusinessConversion.map((er: any, idx: number) => {
                        return (
                          <div className='labelItem' key={idx}>
                            <TagsAnnotator txt={er.title} bg={er.color} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className='dailyactivitycon'>
            <Grid container>
              <Grid item md={8} sm={12} xs={12}>
                <div className='leftcon'>
                  <DailyActivity setLd={setLd} option={option} />
                  <div className='borderdivider'></div>
                  <ResumeData resumeData={resumeData} />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className='rightcon'>
                  <CalenderEvents setLd={setLd} />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='Interviewcon'>
            <Grid container>
              <Grid item md={4} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='leftcon'>
                  <InterviewData resumeData={interviewData} />
                </div>{' '}
              </Grid>
              <Grid item md={8} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='rightcon'>
                  <OffersData setLd={setLd} option={option} />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='joineecon'>
            <Grid container>
              <Grid item md={4} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='leftcon'>
                  <Joinee resumeData={joineesData} />
                </div>{' '}
              </Grid>
              <Grid item md={8} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='rightcon'>
                  <RecentClients setLd={setLd} option={option} />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='Postioncon'>
            <Grid container>
              <Grid item md={8} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='leftcon'>
                  <RecentPosition setLd={setLd} option={option} />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='rightcon'>
                  <FinacialData setLd={setLd} option={option} />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {Ld && <Loader />}
    </>
  );
};

export default Dashboard;
