/* eslint-disable quotes */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Typography,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Tab,
  Button,
  Modal,
  Popover,
  Badge,
  styled,
  BadgeProps,
  Icon,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layer from 'assets/png/CvUpload.png';
import CVSent from 'assets/png/CVSent.png';
import CVStatus from 'assets/png/CVStatus.png';
import OverallStatus from 'assets/png/OverallStatus.png';
import moment from 'moment';
import CvUpload from './CvUpload';
import CvSent from './CvSent';
import CvStatus from './CvStatus';
import OverAllStatus from './OverAllStatus';
import { addNewData, getAllListData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CloseIcon from 'assets/png/close.svg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ck-editor-plugin/build/ckeditor';
import CustomInput from 'components/CustomInput/customInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  InterviewConfirmationCandidateSchema,
  InterviewConfirmationClientSchema,
  InterviewDetailsMultipleSchema,
  sendEmailSchema,
} from 'utils/ValidatorSchema';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  ResechduleReason,
  alertOptions,
  interviewDuration,
  interviewSchedule,
  interviewType,
} from 'utils/Config';
import { fileTypeCheck } from 'utils/helper';
import Fileupload from 'assets/png/upload-cloud.svg';
import Wordpng from 'assets/png/Word.png';
import Deletebucket from 'assets/png/Deletebucket.svg';
import { useAppSelector } from 'store/hooks';
import View from 'assets/png/View.png';
import { saveAs } from 'file-saver';
import Snapshot from 'assets/png/Snapshot.png';
import Download from 'assets/png/Download.png';
import Reschedule from 'assets/png/Reschedule.svg';
import Schedule from 'assets/png/schedule.svg';
import InterviewDetail from 'assets/png/interviewDetail.svg';
import SechduleToClient from 'assets/png/scheduletoClient.svg';
import SechduleToCandidate from 'assets/png/scheduletoCandidate.svg';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomTagInput from 'components/Taginput/Taginput';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

type defaultprops = {
  history: any;
  crmUserId: string;
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: -5,
    // top: 13,
    backgroundColor: '#ee4b22',
    border: `0px solid ${theme.palette.background.paper}`,
    padding: '3px 8px 0px 9px',
  },
}));

const RevisionHistory: React.FC<defaultprops> = ({ history, crmUserId }) => {
  const [tabValue, setTabValue] = useState('1');
  const navigate = useNavigate();
  const [cvUploadSend, setCvUploadSend] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { menuList }: any = useAppSelector((store: any) => store.userLogin);
  const [searchParams] = useSearchParams();
  const [tableData, settableData] = useState<any>([]);
  const hiddenFileInput: any = useRef(null);
  const {
    userInfo: { organizationId: orgdy, id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [fileUpload, setfileupload] = useState<any>();
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [resumeRowData, setResumeRowData] = useState<any>({});
  const [selectedCandidate, setSelectedCandidate] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setSelectedCandidate([]);
    setTabValue(newValue);
  };

  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    // setError,
    // trigger,
    // watch,
    getValues,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      subject: '',
      message: '',
      candidate: '',
      cc: [],
      client: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(sendEmailSchema),
  });
  const handleClose = () => {
    setOpen(false);
    // setCvUploadSend([]);
    getList();
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, row?: any) => {
    setResumeRowData(row);
    setPAnchorEl(event.currentTarget);
  };
  const handlePoperClose = () => {
    setResumeRowData({});
    setPAnchorEl(null);
  };
  const openPop = Boolean(panchorEl);
  const paid = openPop ? 'simple-popover' : undefined;
  const downloadResume = () => {
    setloading(true);
    getAllListData(`${ResumesENDPOINT.download}/${resumeRowData?.id}`, true)
      .then((res) => {
        saveAs(
          'data:application/pdf;base64,' + res,
          (resumeRowData?.personal
            ? `${resumeRowData?.personal?.firstName} ${resumeRowData?.personal?.lastName}`
            : resumeRowData?.file?.fileName) + '.pdf',
          { autoBom: true },
        );
        handlePoperClose();
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  const viewSnapshot = () => {
    setloading(true);
    getAllListData(`${ResumesENDPOINT.resumeSnapshot}/${resumeRowData?.id}`, true)
      .then((res) => {
        if (res) {
          const baseUrl = res;
          const winHtml = `<!DOCTYPE html>
          <html>
            <body>
              <iframe
                style="width: 100%; height: 100vh"
                src="data:application/pdf;base64,${baseUrl}"
              />
            </body>
          </html>
          `;
          const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
          window.open(
            winUrl,
            'win',
            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=500',
          );
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  const viewResume = () => {
    setloading(true);
    getAllListData(`${ResumesENDPOINT.download}/${resumeRowData?.id}`, true)
      .then((res) => {
        if (res) {
          const baseUrl = res;
          // window.open(`data:application/pdf;base64,${baseUrl}`);
          // handlePoperClose();

          const winHtml = `<!DOCTYPE html>
          <html>
            <body>
              <iframe
                style="width: 100%; height: 100vh"
                src="data:application/pdf;base64,${baseUrl}"
              />
            </body>
          </html>
          `;
          const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
          window.open(
            winUrl,
            'win',
            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=500',
          );
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  const sentCV = () => {
    if (cvUploadSend && cvUploadSend.length) {
      const settingData = menuList
        .find((e: any) => e.moduleName === 'Resume')
        .screens.find((a: any) => a.screenName === 'Send Resume');
      if (!settingData) {
        toast.error(`You don't have the access`, alertOptions);
      } else {
        getAllListData(
          `${ResumesENDPOINT.cvUpload}/${searchParams.get('id')}?resumeIds=${cvUploadSend}`,
        )
          .then((res) => {
            if (res && res.data) {
              setValue('subject', res.data.subject);
              setValue('candidate', res.data.candidate);
              setValue('client', res.data.client);
              setValue('message', res.data.message);
              setOpen(true);
            }
          })
          .catch((e) => {
            if (e === 'Forbidden resource') {
              toast.error(e);
              navigate('/dashboard');
            }
            console.log(e);
          });
      }
    }
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  const handledelete = () => {
    setfileupload('');
    // hiddenFileInput.current.value = '';
  };

  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (fileTypeCheck.includes(file[0].type)) {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 2048) {
          toast.error('File is too big, Please select a file less than 2MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types  DOCX and DOC only', alertOptions);
        return;
      }
    }
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };
  const getList = () => {
    if (tabValue !== '4') {
      setloading(true);
      getAllListData(
        `${ResumesENDPOINT.resumeList}/list/${searchParams.get('id')}?type=${
          tabValue === '1'
            ? 'cv-uploaded'
            : tabValue === '2'
              ? 'cv-sent'
              : tabValue === '3'
                ? 'cv-status'
                : ''
        }&organizationId=${orgdy}`,
        true,
      )
        .then((res: any) => {
          setloading(false);
          settableData(res.data);
        })
        .catch((e: any) => {
          setloading(false);
          console.log(e);
        });
    }
  };
  const onSubmit = (data: any) => {
    const formdata: any = new FormData();
    formdata.append('positionId', searchParams.get('id'));
    formdata.append('resumeIds', `${JSON.stringify(cvUploadSend)}`);
    formdata.append('subject', data.subject);
    formdata.append('message', data.message);
    data.cc && formdata.append('cc', data.cc ? JSON.stringify(data.cc) : '');
    if (fileUpload && fileUpload.length) {
      formdata.append('attachment', fileUpload[0]);
    }

    addNewData(
      formdata,
      `${ResumesENDPOINT.sendCV}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then(() => {
        handleClose();
        setCvUploadSend([]);
        getList();
        getTabCount();
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor && editor.getData();
    setValue('message', data);
  };
  React.useEffect(() => {
    getList();
  }, [tabValue]);

  const getTabCount = () => {
    getAllListData(`${ResumesENDPOINT.OverAllStatus}/${searchParams.get('id')}`, true)
      .then((res: any) => {
        setData(res.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getTabCount();
  }, []);
  return (
    <Typography component={'div'}>
      {loading && <Loader />}
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='basic-details-position'>
            Revision History
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>History</TableCell>
                  <TableCell>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history && history.length ? (
                  history.map((row: any) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {row && row.createdAt ? moment(row.createdAt).format('DD-MM-YYYY') : ' '}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {row.reason ? row.reason : '-'}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={5} align={'center'}>
                      No Record(s) Found.
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography className='bdiveder' />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
          <Box>
            <TabContext value={tabValue}>
              <Typography component={'div'} className='d-flex-a d-flex-sb custom-position-tab'>
                <Box
                  sx={{
                    borderColor: 'divider',
                    borderRadius: '8px',
                    background: '#F5F6FA',
                    padding: '5px',
                  }}>
                  <TabList onChange={handleChange} aria-label='lab API tabs example'>
                    <Tab
                      icon={
                        <img
                          src={Layer}
                          alt='Layer'
                          style={{
                            height: '18px',
                            width: '18px',
                            marginBottom: '0px',
                            marginRight: '10px',
                          }}
                        />
                      }
                      label='CV Uploaded'
                      value='1'
                    />

                    <Tab
                      // icon={}
                      sx={{ paddingRight: '38px !important' }}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Icon>
                            <img
                              src={OverallStatus}
                              alt='OverallStatus'
                              style={{
                                height: '18px',
                                width: '24px',
                                marginBottom: '0px',
                                marginRight: '10px',
                              }}
                            />
                          </Icon>
                          <span style={{ marginLeft: '8px' }}>{'CV Sent'}</span>
                          <StyledBadge
                            badgeContent={data?.cvSent ? data?.cvSent : 0}
                            color='secondary'
                          />
                        </div>
                      }
                      value='2'
                    />

                    <Tab
                      icon={
                        <img
                          src={CVSent}
                          alt='CVSent'
                          style={{
                            height: '18px',
                            width: '18px',
                            marginBottom: '0px',
                            marginRight: '10px',
                          }}
                        />
                      }
                      label='CV Status'
                      value='3'
                    />

                    <Tab
                      icon={
                        <img
                          src={CVStatus}
                          alt='CVStatus'
                          style={{
                            height: '18px',
                            width: '18px',
                            marginBottom: '0px',
                            marginRight: '10px',
                          }}
                        />
                      }
                      label='Over all Status'
                      value='4'
                    />
                  </TabList>
                </Box>
                {tabValue === '1' && userId === crmUserId && (
                  <Button
                    style={{ marginLeft: '10px' }}
                    form={'profile-edit'}
                    className='s-add-btn'
                    type='button'
                    onClick={() => sentCV()}>
                    Send CV
                  </Button>
                )}
                {tabValue === '3' && (
                  <MultipleScehedule
                    setSelectedCandidate={setSelectedCandidate}
                    selectedCandidate={selectedCandidate}
                    getList={getList}
                    userId={userId}
                    crmUserId={crmUserId}
                  />
                )}
              </Typography>
              <Typography component={'div'}>
                <TabPanel value='1' sx={{ padding: '10px 0px' }}>
                  <CvUpload
                    sentCv={sentCV}
                    cvUploadSend={cvUploadSend}
                    setCvUploadSend={setCvUploadSend}
                    tableData={tableData}
                    settableData={settableData}
                    getList={getList}
                    loading={loading}
                    setloading={setloading}
                    handleClick={handleClick}
                    userId={userId}
                    crmUserId={crmUserId}
                  />
                </TabPanel>
                <TabPanel value='2' sx={{ padding: '10px 0px' }}>
                  <CvSent
                    tableData={tableData}
                    settableData={settableData}
                    loading={loading}
                    setloading={setloading}
                    getList={getList}
                  />
                </TabPanel>
                <TabPanel value='3' sx={{ padding: '10px 0px' }}>
                  <CvStatus
                    tableData={tableData}
                    settableData={settableData}
                    loading={loading}
                    setloading={setloading}
                    getList={getList}
                    setSelectedCandidate={setSelectedCandidate}
                    selectedCandidate={selectedCandidate}
                    userId={userId}
                    crmUserId={crmUserId}
                  />
                </TabPanel>
                <TabPanel value='4' sx={{ padding: '10px 0px' }}>
                  <OverAllStatus loading={loading} setloading={setloading} />
                </TabPanel>
              </Typography>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        disableEnforceFocus={true}>
        <Box className='email-modal'>
          <Typography component={'div'} className='d-flex-a flex-sb mb-30'>
            <Typography className='l-title'>{'Send CV to Client'}</Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
          </Typography>
          <Typography component={'div'}>
            <form className='private-form' id={'cvSend-form'} onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Client</Typography>
                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value}
                          disabled
                          name={name}
                          placeHolder='Client'
                          className='custom-input'
                          error={errors.client?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='client'
                      control={control}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>CC</Typography>
                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomTagInput
                          value={value}
                          name={name}
                          placeHolder='Add multiple emails separated by comma'
                          // className='custom-input'
                          error={errors.cc?.message ? true : false}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='cc'
                      control={control}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Candidate(s)</Typography>

                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value}
                          name={name}
                          disabled
                          placeHolder='Candidate(s)'
                          className='custom-input'
                          error={errors.candidate?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='candidate'
                      control={control}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Subject <span>*</span>
                    </Typography>

                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value}
                          name={name}
                          placeHolder='Subject'
                          className='custom-input'
                          error={errors.subject?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='subject'
                      control={control}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Message <span>*</span>
                    </Typography>
                    <>
                      <div
                        className={
                          errors && errors.message && errors.message.message
                            ? 'ckeditor-config ckeditor-container'
                            : 'ckeditor-container'
                        }>
                        <CKEditor
                          editor={Editor}
                          data={getValues('message')}
                          onChange={handleEditorChange}
                          // config={editorConfig}
                        />
                      </div>
                      {errors && errors.message && errors.message.message && (
                        <div className='Custom-error '>{String(errors.message.message)}</div>
                      )}
                    </>
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Attachment</Typography>
                    <Dropzone onDrop={onDrop}>
                      {({ getRootProps, getInputProps }) => {
                        return (
                          <div
                            {...getRootProps({
                              className: 'dropzone container container-position',
                              onClick: () => {
                                hiddenFileInput.current?.click();
                              },
                            })}>
                            <div>
                              <input {...getInputProps()} />
                              <p className='img-container'>
                                <img src={Fileupload} alt='file upload' />
                              </p>
                              <div className='fil-uplod-desc'>
                                <p className='first-heading'>
                                  <span className='frst-span' role='button'>
                                    Click to upload
                                  </span>{' '}
                                  <span className='drago'>or drag and drop</span>
                                </p>
                                <p className='docx'>DOC, DOCX (up to 2MB)</p>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </Typography>
                  <div>
                    {fileUpload ? (
                      <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                        <Typography component={'div'} className='custom-field file-view-box'>
                          <Typography
                            component={'p'}
                            className='first-children first-green-children'>
                            <div className='image-container'>
                              <span className='image image-success'>
                                <img src={Wordpng} alt='Wordpng' />
                              </span>

                              <span className={'texted'}>
                                <TableTooltip
                                  value={
                                    fileUpload && fileUpload[0]
                                      ? fileUpload[0]?.name
                                      : fileUpload
                                        ? fileUpload.fileName
                                        : ''
                                  }
                                  positionfile={true}></TableTooltip>
                              </span>
                            </div>
                            <div
                              className='Crossicon Crossicon-deletebucket'
                              onClick={handledelete}>
                              <img src={Deletebucket} alt='Crossicon' />
                            </div>
                          </Typography>
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </div>
                </Grid>
              </Grid>
            </form>
            <Typography className='d-flex-a flex-end mt-15'>
              <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                Cancel
              </Button>
              <Button className='l-save-btn' form={'cvSend-form'} type={'submit'}>
                {'Submit'}
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>
      <Popover
        className='profile-drop'
        id={paid}
        anchorEl={panchorEl}
        open={openPop}
        onClose={handlePoperClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ width: '210px !important' }}>
        <Typography component={'div'} className='menu-box'>
          <Typography
            className='menu-item'
            onClick={viewResume}
            style={{ padding: '0px 0px 0px 15px' }}>
            <img src={View} alt='View' />
            View Resume
          </Typography>
          <Typography
            className='menu-item'
            onClick={downloadResume}
            style={{ padding: '0px 0px 0px 15px' }}>
            <img src={Download} alt='Download' />
            Download Resume
          </Typography>
          <Typography
            className='menu-item'
            onClick={viewSnapshot}
            style={{ padding: '0px 0px 0px 15px' }}>
            <img src={Snapshot} alt='Snapshot' />
            View Snapshot
          </Typography>
        </Typography>
      </Popover>
    </Typography>
  );
};
export const MultipleScehedule: React.FC<{
  selectedCandidate: any;
  setSelectedCandidate: any;
  getList: any;
  userId: string;
  crmUserId: string;
}> = ({ selectedCandidate, setSelectedCandidate, getList, userId, crmUserId }) => {
  const [tabValue, setTabValue] = useState('1');
  const [candidatetabValue, setCandidateTabValue] = useState('');
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [sechduleMultiplepen, setSechduleMultiplepen] = React.useState(false);
  const [candidateDetail, setCandidateDetail] = React.useState([]);
  // const currentDate = moment().format('YYYY-MM-DDTHH:MM');
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const [searchParams] = useSearchParams();
  const handleInterviewClose = () => {
    setSechduleMultiplepen(false);
    append({
      rescheduleReason: '',
      interviewDuration: '',
      interviewLevel: '',
      interviewMode: '',
      interviewDateTime: '',
      interviewVenue: '',
      interviewVenueAddress: '',
      spocId: '',
      contactPerson: '',
      contactNo: '',
      additionalInfo: '',
      resumeId: '',
    });
    setvaluesOne('interviewDetails', []);
    setTabValue('1');
  };
  const {
    control: controlOne,
    formState: { errors: errorsOne },
    handleSubmit: handleSubmitOne,
    reset: resetOne,
    setValue: setvaluesOne,
    watch,
    getValues: getValuesOne,
  } = useForm({
    criteriaMode: 'all',
    defaultValues: {
      interviewDetails: [],
      client: '',
      cc: [],
      candidate: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(InterviewDetailsMultipleSchema),
  });
  const {
    fields,
    append,
    // replace: replaceform,
    // update,
    // remove,
    // swap,
  } = useFieldArray({
    control: controlOne,
    name: 'interviewDetails',
  });
  const {
    control: controlTwo,
    formState: { errors: errorsTwo },
    handleSubmit: handleSubmitTwo,
    reset: resetTwo,
    setValue: setValuesTwo,
    getValues: getValuesTwo,
  } = useForm({ resolver: yupResolver(InterviewConfirmationClientSchema) });

  const {
    control: controlThree,
    formState: { errors: errorsThree },
    handleSubmit: handleSubmitThree,
    reset: resetThree,
    setValue: setValuesThree,
    getValues: getValuesThree,
  } = useForm({ resolver: yupResolver(InterviewConfirmationCandidateSchema) });
  const handleInterviewOpen = (value: any) => {
    setPAnchorEl(null);
    setSechduleMultiplepen(true);
    selectedCandidate.map((re: any) => {
      append({
        interviewType: value,
        rescheduleReason: '',
        interviewDuration: '',
        interviewLevel: '',
        interviewMode: '',
        interviewDateTime: '',
        interviewVenue: '',
        interviewVenueAddress: '',
        contactPerson: `${re?.position?.spoc?.firstName} ${re?.position?.spoc?.lastName}`,
        contactNo: re?.position?.spoc?.phoneNumber,
        additionalInfo: '',
        resumeId: re.id,
        spocId: re?.position?.spocId,
      });
    });
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setCandidateTabValue(newValue);
  };

  const onSubmitOne = () => {
    const obj: any = {
      positionId: searchParams.get('id'),
      interviewDetails: getValuesOne('interviewDetails')?.map((re: any, index: number) => ({
        interviewType: getValuesOne(`interviewDetails.${index}.interviewType`),
        ...(getValuesOne(`interviewDetails.${index}.interviewType`) === 'reSchedule'
          ? { rescheduleReason: re.rescheduleReason }
          : {}),
        resumeId: re.resumeId,
        duration: re.interviewDuration,
        level: re.interviewLevel,
        mode: re.interviewMode,
        address: re.interviewVenueAddress,
        venue: re.interviewVenue,
        dateTime: moment(new Date(re.interviewDateTime)).format('DD-MM-YYYY hh:mm A'),
        spocId: re?.spocId,
        contactPerson: re.contactPerson,
        contactNumber: Number(re?.contactNo),
        additionalInfo: re.additionalInfo,
      })),
    };

    addNewData(JSON.stringify(obj), ResumesENDPOINT.emailTempalate)
      .then((res: any) => {
        if (res && res.data && res.data) {
          const data = res.data;
          setCandidateDetail(data.confirmationToCandidate);
          setValuesTwo('client', data.confirmationToClient?.client);
          setValuesTwo('message', data.confirmationToClient?.message);
          setValuesTwo('subject', data.confirmationToClient?.subject);
          setValuesThree('candidate', data.confirmationToCandidate[0]?.candidate);
          setValuesThree('message', data.confirmationToCandidate[0]?.message);
          setValuesThree('subject', data.confirmationToCandidate[0]?.subject);
          setTabValue('2');
        }
      })
      .then((e) => {
        console.log(e);
      });
  };
  const onSubmitTwo = (data: any) => {
    if (data) {
      setTabValue('3');
    }
  };
  const onSubmitThree = () => {
    const obj = {
      positionId: searchParams.get('id'),
      interviewDetails: getValuesOne('interviewDetails')?.map((re: any, i: number) => ({
        interviewType: getValuesOne(`interviewDetails.${i}.interviewType`),
        ...(getValuesOne(`interviewDetails.${i}.interviewType`) === 'reSchedule'
          ? { rescheduleReason: getValuesOne(`interviewDetails.${i}.rescheduleReason`) }
          : {}),
        ...(getValuesOne(`interviewDetails.${i}.interviewMode`) === 'faceToFace'
          ? { address: getValuesOne(`interviewDetails.${i}.interviewVenueAddress`) }
          : {}),
        resumeId: re.resumeId,
        duration: re.interviewDuration,
        level: re.interviewLevel,
        mode: re.interviewMode,
        venue: re.interviewVenue,
        dateTime: re.interviewDateTime,
        spocId: re?.spocId,
        contactPerson: re.contactPerson,
        contactNumber: Number(re?.contactNo),
      })),

      confirmationToClient: {
        subject: getValuesTwo('subject'),
        message: getValuesTwo('message'),
      },
      confirmationToCandidate: candidateDetail.map((es: any) => ({
        resumeId: es.resumeId,
        subject: es.subject,
        message: es.message,
      })),
      ...(getValuesOne('cc') ? { cc: getValuesOne('cc') && getValuesOne('cc') } : {}),
    };
    addNewData(obj, ResumesENDPOINT.interview, {}, true)
      .then(() => {
        handleInterviewClose();
        getList();
        resetOne();
        resetTwo();
        resetThree();
        setTabValue('1');
        setSelectedCandidate([]);
      })
      .then((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (selectedCandidate?.length) {
      setCandidateTabValue(selectedCandidate[0]?.id);
    }
  }, [selectedCandidate]);
  console.log(errorsOne);

  return (
    <>
      {userId === crmUserId && (
        <Button
          style={{ marginLeft: '10px' }}
          form={'profile-edit'}
          className='s-add-btn'
          type='button'
          onClick={(e: any) => {
            selectedCandidate?.length ? handleClick(e) : '';
          }}>
          Action
        </Button>
      )}

      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '100%', padding: '1rem' }}>
        <Typography component={'div'}>
          <Typography
            onClick={() => handleInterviewOpen('schedule')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={Schedule} alt='approve' />
            Schedule Interview
          </Typography>
          <Typography
            onClick={() => handleInterviewOpen('reSchedule')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={Reschedule} alt='reject' />
            Re-Schedule Interview
          </Typography>
        </Typography>
      </Popover>
      <Modal open={sechduleMultiplepen} onClose={handleInterviewClose}>
        <Box
          className='logout-modal p-32'
          sx={{ width: '865px', maxHeight: '90%', overflow: 'auto' }}>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
              {getValuesOne(`interviewDetails.${0}.interviewType`) === 'reSchedule'
                ? 'Re-Schedule Interview'
                : 'Schedule Interview'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleInterviewClose}
              />
            </Typography>
          </Typography>
          <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
            <Box>
              <TabContext value={tabValue}>
                <Typography component={'div'} className='d-flex-a d-flex-sb custom-position-tab'>
                  <Box
                    sx={{
                      borderColor: 'divider',
                      borderRadius: '8px',
                      background: '#F5F6FA',
                      padding: '5px',
                      width: '100%',
                    }}>
                    <TabList
                      aria-label='lab API tabs example'
                      sx={{ justifyContent: 'space-around' }}>
                      <Tab
                        icon={
                          <img
                            src={InterviewDetail}
                            alt='ID'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Interview Details'
                        value='1'
                      />

                      <Tab
                        icon={
                          <img
                            src={SechduleToClient}
                            alt='OverallStatus'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Interview Confirmation to Clients'
                        value='2'
                      />

                      <Tab
                        icon={
                          <img
                            src={SechduleToCandidate}
                            alt='SC'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Interview Confirmation to Candidate'
                        value='3'
                      />
                    </TabList>
                  </Box>
                </Typography>
                <Typography component={'div'}>
                  <TabPanel value='1' sx={{ padding: '10px 0px' }}>
                    <Typography component={'div'}>
                      <form
                        className='private-form'
                        id={'interview-form-One'}
                        onSubmit={handleSubmitOne(onSubmitOne)}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Client</Typography>
                              <Controller
                                render={({ field: { onChange, name } }) => (
                                  <CustomInput
                                    value={selectedCandidate[0]?.client?.corporateName}
                                    disabled
                                    name={name}
                                    placeHolder='Client'
                                    className='custom-input'
                                    error={errorsOne.client?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='client'
                                control={controlOne}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>CC</Typography>
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomTagInput
                                    value={value}
                                    name={name}
                                    placeHolder='Add multiple emails separated by enter'
                                    // classNames='custom-input'
                                    error={errorsOne.cc?.message ? true : false}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='cc'
                                control={controlOne}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography
                                component={'p'}
                                sx={{ fontSize: '20px', fontWeight: '600' }}>
                                Interview Candidate Details
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box>
                              <TabContext value={candidatetabValue}>
                                <Typography
                                  component={'div'}
                                  className='d-flex-a d-flex-sb custom-candidate-tab'>
                                  <Box
                                    sx={{
                                      borderColor: 'divider',
                                      borderRadius: '8px',
                                      background: '#F5F6FA',
                                      padding: '5px',
                                    }}>
                                    <TabList
                                      onChange={handleChange}
                                      aria-label='lab API tabs example'>
                                      {selectedCandidate &&
                                        selectedCandidate?.map((re: any) => (
                                          <Tab
                                            key={re}
                                            label={re?.personal?.firstName}
                                            value={re?.id}
                                          />
                                        ))}
                                    </TabList>
                                  </Box>
                                </Typography>
                                <Grid item md={12} sm={12} xs={12}>
                                  <Typography className='bdiveder' />
                                </Grid>
                              </TabContext>
                            </Box>
                          </Grid>
                          {fields &&
                            fields.map((ew: any, index: number) => (
                              <div
                                style={{
                                  display: ew.resumeId === candidatetabValue ? 'block' : 'none',
                                }}
                                key={index}>
                                <Grid container>
                                  <Grid item md={12} sm={12} xs={12}>
                                    <Grid container columnSpacing={2}>
                                      {watch(`interviewDetails.${index}.interviewMode`) ===
                                      'faceToFace' ? (
                                        <Grid item md={4} sm={12} xs={12}>
                                          <Typography component={'div'} className='custom-field'>
                                            <Typography component={'p'}>
                                              Interview Venue Address <span>*</span>
                                            </Typography>
                                            <Controller
                                              control={controlOne}
                                              defaultValue={''}
                                              name={`interviewDetails.${index}.interviewVenueAddress`}
                                              render={({ field: { onChange, value, name } }) => {
                                                return (
                                                  <CustomSelect
                                                    labelKey={'label'}
                                                    valueKey={'value'}
                                                    options={[
                                                      {
                                                        label: 'Client/Plant Address',
                                                        value: 'client/plantAddress',
                                                      },
                                                      {
                                                        label: 'Other Location',
                                                        value: 'otherLocation',
                                                      },
                                                    ]}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                      onChange(e);
                                                      if (
                                                        watch(
                                                          `interviewDetails.${index}.interviewVenueAddress`,
                                                        ) === 'otherLocation'
                                                      ) {
                                                        setvaluesOne(
                                                          `interviewDetails.${index}.interviewVenue`,
                                                          '',
                                                        );
                                                      }
                                                    }}
                                                    placeHolder='Interview Venue Address'
                                                    error={
                                                      errorsOne?.interviewDetails?.[index]
                                                        ?.interviewVenueAddress?.message
                                                    }
                                                  />
                                                );
                                              }}
                                            />
                                          </Typography>
                                        </Grid>
                                      ) : (
                                        ''
                                      )}
                                      {getValuesOne(`interviewDetails.${0}.interviewType`) ===
                                        'reSchedule' && (
                                        <Grid item md={8} sm={12} xs={12} columnSpacing={2}>
                                          <Typography component={'div'} className='custom-field'>
                                            <Typography component={'p'}>
                                              Reason for Re-sechedule <span>*</span>
                                            </Typography>
                                            <Controller
                                              render={({ field: { onChange, value, name } }) => (
                                                <CustomSelect
                                                  labelKey={'label'}
                                                  valueKey={'value'}
                                                  options={ResechduleReason}
                                                  name={name}
                                                  value={value}
                                                  onChange={(e) => {
                                                    onChange(e);
                                                  }}
                                                  placeHolder='Reason for Re-schedule'
                                                  error={
                                                    errorsOne?.interviewDetails?.[index]
                                                      ?.rescheduleReason?.message
                                                  }
                                                />
                                              )}
                                              name={`interviewDetails.${index}.rescheduleReason`}
                                              control={controlOne}
                                            />
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                    {watch(`interviewDetails.${index}.interviewMode`) ===
                                      'faceToFace' &&
                                    watch(`interviewDetails.${index}.interviewVenueAddress`) ? (
                                      watch(`interviewDetails.${index}.interviewVenueAddress`) ===
                                      'client/plantAddress' ? (
                                        <Grid item md={12} sm={12} xs={12}>
                                          <Typography component={'div'} className='custom-field'>
                                            <Typography component={'p'}>
                                              Interview Venue<span>*</span>
                                            </Typography>
                                            <Controller
                                              render={({ field: { onChange, value, name } }) => {
                                                const options: any = [];
                                                const spocConfirm =
                                                  selectedCandidate[index]?.position?.spoc
                                                    ?.sameAsCorporateAddress;
                                                if (!spocConfirm) {
                                                  options.push(
                                                    {
                                                      label: `${selectedCandidate[index]?.position
                                                        ?.spoc?.address1}, ${
                                                        selectedCandidate[index]?.position?.spoc
                                                          ?.address2
                                                          ? selectedCandidate[index]?.position?.spoc
                                                              ?.address2 + ','
                                                          : ''
                                                      } ${selectedCandidate[index]?.position?.spoc
                                                        ?.city}-${selectedCandidate[index]?.position
                                                        ?.spoc?.pincode}, ${selectedCandidate[index]
                                                        ?.position?.spoc
                                                        ?.state} ${selectedCandidate[index]
                                                        ?.position?.spoc?.country}`,
                                                      value: `${selectedCandidate[index]?.position
                                                        ?.spoc?.address1}, ${
                                                        selectedCandidate[index]?.position?.spoc
                                                          ?.address2
                                                          ? selectedCandidate[index]?.position?.spoc
                                                              ?.address2 + ','
                                                          : ''
                                                      } ${selectedCandidate[index]?.position?.spoc
                                                        ?.city}-${selectedCandidate[index]?.position
                                                        ?.spoc?.pincode}, ${selectedCandidate[index]
                                                        ?.position?.spoc
                                                        ?.state} ${selectedCandidate[index]
                                                        ?.position?.spoc?.country}`,
                                                    },
                                                    {
                                                      label: `${selectedCandidate[index]?.client
                                                        ?.address1}, ${
                                                        selectedCandidate[index]?.client?.address2
                                                          ? selectedCandidate[index]?.client
                                                              ?.address2 + ','
                                                          : ''
                                                      } ${selectedCandidate[index]?.client
                                                        ?.city}-${selectedCandidate[index]?.client
                                                        ?.pincode}, ${selectedCandidate[index]
                                                        ?.client?.state} ${selectedCandidate[index]
                                                        ?.client?.country}`,
                                                      value: `${selectedCandidate[index]?.client
                                                        ?.address1}, ${
                                                        selectedCandidate[index]?.client?.address2
                                                          ? selectedCandidate[index]?.client
                                                              ?.address2 + ','
                                                          : ''
                                                      } ${selectedCandidate[index]?.client
                                                        ?.city}-${selectedCandidate[index]?.client
                                                        ?.pincode}, ${selectedCandidate[index]
                                                        ?.client?.state} ${selectedCandidate[index]
                                                        ?.client?.country}`,
                                                    },
                                                  );
                                                } else {
                                                  options.push({
                                                    label: `${selectedCandidate[index]?.position
                                                      ?.spoc?.address1}, ${
                                                      selectedCandidate[index]?.position?.spoc
                                                        ?.address2
                                                        ? selectedCandidate[index]?.position?.spoc
                                                            ?.address2 + ','
                                                        : ''
                                                    } ${selectedCandidate[index]?.position?.spoc
                                                      ?.city}-${selectedCandidate[index]?.position
                                                      ?.spoc?.pincode}, ${selectedCandidate[index]
                                                      ?.position?.spoc?.state} ${selectedCandidate[
                                                      index
                                                    ]?.position?.spoc?.country}`,
                                                    value: `${selectedCandidate[index]?.position
                                                      ?.spoc?.address1}, ${
                                                      selectedCandidate[index]?.position?.spoc
                                                        ?.address2
                                                        ? selectedCandidate[index]?.position?.spoc
                                                            ?.address2 + ','
                                                        : ''
                                                    } ${selectedCandidate[index]?.position?.spoc
                                                      ?.city}-${selectedCandidate[index]?.position
                                                      ?.spoc?.pincode}, ${selectedCandidate[index]
                                                      ?.position?.spoc?.state} ${selectedCandidate[
                                                      index
                                                    ]?.position?.spoc?.country}`,
                                                  });
                                                }

                                                return (
                                                  <CustomSelect
                                                    labelKey={'label'}
                                                    options={options}
                                                    valueKey={'value'}
                                                    value={value}
                                                    name={name}
                                                    placeHolder='Interview Venue'
                                                    className='custom-input'
                                                    error={
                                                      errorsOne?.interviewDetails?.[index]
                                                        ?.interviewVenue?.message
                                                    }
                                                    onChange={(e) => {
                                                      onChange(e);
                                                    }}
                                                  />
                                                );
                                              }}
                                              name={`interviewDetails.${index}.interviewVenue`}
                                              control={controlOne}
                                            />
                                          </Typography>
                                        </Grid>
                                      ) : (
                                        <Grid item md={12} sm={12} xs={12}>
                                          <Typography component={'div'} className='custom-field'>
                                            <Typography component={'p'}>
                                              Interview Venue<span>*</span>
                                            </Typography>
                                            <Controller
                                              render={({ field: { onChange, value, name } }) => (
                                                <CustomInput
                                                  value={value}
                                                  name={name}
                                                  placeHolder='Interview Venue'
                                                  className='custom-input'
                                                  error={
                                                    errorsOne?.interviewDetails?.[index]
                                                      ?.interviewVenue?.message
                                                  }
                                                  onChange={(e) => {
                                                    onChange(e);
                                                  }}
                                                />
                                              )}
                                              name={`interviewDetails.${index}.interviewVenue`}
                                              control={controlOne}
                                            />
                                          </Typography>
                                        </Grid>
                                      )
                                    ) : (
                                      ''
                                    )}
                                    <Grid container columnSpacing={2}>
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Typography component={'div'} className='custom-field'>
                                          <Typography component={'p'}>
                                            Interview Level <span>*</span>
                                          </Typography>
                                          <Controller
                                            control={controlOne}
                                            name={`interviewDetails.${index}.interviewLevel`}
                                            render={({ field: { onChange, value, name } }) => {
                                              return (
                                                <CustomSelect
                                                  labelKey={'label'}
                                                  valueKey={'value'}
                                                  options={interviewSchedule}
                                                  name={name}
                                                  value={value}
                                                  onChange={(e) => {
                                                    onChange(e);
                                                  }}
                                                  placeHolder='Interview Level'
                                                  error={
                                                    errorsOne?.interviewDetails?.[index]
                                                      ?.interviewLevel?.message
                                                  }
                                                />
                                              );
                                            }}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Typography component={'div'} className='custom-field'>
                                          <Typography component={'p'}>
                                            Interview Mode <span>*</span>
                                          </Typography>
                                          <Controller
                                            control={controlOne}
                                            defaultValue='noPreference'
                                            name={`interviewDetails.${index}.interviewMode`}
                                            render={({ field: { onChange, value, name } }) => {
                                              return (
                                                <CustomSelect
                                                  labelKey={'label'}
                                                  valueKey={'value'}
                                                  options={interviewType}
                                                  name={name}
                                                  value={value}
                                                  onChange={(e) => {
                                                    onChange(e);
                                                  }}
                                                  placeHolder='Interview Mode'
                                                  error={
                                                    errorsOne?.interviewDetails?.[index]
                                                      ?.interviewMode?.message
                                                  }
                                                />
                                              );
                                            }}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Typography component={'div'} className='custom-field'>
                                          <Controller
                                            control={controlOne}
                                            defaultValue={''}
                                            name={`interviewDetails.${index}.interviewDateTime`}
                                            render={({ field: { onChange, value, name } }) => {
                                              return (
                                                <>
                                                  {/* <TextField
                                                    id='date'
                                                    value={value}
                                                    name={name}
                                                    type='datetime-local'
                                                    onChange={(e) => {
                                                      onChange(e);
                                                    }}
                                                    className={`clientfilter-time-from-to ${
                                                      errorsOne?.interviewDetails &&
                                                      errorsOne?.interviewDetails?.[index]
                                                        ?.interviewDateTime?.message
                                                        ? 'clientfilter-time-from-to-error'
                                                        : ''
                                                    }`}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: currentDate,
                                                      },
                                                    }}
                                                  /> */}
                                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer
                                                      components={['DateTimePicker']}
                                                      sx={{ paddingTop: '0px' }}>
                                                      <Typography component={'p'}>
                                                        Interview Date & Time <span>*</span>
                                                      </Typography>
                                                      <DateTimePicker
                                                        views={[
                                                          'day',
                                                          'month',
                                                          'year',
                                                          'hours',
                                                          'minutes',
                                                        ]}
                                                        name={name}
                                                        value={dayjs(value)}
                                                        sx={{
                                                          overflow: 'hidden !important',
                                                          minWidth: 'unset !important',
                                                          marginTop: 'unset !important',
                                                          '& input': {
                                                            fontSize: '14px !important',
                                                            padding: '10.5px 12px',
                                                          },
                                                          '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                              borderColor: '#d0d5dd', // Remove border
                                                              borderWidth: 0.5,
                                                            },
                                                            '&:hover fieldset': {
                                                              borderColor:
                                                                errorsOne?.interviewDetails &&
                                                                errorsOne?.interviewDetails?.[index]
                                                                  ?.interviewDateTime?.message
                                                                  ? 'red'
                                                                  : '#d0d5dd', // Remove border on hover
                                                              borderWidth: 0.5,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                              borderColor:
                                                                errorsOne?.interviewDetails &&
                                                                errorsOne?.interviewDetails?.[index]
                                                                  ?.interviewDateTime?.message
                                                                  ? 'red'
                                                                  : '#d0d5dd', // Remove border on focus
                                                              borderWidth: 0.5,
                                                            },
                                                          },
                                                          '& .MuiOutlinedInput-input': {
                                                            '&:focus': {
                                                              outline: 'none', // Remove default outline
                                                            },
                                                          },
                                                        }}
                                                        disablePast
                                                        // className={`clientfilter-time-from-to ${
                                                        //   errorsOne?.interviewDetails &&
                                                        //   errorsOne?.interviewDetails?.[index]
                                                        //     ?.interviewDateTime?.message
                                                        //     ? 'clientfilter-time-from-to-error'
                                                        //     : ''
                                                        // }`}
                                                        slotProps={{
                                                          field: {
                                                            readOnly: true,
                                                          },
                                                          textField: {
                                                            error:
                                                              errorsOne?.interviewDetails &&
                                                              errorsOne?.interviewDetails?.[index]
                                                                ?.interviewDateTime?.message
                                                                ? true
                                                                : false,
                                                          },
                                                        }}
                                                        onChange={(e: any) => {
                                                          onChange(e);
                                                        }}
                                                      />
                                                    </DemoContainer>
                                                  </LocalizationProvider>
                                                  {errorsOne &&
                                                    errorsOne?.interviewDetails &&
                                                    errorsOne?.interviewDetails?.[index]
                                                      ?.interviewDateTime?.message && (
                                                      <div className='Custom-error '>
                                                        {String(
                                                          errorsOne?.interviewDetails?.[index]
                                                            ?.interviewDateTime?.message,
                                                        )}
                                                      </div>
                                                    )}
                                                </>
                                              );
                                            }}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Typography component={'div'} className='custom-field'>
                                          <Typography component={'p'}>
                                            Interview Duration <span>*</span>
                                          </Typography>
                                          <Controller
                                            render={({ field: { onChange, value, name } }) => (
                                              <CustomSelect
                                                labelKey={'label'}
                                                valueKey={'value'}
                                                options={interviewDuration}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                                placeHolder='Interview Duration'
                                                error={
                                                  errorsOne?.interviewDetails?.[index]
                                                    ?.interviewDuration?.message
                                                }
                                              />
                                            )}
                                            name={`interviewDetails.${index}.interviewDuration`}
                                            control={controlOne}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Typography component={'div'} className='custom-field'>
                                          <Typography component={'p'}>
                                            Contact Person<span>*</span>
                                          </Typography>
                                          <Controller
                                            render={({ field: { onChange, value, name } }) => (
                                              <CustomInput
                                                value={value}
                                                name={name}
                                                placeHolder='Contact Person'
                                                className='custom-input'
                                                disabled={true}
                                                error={
                                                  errorsOne?.interviewDetails?.[index]
                                                    ?.contactPerson?.message
                                                }
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                              />
                                            )}
                                            name={`interviewDetails.${index}.contactPerson`}
                                            control={controlOne}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Typography component={'div'} className='custom-field'>
                                          <Typography component={'p'}>
                                            Contact No<span>*</span>
                                          </Typography>
                                          <Controller
                                            render={({ field: { onChange, value, name } }) => (
                                              <CustomInput
                                                value={value}
                                                name={name}
                                                type='number'
                                                placeHolder='Contact No'
                                                className='custom-input'
                                                disabled={true}
                                                error={
                                                  errorsOne?.interviewDetails?.[index]?.contactNo
                                                    ?.message
                                                }
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                              />
                                            )}
                                            name={`interviewDetails.${index}.contactNo`}
                                            control={controlOne}
                                          />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container columnSpacing={2}>
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Typography component={'div'} className='custom-field'>
                                          <Typography component={'p'}>Additional Info</Typography>
                                          <Controller
                                            render={({ field: { onChange, value, name } }) => (
                                              <CustomInput
                                                value={value}
                                                name={name}
                                                placeHolder='Additional Info'
                                                className='custom-input'
                                                error={
                                                  errorsOne?.interviewDetails?.[index]
                                                    ?.additionalInfo?.message
                                                }
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                              />
                                            )}
                                            name={`interviewDetails.${index}.additionalInfo`}
                                            control={controlOne}
                                          />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                  <Typography className='bdiveder' />
                                </Grid>
                              </div>
                            ))}
                        </Grid>
                      </form>
                      <Typography className='d-flex-a flex-end mt-15'>
                        <Button className='l-cancel-btn mr-16' onClick={handleInterviewClose}>
                          Cancel
                        </Button>
                        <Button className='l-save-btn' form={'interview-form-One'} type={'submit'}>
                          Save and Continue
                        </Button>
                      </Typography>
                    </Typography>
                  </TabPanel>
                  <TabPanel value='2' sx={{ padding: '10px 0px' }}>
                    <Typography component={'div'}>
                      <form
                        className='private-form'
                        id={'interview-form-two'}
                        onSubmit={handleSubmitTwo(onSubmitTwo)}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Client</Typography>
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    disabled
                                    name={name}
                                    placeHolder='Client'
                                    className='custom-input'
                                    error={errorsTwo.client?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='client'
                                control={controlTwo}
                              />
                            </Typography>
                          </Grid>

                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Subject</Typography>

                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    placeHolder='Subject'
                                    className='custom-input'
                                    error={errorsTwo.subject?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='subject'
                                control={controlTwo}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Message <span>*</span>
                              </Typography>
                              <>
                                <div
                                  className={
                                    errorsTwo && errorsTwo.message && errorsTwo.message.message
                                      ? 'ckeditor-config ckeditor-container'
                                      : 'ckeditor-container'
                                  }>
                                  <CKEditor
                                    editor={Editor}
                                    data={getValuesTwo('message')}
                                    // onChange={handleEditorChangeTwo}
                                    // config={editorConfig}
                                  />
                                </div>
                                {errorsTwo && errorsTwo.message && errorsTwo.message.message && (
                                  <div className='Custom-error '>
                                    {String(errorsTwo.message.message)}
                                  </div>
                                )}
                              </>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography className='bdiveder' />
                          </Grid>
                        </Grid>
                      </form>
                      <Typography className='d-flex-a flex-end mt-15'>
                        <Button
                          className='l-cancel-btn mr-16'
                          onClick={() => {
                            setTabValue('1');
                          }}>
                          Cancel
                        </Button>
                        <Button className='l-save-btn' form={'interview-form-two'} type={'submit'}>
                          Save and Continue
                        </Button>
                      </Typography>
                    </Typography>
                  </TabPanel>
                  <TabPanel value='3' sx={{ padding: '10px 0px' }}>
                    <Typography component={'div'}>
                      <form
                        className='private-form'
                        id={'interview-form-three'}
                        onSubmit={handleSubmitThree(onSubmitThree)}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Candidate(s)</Typography>
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    disabled
                                    name={name}
                                    placeHolder='Candidate(s)'
                                    className='custom-input'
                                    error={errorsThree.candidate?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='candidate'
                                control={controlThree}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Subject</Typography>

                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    placeHolder='Subject'
                                    className='custom-input'
                                    error={errorsThree.subject?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='subject'
                                control={controlThree}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Message <span>*</span>
                              </Typography>
                              <>
                                <div
                                  className={
                                    errorsThree &&
                                    errorsThree.message &&
                                    errorsThree.message.message
                                      ? 'ckeditor-config ckeditor-container'
                                      : 'ckeditor-container'
                                  }>
                                  <CKEditor
                                    editor={Editor}
                                    data={getValuesThree('message')}
                                    // onChange={handleEditorChangeThree}
                                    // config={editorConfig}
                                  />
                                </div>
                                {errorsThree &&
                                  errorsThree.message &&
                                  errorsThree.message.message && (
                                    <div className='Custom-error '>
                                      {String(errorsThree.message.message)}
                                    </div>
                                  )}
                              </>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography className='bdiveder' />
                          </Grid>
                        </Grid>
                      </form>
                      <Typography className='d-flex-a flex-end mt-15'>
                        <Button
                          className='l-cancel-btn mr-16'
                          onClick={() => {
                            setTabValue('2');
                          }}>
                          Cancel
                        </Button>
                        <Button
                          className='l-save-btn'
                          form={'interview-form-three'}
                          type={'submit'}>
                          {'Save'}
                        </Button>
                      </Typography>
                    </Typography>
                  </TabPanel>
                </Typography>
              </TabContext>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default RevisionHistory;
