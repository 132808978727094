import './index.scss';
import { PieChart, Pie, Cell } from 'recharts';

export default function Donutreactchart({ resumeData, interview }: any) {
  return (
    <div className={interview ? 'donutchartcon-in' : 'donutchartcon'}>
      <PieChart width={215} height={215}>
        <Pie
          data={resumeData}
          cx={107.5}
          cy={107.5}
          innerRadius={60}
          outerRadius={80}
          fill='#8884d8'
          paddingAngle={5}
          dataKey='value'
          nameKey='name'>
          {resumeData.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      <div className='totalcon'>
        <div className='total'>Total</div>
        <div className='value'>30</div>
      </div>
    </div>
  );
}
