/* eslint-disable @typescript-eslint/no-unused-vars */
import FullCalendar from '@fullcalendar/react';
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
} from '@mui/material';
import { useDebounce } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  take?: number | null;
  sortby?: string | null;
  status?: string | null;
  year?: string;
  fromDate?: string | null;
  toDate?: string | null;
  branchId?: string;
}

const Defaultparam = {
  search: null,
  skip: 0,
  order: 'desc',
  sortby: '',
  status: null,
  fromDate: null,
  toDate: null,
  take: 50,
};
const renderEventContent = () => {
  return <div className='dot-marker'>•</div>;
};
export const CalenderEvents = ({ setLd, option }: any) => {
  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const [eventsList, setEventsList] = React.useState<any>([]);
  const [events, setEvents] = React.useState([]);
  const [params, setParams] = React.useState<Statetype>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const findMonth = new Date(debouncedParams.fromDate);
  const getList = () => {
    setLd(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${DASHBOARD_ENDPOINT.event}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res: any) => {
        const limitedData = res.data.slice(0, 7);
        setEventsList(limitedData);
        setEvents(
          res.data && Array.isArray(res.data)
            ? res.data.map((e: any) => ({
                backgroundColor: e.colour,
                dot: true,
                start: new Date(e.startsAt),
                title: '',
              }))
            : [],
        );
        setLd(false);
      })
      .catch((err) => {
        // console.log(e);
        setLd(false);
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };
  useEffect(() => {
    getList();
  }, [option, debouncedParams]);
  return (
    <div className='Calender'>
      <header>
        <div className='title'>Calender</div>
      </header>
      <div className='Calendar'>
        <div className='calendar-sidebar'>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            headerToolbar={{
              left: 'prev',
              center: 'title',
              right: 'next',
            }}
            weekends={true}
            editable={true}
            selectable={true}
            themeSystem='bootstrap'
            // events={events}
            displayEventTime={false}
            eventContent={renderEventContent}
            eventTimeFormat={{
              hour: 'numeric',
              meridiem: false,
            }}
            expandRows={true}
            events={(fetchInfo, successCallback) => {
              const middate = moment(
                (new Date(fetchInfo.startStr).getTime() + new Date(fetchInfo.endStr).getTime()) / 2,
              );
              const startOfMonth = middate.startOf('month').format('YYYY-MM-DD');
              const endOfMonth = middate.endOf('month').format('YYYY-MM-DD');

              setParams((prev) =>
                prev.fromDate !== startOfMonth
                  ? { ...prev, fromDate: startOfMonth, toDate: endOfMonth }
                  : prev,
              );

              successCallback(events);
            }}
          />
        </div>
      </div>
      <header style={{ display: 'flex', justifyContent: 'space-between', marginTop: '25px' }}>
        <div className='title'>ToDo List</div>
        <div className='view-all' onClick={() => navigate('/events')}>
          View All
        </div>
      </header>
      <TableContainer component={Paper} style={{ marginTop: '10px', overflowX: 'hidden' }}>
        <Table sx={{ minWidth: 200 }} className='custom-table' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                {findMonth.toLocaleString('default', { month: 'long' })} - Events{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventsList && eventsList.length ? (
              eventsList.map((row: any) => {
                const formatDate = (dateString: string | number | Date) => {
                  const date = new Date(dateString);
                  const months = [
                    'JAN',
                    'FEB',
                    'MAR',
                    'APR',
                    'MAY',
                    'JUN',
                    'JUL',
                    'AUG',
                    'SEP',
                    'OCT',
                    'NOV',
                    'DEC',
                  ];
                  const day = date.getUTCDate();
                  const month = months[date.getUTCMonth()];
                  const hours = date.getUTCHours();
                  const minutes = date.getUTCMinutes();
                  const period = hours >= 12 ? 'P.M.' : 'A.M.';

                  const formattedHours = hours % 12 || 12;
                  const formattedMinutes =
                    minutes === 0 ? '' : `:${minutes.toString().padStart(2, '0')}`;

                  return `${day}TH ${month} ${formattedHours}${formattedMinutes} ${period}`;
                };
                return (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <div>
                        <div>{row.title ? row.title : '-'}</div>
                        <div
                          style={{
                            width: '300px',
                            marginTop: '5px',
                            fontFamily: 'Poppins medium !important',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '12px',
                            color: '#808080',
                          }}>
                          SCHEDULED FOR {formatDate(row.startsAt)}
                        </div>
                      </div>
                      <div
                        style={{
                          height: '10px',
                          width: '10px',
                          backgroundColor: row.colour,
                          borderRadius: '10px',
                          marginRight: '25px',
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <tr className={'text-center'}>
                <td colSpan={1} align={'center'}>
                  No Record(s) Found.
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
