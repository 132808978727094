import { Grid, Typography, Breadcrumbs, Button, Link } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Controller, useForm } from 'react-hook-form';
import { holidaysAddSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import _ from 'lodash';
import { updateData, addNewData, getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const HolidayAdd = () => {
  const { editId } = useParams();
  const [loader, setLoader] = useState<any>(false);
  const [branchData, setBranchData] = useState<any>();
  const navigate = useNavigate();
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      branchName: '',
      eventDate: '',
      eventName: '',
      status: '',
      description: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(holidaysAddSchema),
  });

  const getInitialStatus = () => {
    if (editId) {
      getAllListData(`${SETTING_ENDPOINT.leaves.holidays}?id=${editId}`, true)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue('branchName', resp.data[0].branchId);
            setValue('eventDate', resp.data[0].holidayOn);
            setValue('eventName', resp.data[0].name);
            setValue('description', resp.data[0].description);
            setValue('status', resp.data[0].status ? 'active' : 'inActive');
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const onSubmitForm = (data: any) => {
    setLoader(true);
    if (!_.isUndefined(editId)) {
      updateData(
        editId,
        {
          organizationId: orgdy,
          branchId: data.branchName,
          holidayOn: data.eventDate,
          name: data.eventName,
          status: data.status === 'active' ? true : false,
          description: data.description,
        },
        `${String(SETTING_ENDPOINT.leaves.holidays)}`,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.LEAVE.HOLIDAY.HOLIDAYLIST}`);
          setLoader(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    } else {
      addNewData(
        {
          organizationId: orgdy,
          branchId: data.branchName,
          holidayOn: data.eventDate,
          name: data.eventName,
          status: data.status === 'active' ? true : false,
          description: data.description,
        },
        `${String(SETTING_ENDPOINT.leaves.holidays)}`,
        undefined,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.LEAVE.HOLIDAY.HOLIDAYLIST}`);
          setLoader(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    }
  };

  const branchList = () => {
    setLoader(true);
    getAllListData(
      `${SETTING_ENDPOINT.Branch}?organizationId=${orgdy}&branchType=user&status=true`,
      true,
    )
      .then((res) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setBranchData(options);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };
  useEffect(() => {
    getInitialStatus();
    branchList();
  }, []);

  return (
    <>
      <Grid container>
        {loader && <Loader />}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Leaves
              </Link>
              <Link underline='hover' color='inherit' href={'/settings/leave/holiday'}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Holidays
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {editId ? 'Edit' : 'Add'} Holiday
              </Typography>
            </Breadcrumbs>
          </Typography>
          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>{editId ? 'Edit' : 'Add'} Holiday</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => navigate('/settings/leave/holiday')}>
                Cancel
              </Button>
              <Button form={'branch-add'} className='s-add-btn' type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form
                className='private-form'
                id={'branch-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Branch Name <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='branchName'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={branchData}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Select your branch Name'
                                  error={errors?.branchName?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Event Date<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='eventDate'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  type={'date'}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Type your branch address'
                                  error={errors.eventDate?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Event Name<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='eventName'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Event Name'
                                  error={errors.eventName?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Status<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='status'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={[
                                    { label: 'Active', value: 'active' },
                                    { label: 'Inactive', value: 'inActive' },
                                  ]}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Status'
                                  error={errors.status?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>Description</Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='description'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  isTextArea={true}
                                  className='custom-input custom-input-position'
                                  placeHolder='Type your Description..'
                                  error={errors.description?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default HolidayAdd;
