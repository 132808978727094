import { Drawer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import { useForm } from 'react-hook-form';
import { InterviewFilterType } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { InterviewFilterForm } from './InterviewFilterFrorm';
import { InterviewFilterSchema } from 'utils/ValidatorSchema';

interface ResumeFilterDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  callback: (filters: InterviewFilterType) => void;
  setParams?: any;
  setcommonstate?: any;
  commonState?: any;
  setLoading?: any;
  isLoading?: any;
  setpagetotal?: any;
  listdataapi?: any;
}

export const InterviewFilterDrawer: React.FC<ResumeFilterDrawerProps> = ({
  isOpen,
  closeDrawer,
  // callback,
  setParams,
  // setcommonstate,
  // setLoading,
  // setpagetotal,
  listdataapi,
}) => {
  const [key, setKey] = useState(0);
  const { control, handleSubmit, reset, formState, watch, trigger, getValues } =
    useForm<InterviewFilterType>({
      criteriaMode: 'all',
      defaultValues: {
        fromDate: undefined,
        toDate: undefined,
        currentStatus: undefined,
        employee: undefined,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(InterviewFilterSchema),
    });

  const closeSideDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const resetFilters = useCallback(() => {
    setParams((prev: any) => ({
      ...prev,
      from: null,
      to: null,
      currentStatus: null,
      employee: null,
    }));
    reset();
    listdataapi();
    setKey((prev: number) => prev + 1);
  }, [reset]);

  const onSubmit = (data: any) => {
    if (data.currentStatus || data.fromDate || data.toDate || data.employee) {
      setParams((prev: any) => ({
        ...prev,
        from: data.fromDate ? data.fromDate : null,
        to: data.toDate ? data.toDate : null,
        currentStatus: data.currentStatus ? data.currentStatus : null,
        employee: data.employee ? data.employee : null,
      }));
    }
  };
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        ['& .MuiDrawer-paper']: {
          boxSizing: 'border-box',
          marginTop: '72px',
          width: '300px',
          padding: '30px',
          paddingTop: '0px',
        },
        ['& .MuiModal-backdrop ']: { backgroundColor: 'transparent' },
      }}>
      <header className='clientfilter-header'>
        <img
          src={CloseIcon}
          alt='closeicon'
          className='clientfilter-close-ico'
          onClick={closeSideDrawer}
        />
        <div className='heading'>Filters</div>
        <div
          className='clearall'
          style={{
            ...(!(
              watch('currentStatus') ||
              watch('employee') ||
              watch('fromDate') ||
              watch('toDate')
            )
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}
          onClick={() =>
            watch('currentStatus') || watch('employee') || watch('fromDate') || watch('toDate')
              ? resetFilters()
              : ''
          }>
          Clear All
        </div>
      </header>
      <div className='clientfilter-divider'></div>
      <InterviewFilterForm
        setParams={setParams}
        key={key}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        onSubmit={onSubmit}
        watch={watch}
        trigger={trigger}
        getValues={getValues}
      />
    </Drawer>
  );
};
