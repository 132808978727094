/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Box,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Controller, useForm } from 'react-hook-form';
import { branchFormSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import _ from 'lodash';
// import { toast } from 'react-toastify';
import { updateData, addNewData, getAllListData } from 'services/CommonServices';
// import { alertOptions } from 'utils/Config';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
// import { toast } from 'react-toastify';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Alert from '@mui/material/Alert';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const branchesAdd = () => {
  const { editId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState({ opa: !true, msg: '' });
  const [loader, setLoader] = useState<any>(false);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    // setError,
    // watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      branchName: '',
      branchType: '',
      // address: '',
      // pincode: '',
      status: 'active',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(branchFormSchema),
  });

  const handleClose = () => {
    setOpen({ opa: !true, msg: '' });
  };

  const getInitialStatus = () => {
    if (editId) {
      getAllListData(`${SETTING_ENDPOINT.Branch}?id=${editId}`, true)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue('branchName', resp.data[0].name);
            setValue('branchType', resp.data[0].type);
            // setValue('address', resp.data[0].address);
            // setValue('pincode', resp.data[0].pincode);
            setValue('status', resp.data[0].status ? 'active' : 'inactive');
          }
          setLoader(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
          // console.log(e);
        });
    }
  };

  const onSubmitForm = (data: any) => {
    setLoader(true);
    if (!_.isUndefined(editId)) {
      updateData(
        editId,
        {
          organizationId: orgdy ? orgdy : '',
          name: data.branchName,
          type: data.branchType,
          // address: data.address,
          // pincode: data.pincode,
          status: data.status === 'active' ? true : false,
        },
        `${String(SETTING_ENDPOINT.Branch)}`,
        true,
      )
        .then(() => {
          // toast.success('Branch Details Updated Successfully', alertOptions);
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.BRANCHES.BRANCHESLIST}`);
          setLoader(false);
        })
        .catch((e: any) => {
          // console.log(e);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
          setOpen({ opa: true, msg: e ? e : '' });
        });
    } else {
      addNewData(
        {
          organizationId: orgdy ? orgdy : '',
          name: data.branchName,
          type: data.branchType,
          // address: data.address,
          // pincode: data.pincode,
          status: data.status === 'active' ? true : false,
        },
        `${String(SETTING_ENDPOINT.Branch)}`,
        undefined,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.BRANCHES.BRANCHESLIST}`);
          setLoader(false);
        })
        .catch((e) => {
          // console.log(e);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
          setOpen({ opa: true, msg: e ? e : '' });
        });
    }
  };
  useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <>
      <Grid container>
        {loader && <Loader />}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href=''
                onClick={() => navigate('/settings/branches')}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Branches
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {editId ? 'Edit' : 'Add'} Branch
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>{editId ? 'Edit' : 'Add'} Branch</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button className='cancel-btn mr-16' onClick={() => navigate('/settings/branches')}>
                Cancel
              </Button>
              <Button form={'branch-add'} className='s-add-btn' type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form
                className='private-form'
                id={'branch-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Branch Name <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='branchName'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Type your branch name'
                                  error={errors.branchName?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Branch Type <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='branchType'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={[
                                    { label: 'Client', value: 'client' },
                                    { label: 'User', value: 'user' },
                                  ]}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Select your branch type'
                                  error={errors.branchType?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>

                      {/* <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Address <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='address'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Type your branch address'
                                error={errors.address?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Pincode <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='pincode'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                className='custom-input'
                                placeHolder='Type your pincode'
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                error={errors.pincode?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid> */}

                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Status <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=''
                            name='status'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <RadioGroup
                                  className='custom-radio'
                                  row
                                  aria-labelledby=''
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value='active'
                                    control={<Radio />}
                                    label='Active'
                                    checked={value === 'active'}
                                  />
                                  <FormControlLabel
                                    value='inactive'
                                    control={<Radio />}
                                    label='Inactive'
                                    checked={value === 'inactive'}
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <React.Fragment>
        <Dialog
          open={open.opa}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='dialogmoda'>
          <Box className='cusmoda'>
            <header id='alert-dialog-title'>
              <ReportGmailerrorredIcon color={'error'} fontSize='large' />
              {open && open.msg ? open.msg : ''}
            </header>
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Button onClick={handleClose} className='s-add-btn' autoFocus>
              OK
            </Button>
          </Box>
        </Dialog>
      </React.Fragment>
    </>
  );
};
export default branchesAdd;
