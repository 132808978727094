import React, { useCallback } from 'react';
import Refresh from 'assets/png/reset.svg';
import Preview from 'assets/png/preview.svg';
import { Button, Grid, Typography } from '@mui/material';
import DateTimepicker from 'components/CustomDateRangePicker';
import CustomSelect from 'components/CustomSelect/customSelect';
import { ReportFilterSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// import moment from 'moment';

export const ReportFilter = ({ setParams }: any) => {
  const { control, handleSubmit, reset } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      mergedDate: undefined,
      fromDate: undefined,
      toDate: undefined,
      type: undefined,
      role: undefined,
      employee: undefined,
      branch: undefined,
      report: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ReportFilterSchema),
  });
  const resetFilters = useCallback(() => {
    setParams((prev: any) => ({
      ...prev,
      fromDate: null,
      mergedDate: null,
      toDate: null,
      type: null,
      role: null,
      employee: null,
      branch: null,
      report: null,
    }));
    reset();
  }, [reset]);
  const onSubmit = (data: any) => {
    setParams((prev: any) => ({
      ...prev,
      // fromDate: data.mergedDate?.[0] ? moment(data.mergedDate[0]).format('YYYY-MM-DD') : null,
      // toDate: data.mergedDate?.[1] ? moment(data.mergedDate[1]).format('YYYY-MM-DD') : null,
      fromDate: '2024-01-01',
      toDate: '2024-11-13',
      type: data.type || null,
      role: data.role || null,
      employee: data.employee || null,
      branch: data.branch || null,
      report: data.report || null,
    }));
  };

  return (
    <Typography
      component={'div'}
      className='d-flex-a flex-sb title-add-box'
      sx={{ padding: '14px 15px 14px 50px', justifyContent: 'unset' }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Grid container columnGap={2}>
          <Grid item md={2} sm={12} xs={12}>
            <Controller
              control={control}
              name='mergedDate'
              defaultValue={undefined}
              render={({ field: { onChange, value, name } }) => {
                return <DateTimepicker onChange={onChange} value={value} name={name} />;
              }}
            />
          </Grid>
          <Grid item md={1.2} sm={12} xs={12}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '135px', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='type'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[
                        { label: 'Monthly Opening(CTC)', value: 'ctcWise' },
                        { label: 'Client Opening(CTC)', value: 'clientWise' },
                        { label: 'Client Opening(Month)', value: 'monthWise' },
                      ]}
                      name={name}
                      report={true}
                      isClearable={false}
                      value={value ? value : null}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder='Select'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.2} sm={12} xs={12}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '135px', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='role'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[]}
                      name={name}
                      isClearable={false}
                      report={true}
                      value={value ? value : null}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder='Select'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.2} sm={12} xs={12}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '135px', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='employee'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[]}
                      name={name}
                      report={true}
                      isClearable={false}
                      value={value ? value : null}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder='Select'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.2} sm={12} xs={12}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '135px', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='branch'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[]}
                      name={name}
                      report={true}
                      isClearable={false}
                      value={value ? value : null}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder='Select'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.2} sm={12} xs={12}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '135px', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='report'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[
                        { label: 'Table', value: 'table' },
                        { label: 'Graph', value: 'graph' },
                        { label: 'Tabel-Graph', value: 'all' },
                      ]}
                      name={name}
                      report={true}
                      isClearable={false}
                      value={value ? value : null}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder='Select'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1} sm={12} xs={12}>
            <Button className='s-add-btn' type='submit' sx={{ textTransform: 'none !important' }}>
              {'Apply'}
            </Button>
          </Grid>
          <Grid
            item
            md={1.8}
            sm={12}
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <img
              src={Refresh}
              alt='Reset'
              style={{ height: '30px', width: '30px', cursor: 'pointer' }}
              onClick={resetFilters}
            />
            <img
              src={Preview}
              alt='Preview'
              style={{ height: '30px', width: '30px', marginLeft: '20px', cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
      </form>
    </Typography>
  );
};
