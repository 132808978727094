import React from 'react';
import reportIcon from 'assets/png/reporticon.svg';

const ReportIcon = () => {
  return (
    <div className='d-flex-cc mt-150'>
      <img src={reportIcon} alt={'report'} />
    </div>
  );
};

export default ReportIcon;
