
function ProductivityRecruiterChart({c1,c2,c3,c4}:{c1:number;c2:number;c3:number;c4:number;}) {
  return (
    <>
    <div style={{position:'relative',display:'inline-block'}}>
        <div className="circSvgTxt" style={{position:'absolute',top:'1%',left:'52%',}}>{`${c1*100}%`}</div>
        <div className="circSvgTxt" style={{position:'absolute',top:'8%',left:'52%',}}>{`${c2*100}%`}</div>
        <div className="circSvgTxt" style={{position:'absolute',top:'16%',left:'52%',}}>{`${c3*100}%`}</div>
        <div className="circSvgTxt" style={{position:'absolute',top:'24%',left:'52%',}}>{`${c4*100}%`}</div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="256"
      fill="none"
      viewBox="0 0 256 256"
      style={{
        transformBox: 'fill-box',
        transform:'rotate(-90deg)  scaleY(-1)',
      }}
    >
      <circle
        cx="128"
        cy="128"
        r="120"
        fill="none"
        stroke="#3B82F6"
        strokeDasharray="754.089"
        strokeDashoffset={`${754.089*(1-c1)}`}
        strokeWidth="12"
      ></circle>
      <circle
        cx="128"
        cy="128"
        r="100"
        fill="none"
        stroke="#F59E0B"
        strokeDasharray="628.406"
        strokeDashoffset={`${628.406*(1-c2)}`}
        strokeWidth="12"
      ></circle>
      <circle
        cx="128"
        cy="128"
        r="80"
        fill="none"
        stroke="#14B8A6"
        strokeDasharray="502.725"
        strokeDashoffset={`${502.725*(1-c3)}`}
        strokeWidth="12"
      ></circle>
      <circle
        cx="128"
        cy="128"
        r="60"
        fill="none"
        stroke="#A855F7"
        strokeDasharray="377.044"
        strokeDashoffset={`${377.044*(1-c4)}`}
        strokeWidth="12"
      ></circle>
    </svg>
    </div>
    </>
  );
}

export default ProductivityRecruiterChart;
