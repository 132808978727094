import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

// this is the inner circle with whatever you want inside
const CustomProgressBar = (props: {
  [x: string]: any;
  value: number;
  children: any;
  text: string;
}) => {
  const { children, value, text, ...otherProps } = props;
  return (
    <div
      style={{
        bottom: 35,
        position: 'relative',
        width: '100%',
        height: '100%',
      }}>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <CircularProgressbar {...otherProps} value={value} text={text} />
      </div>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {children}
      </div>
    </div>
  );
};

export const ProgressBar = (props: any) => {
  const { percentage, endColor, startColor, gradientId, children, midColor, text } = props;
  const gradientTransform = 'rotate(90)';
  return (
    <div
      className='progress-bar'
      style={{
        marginLeft: '30px',
        width: '50px',
      }}>
      <svg style={{ height: 0, width: 0 }}>
        <defs>
          <linearGradient id={gradientId} gradientTransform={gradientTransform}>
            <stop offset='10%' stopColor={startColor} />
            <stop offset='40%' stopColor={midColor} />
            <stop offset='50%' stopColor={endColor} />
          </linearGradient>
        </defs>
      </svg>
      <CustomProgressBar
        text={text}
        value={percentage}
        strokeWidth='10'
        styles={{ path: { stroke: `url(#${gradientId})` }, textLength: 73 }}
        textLength={73}>
        {children}
      </CustomProgressBar>
    </div>
  );
};
