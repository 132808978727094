
function SuccessLinechart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="39"
      fill="none"
      viewBox="0 0 121 39"
    >
      <path
        stroke="#BDBDBD"
        strokeDasharray="4 4"
        strokeLinecap="round"
        d="M1.5 22.5L118.5 22.5"
      ></path>
      <path
        stroke="#16C06E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 35.75C8.5 32.75 15 17 20 17c5.5 0 5 12 12 12s7.5 8.5 11.5 8.5c3.5 0 3.5-17 9.5-17s6.5-11 11-11c5 0 6.5 21 12 21 6.5 0 11-22.5 17.5-23 4 0 6.5 9.5 11 9.5s10-16 15.5-16"
      ></path>
    </svg>
  );
}

export default SuccessLinechart;
