import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';

export const Skills = ({ resumeId }: any) => {
  const [skillsData, setSkillsData] = useState<any>([]);

  React.useEffect(() => {
    if (resumeId) {
      getAllListData(`${ResumesENDPOINT.resumeSkills}?resumeId=${resumeId}`)
        .then(async (res: any) => {
          setSkillsData(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [resumeId]);
  return (
    <Box className='skills-container'>
      {skillsData &&
        skillsData?.map((res: any) => (
          <Box key={res?.id} className='skill-tag'>
            <Typography component='div' className='f-family'>
              {res?.name}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};
