import { Drawer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientFilterForm } from './ClientFilterForm';
import { ClientFilterSchema } from 'utils/ValidatorSchema';
import { ClientFilterType } from 'types';
import { useLocation } from 'react-router-dom';

interface ClientFilterDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  setParams?: any;
  setcommonstate?: any;
  commonState?: any;
  setLoading?: any;
  isLoading?: any;
  setpagetotal?: any;
  listdataapi?: any;
}

export const ClientFilterDrawer: React.FC<ClientFilterDrawerProps> = ({
  isOpen,
  closeDrawer,
  setParams,
  // setLoading,
  listdataapi,
}) => {
  const location: any = useLocation();
  const [key, setKey] = useState(0);
  const { control, handleSubmit, reset, formState, watch, trigger, setValue } =
    useForm<ClientFilterType>({
      criteriaMode: 'all',
      defaultValues: {
        from: undefined,
        to: undefined,
        status: undefined,
        crm: undefined,
        approvalStatus: undefined,
        location: undefined,
        industry: undefined,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(ClientFilterSchema),
    });

  const closeSideDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const resetFilters = useCallback(() => {
    setParams((prev: any) => ({
      ...prev,
      from: null,
      to: null,
      approvalStatus: null,
      userId: null,
      location: null,
      industry: null,
      status: null,
    }));
    reset();
    listdataapi();
    setKey((prev: number) => prev + 1);
  }, [reset]);

  const onSubmit = (data: any) => {
    if (
      data.from ||
      data.to ||
      data.crm ||
      data.approvalStatus ||
      data.location ||
      data.status ||
      data.industry
    ) {
      if (!location.pathname.includes('approve')) {
        setParams((prev: any) => ({
          ...prev,
          from: data.from ? data.from : null,
          to: data.to ? data.to : null,
          approvalStatus: data.approvalStatus ? data.approvalStatus : null,
          userId: data.crm ? data.crm : null,
          location: data.location ? data.location : null,
          industry: data.industry ? data.industry : null,
          status: data.status,
        }));
      } else {
        setParams((prev: any) => ({
          ...prev,
          from: data.from ? data.from : null,
          to: data.to ? data.to : null,
          approvalStatus: 'inReview',
          userId: data.crm ? data.crm : null,
          location: data.location ? data.location : null,
          industry: data.industry ? data.industry : null,
          status: data.status,
        }));
      }
    }
  };

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        ['& .MuiDrawer-paper']: {
          boxSizing: 'border-box',
          marginTop: '72px',
          width: '300px',
          padding: '30px',
          paddingTop: '0px',
        },
        ['& .MuiModal-backdrop ']: { backgroundColor: 'transparent' },
      }}>
      <header className='clientfilter-header'>
        <img
          src={CloseIcon}
          alt='closeicon'
          className='clientfilter-close-ico'
          onClick={closeSideDrawer}
        />
        <div className='heading'>Filters</div>
        <span
          className='clearall'
          style={{
            ...(!(
              watch('approvalStatus') ||
              watch('crm') ||
              watch('from') ||
              watch('industry') ||
              watch('location') ||
              watch('status') ||
              watch('to')
            )
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}
          onClick={() =>
            watch('approvalStatus') ||
            watch('crm') ||
            watch('from') ||
            watch('industry') ||
            watch('location') ||
            watch('status') ||
            watch('to')
              ? resetFilters()
              : ''
          }>
          Clear All
        </span>
      </header>
      <div className='clientfilter-divider'></div>
      <ClientFilterForm
        setParams={setParams}
        key={key}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        onSubmit={onSubmit}
        watch={watch}
        trigger={trigger}
        setValue={setValue}
      />
    </Drawer>
  );
};
