/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Badge,
} from '@mui/material';
import { useDebounce } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ClientsENDPOINT, DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';
import SearchIcon from 'assets/png/nsearch.svg';
import CloseIcon from '@mui/icons-material/Close';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Paper from '@mui/material/Paper';
import Paginate from 'components/Pagination/Pagination';
import moment from 'moment';
import { CommomFilter } from './CommomFilter';

export const RecentClients = ({ setLd, option }: any) => {
  const Today = new Date();
  const oneWeekBefore = new Date(Today);
  oneWeekBefore.setDate(Today.getDate() - 7);
  const {
    userInfo: { organizationId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const Defaultparam = {
    search: null,
    skip: 0,
    take: 10,
    from: moment(oneWeekBefore).format('YYYY-MM-DD'),
    to: moment(Today).format('YYYY-MM-DD'),
    type: option.toLowerCase(),
    dateChange: false,
  };
  const navigate = useNavigate();
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [dweropen, setdweropen] = useState(false);
  const dwerclose = () => setdweropen(false);
  const debouncedParams = useDebounce(params, 500);
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [visible, setVisible] = useState(true);

  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }
  function handleFilterPag(filteredobj1: any) {
    setParams((prev: any) => ({
      ...prev,
      ...filteredobj1,
    }));
  }
  const listdataapi = () => {
    const searcharr = [
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
      `${debouncedParams.from ? `fromDate=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `toDate=${debouncedParams.to}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);

    getAllListData(
      `${DASHBOARD_ENDPOINT.Clients}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        setcommonstate([...val.data]);
        setpagetotal(Number(val.total));
        setLd(!true);
        dwerclose();
      })
      .catch((err) => {
        dwerclose();
        setLd(!true);
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };
  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);
  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Recent Clients</div>
        <Typography component={'div'} className='d-flex-cc flex-sb'>
          <TextField
            className='custom-search'
            placeholder='Search'
            id='outlined-start-adornment'
            value={params.search}
            sx={{ width: '200px', marginRight: '25px' }}
            onChange={(e: any) =>
              setParams((prev: any) => ({
                ...prev,
                search: e.target.value,
                skip: 0,
                take: 50,
              }))
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={SearchIcon} alt='search' />
                </InputAdornment>
              ),
              endAdornment: params.search ? (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    edge='end'
                    onClick={() => {
                      setParams(() => ({ ...Defaultparam, search: '' }));
                    }}>
                    <CloseIcon style={{ height: '20px', width: '20px' }} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <Typography
            component={'div'}
            sx={{ marginRight: '25px' }}
            className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
            onClick={() => setdweropen(true)}>
            <Badge color='error' variant='dot' invisible={visible}>
              <img src={Funnelimg} alt='filter funnel icon' />
            </Badge>
          </Typography>
          <div className='view-all' onClick={() => navigate('/clients')}>
            View All
          </div>
        </Typography>
      </header>
      <div className='borderdivider' style={{ marginTop: '20px' }}></div>
      <TableContainer
        component={Paper}
        className='sb-table2'
        sx={{ width: '100% !important', maxHeight: '400px', height: '100%' }}>
        <Table
          sx={{ minWidth: 350 }}
          stickyHeader
          className='custom-table custom-table-client'
          aria-label='sticky Header'>
          <TableHead>
            <TableRow
              sx={{
                marginLeft: '50px',
                marginRight: '43px',
                // width: 'calc(100% - 93px)',
              }}>
              <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                Client
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                Location
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                Created By
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                Created On
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                No. Positions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commonstate && commonstate.length > 0 ? (
              commonstate.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          // justifyContent:'center',
                          alignItems: 'center',
                          gap: '10px',
                          cursor: 'pointer',
                        }}>
                        {row && row?.corporateName ? row?.corporateName : ' '}
                      </div>
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                      {row && row?.country ? row?.country : ' '}
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                      {row && row.firstName ? `${row.firstName} ${row.lastName}` : '-'}
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                      {row && row?.createdAt ? moment(row?.createdAt).format('DD-MM-YYYY') : ' '}
                    </TableCell>{' '}
                    <TableCell
                      align='center'
                      sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                      {row && row.noOfOpenings ? row.noOfOpenings : ' '}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} align={'center'}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '60px',
                    }}>
                    <NCAdedIcon />
                    <Typography className='no-client'>No Client added</Typography>
                  </div>
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={10} dashBoard={true} />
      {dweropen && (
        <CommomFilter
          handleOpen={dweropen}
          handleClose={dwerclose}
          setParams={setParams}
          params={params}
          setVisible={setVisible}
          getApi={listdataapi}
          type='clients'
        />
      )}
    </div>
  );
};
