import Paginate from 'components/Pagination/Pagination';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

// Data for the chart
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const data = [
  { name: 'Accenture - 9', blue: 100, green: 71, purple: 50 },
  { name: 'BirlaSoft - 8', blue: 84, green: 60, purple: 0 },
  { name: 'Capgemini - 7', blue: 72, green: 72, purple: 50 },
  { name: 'Cognizant - 6', blue: 57, green: 41, purple: 13 },
  { name: 'HCL Technologies - 5', blue: 64, green: 45, purple: 0 },
  { name: 'Infosys - 4', blue: 77, green: 55, purple: 17 },
  { name: 'L & T Infotech - 3', blue: 47, green: 47, purple: 17 },
  { name: 'iTech India - 2', blue: 43, green: 31, purple: 0 },
  { name: 'Oracle - 1', blue: 40, green: 28, purple: 0 },
];

// Custom Legend Component
const CustomLegend = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        position: 'absolute',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 20,
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#F2F2F7',
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#357AF6',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ color: '#000', fontWeight: 'bold', marginRight: 5 }}>
            CV Sent to shortlisted %
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 20,
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#F2F2F7',
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#11AF22',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ color: '#000', fontWeight: 'bold', marginRight: 5 }}>
            CV Sent to Interviewed %
          </span>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 20,
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#F2F2F7',
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#673147',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ color: '#000', fontWeight: 'bold', marginRight: 5 }}>
            Openings Worked to Billed %
          </span>
        </div>
      </div>
    </div>
  );
};
const CustomBarChart = () => {
  return (
    <>
      <div style={{ width: '643px', height: '450px', margin: '0 auto' }}>
        {' '}
        {/* Fixed chart size */}
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart
            layout='vertical'
            data={data}
            margin={{ top: 20, right: 30, left: 100, bottom: 5 }}
            barSize={22} // Fixed height for each bar
          >
            {/* X and Y axis */}
            <XAxis
              type='number'
              domain={[0, 250]}
              tickFormatter={(tick) => `${tick}%`}
              tickLine={false}
            />
            <YAxis type='category' dataKey='name' width={150} tickLine={false} />

            {/* Tooltip */}
            <Tooltip formatter={(value) => `${value}%`} />

            {/* Bars with specified colors */}
            <Bar dataKey='blue' fill='#357AF6' stackId='a'>
              <LabelList
                dataKey='blue'
                position='center'
                fill='white'
                formatter={(value: any) => `${value}%`}
              />
            </Bar>
            <Bar dataKey='green' fill='#11AF22' stackId='a'>
              <LabelList
                dataKey='green'
                position='center'
                fill='white'
                formatter={(value: any) => `${value}%`}
              />
            </Bar>
            <Bar dataKey='purple' fill='#673147' stackId='a'>
              <LabelList
                dataKey='purple'
                position='center'
                fill='white'
                formatter={(value: any) => `${value}%`}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {/* Custom Legend */}
      </div>
      <div>
        <CustomLegend />
        <Paginate total={100} DefaultPerPage={10} report={true} />
      </div>
    </>
  );
};

export default CustomBarChart;
