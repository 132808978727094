import React, { useEffect, useRef, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
// import ExcelIcon from 'assets/iconcomponents/excelicon';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Loader from 'components/loader';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { useNavigate } from 'react-router-dom';
import Paginate from 'components/Pagination/Pagination';
import { useDebounce } from 'hooks';
import EditIcon from 'assets/png/edit.svg';
import DeleteIcon from 'assets/png/delete.svg';
import { deleteData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETTING_ENDPOINT } from 'utils/Constant';
import moment from 'moment';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import CustomSelect from 'components/CustomSelect/customSelect';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import PreIcon from 'assets/png/pre.svg';
import { AccessDenied } from 'utils/helper';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  take?: number | null;
  sortby?: string | null;
  status?: string | null;
  year?: number | null;
  branchId?: string;
}

const Defaultparam = {
  search: null,
  skip: 0,
  order: 'asc',
  sortby: 'holidayOn',
  status: null,
  take: 50,
  branchId: '',
  year: Number(new Date().getFullYear()),
};

export const HolidayList = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [loader, setLoader] = useState<boolean>(false);
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const [pagetotal, setpagetotal] = useState<number>(0);
  const debouncedParams = useDebounce(params, 500);
  const [holidayList, setHolidayList] = useState<any>();
  const [branchGetData, setBranchGetData] = useState<any>();
  const navigate = useNavigate();
  const containerRef = useRef(null) as any;
  const [scrollPosition, setScrollPosition] = useState(0);
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
      `${debouncedParams.year ? `year=${debouncedParams.year}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.leaves.holidays}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res: any) => {
        setpagetotal(Number(res.total));
        setHolidayList(res && res.data);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  const deletebtn = async (id: any) => {
    setLoader(true);
    try {
      await deleteData(id, `${SETTING_ENDPOINT.leaves.holidays}`, true);
      setConfirmOpen({
        show: false,
        id: '',
      });
      getList();
    } catch (err) {
      toast.error(String(err), alertOptions);
    } finally {
      setLoader(false);
    }
  };
  const getBranchList = () => {
    setLoader(true);
    getAllListData(
      `${SETTING_ENDPOINT.Branch}?organizationId=${orgdy}&branchType=user&status=true`,
      true,
    )
      .then((res) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setBranchGetData(options);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };
  useEffect(() => {
    getList();
    getBranchList();
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Leaves
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Holidays
            </Typography>
          </Breadcrumbs>
        </Typography>
        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Holidays</Typography>
          <Typography component={'div'} className='d-flex-a'>
            {/* <div className='expot-icon'>
              <span>
                <ExcelIcon
                  url={
                    'users?type=export&roles=1,2,3,4,5,6,7,8,9,10,11,12,13,14&order=asc&sortby=name'
                  }
                  fileName={'Users'}
                  setLoader={setLoader}
                />
              </span>
            </div> */}
            <Button
              className='add-btn'
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.LEAVE.HOLIDAY.HOLIDAYADD)}>
              <img src={AddIcon} alt='plus' /> Add Holiday
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <Grid container>
              <Grid md={9} sm={12} xs={12}>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Grid>
              <Grid md={3} sm={12} xs={12} sx={{ display: 'flex' }}>
                <Typography component={'div'} style={{ width: '50%' }}>
                  <CustomSelect
                    labelKey={'label'}
                    valueKey={'value'}
                    options={branchGetData}
                    onChange={(e) => {
                      setParams((prev: any) => ({
                        ...prev,
                        branchId: e.target.value,
                        skip: 0,
                        take: 50,
                      }));
                    }}
                    placeHolder='Branch'
                  />
                </Typography>
                <Typography component={'div'} style={{ width: '50%', marginLeft: '15px' }}>
                  <CustomSelect
                    labelKey={'label'}
                    valueKey={'value'}
                    options={[{ label: new Date().getFullYear(), value: new Date().getFullYear() }]}
                    onChange={(e) => {
                      setParams((prev: any) => ({
                        ...prev,
                        year: e.target.value,
                        skip: 0,
                        take: 50,
                      }));
                    }}
                    placeHolder='Year'
                  />
                </Typography>
              </Grid>
            </Grid>
            <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
              <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                <img src={PreIcon} alt='' />
              </Button>
              <Button onClick={() => handleScroll(200)} className='next-btn'>
                <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
              </Button>
              <TableContainer component={Paper} className='sb-table' ref={containerRef}>
                <Table
                  sx={{ minWidth: '1200px' }}
                  className='custom-table'
                  aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No. </TableCell>
                      <TableCell className='w200'>Event</TableCell>
                      <TableCell className='w100'>Event Date</TableCell>
                      <TableCell className='w100'>Branch</TableCell>
                      <TableCell className='w100'>Created On</TableCell>
                      <TableCell className='w100'>Status</TableCell>
                      <TableCell className='w100'>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {holidayList && holidayList.length ? (
                      holidayList.map((row: any, index: number) => {
                        const canEditOrEdit = moment(row.holidayOn).isSameOrAfter(new Date());
                        return (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell className='w100' sx={{ textTransform: 'capitalize' }}>
                              {index + 1}
                            </TableCell>
                            <TableCell className='w200' sx={{ textTransform: 'capitalize' }}>
                              <TableTooltip value={row?.name ? row?.name : '-'} />
                            </TableCell>
                            <TableCell className='w100' sx={{ textTransform: 'capitalize' }}>
                              {row?.holidayOn ? moment(row?.holidayOn).format('DD-MM-YYYY') : '-'}
                            </TableCell>
                            <TableCell className='w100' sx={{ textTransform: 'capitalize' }}>
                              {row?.branch?.name ? row?.branch?.name : '-'}
                            </TableCell>
                            <TableCell className='w100' sx={{ textTransform: 'capitalize' }}>
                              {row?.createdAt ? moment(row?.createdAt).format('DD-MM-YYYY') : '-'}
                            </TableCell>
                            <TableCell className='w100'>
                              <button
                                className='interview-status-btn'
                                style={{
                                  backgroundColor: row?.status === true ? '#16C06E' : '#EE4B22',
                                  color: '#fff',
                                }}>
                                {row?.status === true ? 'Active' : 'Inactive'}
                              </button>
                            </TableCell>
                            <TableCell>
                              <img
                                src={EditIcon}
                                alt='Edit'
                                style={canEditOrEdit ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
                                className='pointer'
                                onClick={() =>
                                  canEditOrEdit &&
                                  navigate(
                                    `${AdminPrivateRoutes.SETTINGS.LEAVE.HOLIDAY.HOLIDAYEDIT}/${row.id}`,
                                  )
                                }
                              />
                              <img
                                src={DeleteIcon}
                                alt='Delete'
                                style={canEditOrEdit ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
                                className='ml-24 pointer'
                                onClick={() =>
                                  canEditOrEdit &&
                                  setConfirmOpen((pre: any) => ({
                                    ...pre,
                                    show: true,
                                    id: row.id,
                                  }))
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={5} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Grid container className='mt-24'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <ConfirmDialog
        title='Confirm'
        floatingBtnShow={true}
        open={confirmOpen.show}
        close={() =>
          setConfirmOpen({
            show: false,
            id: '',
          })
        }
        visibleClose={true}
        onConfirm={(act: any) =>
          act === 'yes'
            ? deletebtn(confirmOpen.id)
            : setConfirmOpen({
                show: false,
                id: '',
              })
        }
        autoCloseDisable={true}>
        {'Are you sure do you want to delete this holiday?'}
      </ConfirmDialog>
    </Grid>
  );
};
