import SuccessLinechart from './icon/successlinechart';
import FailureLinechart from './icon/failurelinechart';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';
import {
  Box,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import moment from 'moment';
import TableTooltip from 'components/Tooltip/Tabletooltip';

export const CalculationTable = ({ setLd, option }: any) => {
  const Today = new Date();
  const navigate = useNavigate();
  const [CalculationCardData, setCalaculationCardData] = React.useState<any>([]);
  const [CardModalOpen, setCardModalOpen] = React.useState<any>({
    open: false,
    data: {},
  });
  const handleModalClose = () => {
    setCardModalOpen((prev: any) => ({
      ...prev,
      open: false,
      data: {},
    }));
  };
  const handleModalOpen = (data: any) => {
    setCardModalOpen((prev: any) => ({
      ...prev,
      open: true,
      data: data,
    }));
  };
  const CalculationCountData = () => {
    setLd(true);
    getAllListData(
      `${
        DASHBOARD_ENDPOINT.CalculationTable
      }?type=${option.toLowerCase()}&fromDate=${getFirstDateThreeMonthsAgo()}&toDate=${moment(
        Today,
      ).format('YYYY-MM-DD')}`,
      true,
    )
      .then((val: any) => {
        const names = ['Alpha', 'Bravo', 'Charlie'];
        const coloredData = val.data.map((item: any, index: number) => ({
          ...item,
          name: names[index % names.length],
        }));
        setCalaculationCardData(coloredData);
        setLd(false);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(false);
      });
  };
  const getFirstDateThreeMonthsAgo = () => {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 3);
    return currentDate.toISOString().split('T')[0];
  };

  React.useEffect(() => {
    CalculationCountData();
  }, [option]);
  return (
    <>
      <div className='headingContainer'>
        <div className='heading'>Calculation Table</div>
      </div>
      <div className='calculationTable'>
        {CalculationCardData.map((er: any) => {
          const [year, month] = er.month.split('-');
          const date = new Date(year, month - 1);
          const form: any = { year: 'numeric', month: 'long' };
          const formattedDate = new Intl.DateTimeFormat('en-US', form).format(date);

          return (
            <>
              <div className={`card ${er && er.name ? er.name : ''}`}>
                <header>
                  <div className='heading'>Criteria</div>
                  <div className='month'>{formattedDate.split(' ')[0]}</div>
                  <div className='report' onClick={() => handleModalOpen(er)}>
                    View Report
                  </div>
                </header>
                <main>
                  <div className='description'>
                    The billing value for the month of {formattedDate}
                  </div>
                </main>
                <footer>
                  <div className='billingvalue'>
                    <div className='heading'>Billing Value</div>
                    <div className='price'>
                      &nbsp;
                      <TableTooltip
                        value={new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                          minimumFractionDigits: 2,
                        })
                          .format(er.billingValue)
                          .replace('₹', '₹ ')}
                      />
                    </div>
                  </div>
                  <div className='chart'>
                    <div className='chartcontainer'>
                      {er && !er?.isProfit ? <FailureLinechart /> : <SuccessLinechart />}
                    </div>
                  </div>
                </footer>
              </div>
            </>
          );
        })}
      </div>
      <Modal open={CardModalOpen.open} onClose={handleModalClose}>
        <Box className='email-modal' sx={{ width: '865px !important', minHeight: '45%' }}>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='l-title' sx={{ display: 'flex' }}>
              {'Criteria'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                onClick={handleModalClose}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
          </Typography>
          <Grid container spacing={2}>
            {CardModalOpen?.data && (
              <>
                <Grid item md={6} sm={12} xs={12} sx={{ marginTop: '15px' }}>
                  <TableContainer component={Paper}>
                    <Table className='custom-table' aria-label='simple table'>
                      <TableBody>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Openings Worked
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#F3733F',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.openingsWorked
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            CV Submitted
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#14B8A6',
                              }}>
                              {CardModalOpen && CardModalOpen?.data?.cvSubmitted
                                ? CardModalOpen?.data?.cvSubmitted
                                : '0'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            CRM Reject
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#CBF340',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.crmRejected
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            CRM Validation Pending
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              maxWidth: '350px',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#008FFB',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.crmValidationPending
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            CV Sent
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#F5AA1A',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.cvSent
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            CV Feedback Awaited
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#999999',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.feedbackAwaited
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            CV Shortlisted
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              maxWidth: '350px',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#70A415',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.cvShortlisted
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            CV Rejected
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#C62626',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.cvRejected
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            First Interview Scheduled
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#058DC7',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.firstInterviewScheduled
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ marginTop: '15px' }}>
                  <TableContainer component={Paper}>
                    <Table className='custom-table' aria-label='simple table'>
                      <TableBody>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Second Interview Scheduled
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#F042BC',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.secondInterviewScheduled
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Final Interview Scheduled
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#AF52DE',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.finalInterviewScheduled
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Candidate Interviewed
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#5856D6',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.candidateInterviewed
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Candidate Interview Rejected
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              maxWidth: '350px',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#C79767',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.candidateInterviewRejected
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Candidate Offered
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#52E0E0',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.candidateOffered
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Offered Rejected
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#48D73D',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.offerRejected
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Candidate Joined
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              maxWidth: '350px',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#FFBDED',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.candidateJoined
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                              backgroundColor: '#FDEDE9 !important',
                            },
                          }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              padding: '6.75px 10px !important',
                              fontWeight: '600 !important',
                            }}>
                            Openings Billed
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              textTransform: 'capitalize',
                              lineHeight: '2px !important',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '6.75px 10px !important',
                            }}>
                            <div
                              style={{
                                width: '32px',
                                height: '22px',
                                top: '448px',
                                left: '609px',
                                padding: '10px 5px 0px 5px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                color: '#ffffff',
                                background: '#ABBD75',
                              }}>
                              {CardModalOpen && CardModalOpen?.data
                                ? CardModalOpen?.data?.openingsBilled
                                : '-'}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
