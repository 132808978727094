import React, { useEffect, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
// import { AdminPrivateRoutes } from 'routes/routeConstans';
import ReportIcon from 'assets/iconcomponents/reporticon';
// import CustomBarChart from '../CommonGraph/sideBar';
import Fulllogo from 'assets/png/cthiring_new_logo.png';
import Chart from 'assets/png/chart .svg';
import Photo from 'assets/png/photo.svg';
import Pdf from 'assets/png/pngimg.png';
import { useDebounce } from 'hooks';
import { getAllListData } from 'services/CommonServices';
import { REPORT_ENDPOINT } from 'utils/Constant';
import StackedBarChart from '../Common/barGraph';
import { useAppSelector } from 'store/hooks';
import { ReportFilter } from '../Common/filter';
import CustomBarChart from '../Common/sideBar';

const Defaultparam = {
  fromDate: null,
  toDate: null,
  userId: null,
  branchId: null,
  type: null,
  report: null,
  order: null,
  skip: 0,
  sortby: null,
  status: null,
  take: 10,
  organizationId: null,
  createdBy: null,
};
export const OpeningHandle = () => {
  const { userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [tableData, setTableData] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const listdataapi = () => {
    const searcharr = [
      `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
      `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
      `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
      `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
      `${debouncedParams.report ? `report=${debouncedParams.report}` : null}`,
      `${debouncedParams.employee ? `employee=${debouncedParams.employee}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${REPORT_ENDPOINT.openingHandled}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        setTableData(val.data);
        function getMonthAbbreviation(monthString: string) {
          const date = new Date(monthString + '-01'); // Create a date from YYYY-MM
          return date.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
        }
        function transformData(data: any) {
          return data.map((monthData: { month: any; data: any[] }) => {
            const transformedMonth: any = { name: getMonthAbbreviation(monthData.month) };

            monthData.data.forEach((item: any) => {
              transformedMonth[item.range] = item.openingHandled;
            });
            return transformedMonth;
          });
        }

        const transformedData = transformData(val.data.graphData);
        setGraphData(transformedData);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography color='text.primary'>
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Reports
            </Typography>
          </Breadcrumbs>
        </Typography>
        <ReportFilter setParams={setParams} />
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            {!tableData || !graphData ? (
              <ReportIcon />
            ) : (
              <>
                {params.type === 'ctcWise' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography component={'div'}>
                            <Typography component={'span'} className='report-title'>
                              CTC Wise Monthly
                              <Typography component={'span'} className='report-title-name'>
                                (
                                {userDetail?.roleId === 15
                                  ? 'Administrator'
                                  : userDetail?.roleData.name
                                    ? userDetail?.roleData.name
                                    : ''}
                                : {`${userDetail.firstName} ${userDetail.lastName}`})
                              </Typography>
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <img
                              src={Photo}
                              alt='Photo'
                              style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                            />
                            <img
                              src={Pdf}
                              alt='Pdf'
                              style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <StackedBarChart data={graphData} />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {(params.type === 'clientWise' || params.type === 'monthWise') &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography component={'div'}>
                            <Typography component={'span'} className='report-title'>
                              CTC Wise Monthly
                              <Typography component={'span'} className='report-title-name'>
                                (
                                {userDetail?.roleId === 15
                                  ? 'Administrator'
                                  : userDetail?.roleData.name
                                    ? userDetail?.roleData.name
                                    : ''}
                                : {`${userDetail.firstName} ${userDetail.lastName}`})
                              </Typography>
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <img
                              src={Photo}
                              alt='Photo'
                              style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                            />
                            <img
                              src={Pdf}
                              alt='Pdf'
                              style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <CustomBarChart />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {(params.report === 'table' || params.report === 'all') && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='d-flex flex-sb'
                        sx={{ marginTop: '25px' }}>
                        <Typography component={'div'}>
                          <Typography component={'span'} className='report-title'>
                            CTC Wise Monthly
                            <Typography component={'span'} className='report-title-name'>
                              (
                              {userDetail?.roleId === 15
                                ? 'Administrator'
                                : userDetail?.roleData.name
                                  ? userDetail?.roleData.name
                                  : ''}
                              : {`${userDetail.firstName} ${userDetail.lastName}`})
                            </Typography>
                          </Typography>
                        </Typography>
                        <Typography component={'span'} className='report-title-name'>
                          Timeline :{' '}
                          <Typography component={'span'} className='report-title'>
                            01/04/2024 - 12/11/2024
                          </Typography>
                          <img
                            src={Photo}
                            alt='Photo'
                            style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                          />
                          <img
                            src={Pdf}
                            alt='Pdf'
                            style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                    <Typography component={'div'} className='mt-32'>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          className='custom-table'
                          aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              {tableData?.tableHeaders?.map((header: any) => (
                                <TableCell sx={{ textTransform: 'capitalize' }} key={header}>
                                  {header}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData?.tableData && tableData?.tableData?.length ? (
                              tableData?.tableData?.map((row: any) => (
                                <TableRow key={row.month}>
                                  {/* Month Cell */}
                                  <TableCell>
                                    {row.month
                                      ? row.month
                                      : row.corporateName
                                        ? row.corporateName
                                        : '-'}
                                  </TableCell>

                                  {/* Data Cells */}
                                  {tableData.tableHeaders.slice(1).map((header: any) => {
                                    // Find matching data based on header (range) and display `openingHandled` or '-'
                                    const cellData = row.data.find(
                                      (item: any) => item.range === header,
                                    );
                                    return (
                                      <TableCell key={header}>
                                        {cellData ? cellData.openingHandled : '-'}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={tableData?.tableHeaders?.length} align='center'>
                                  No Record(s) Found.
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Typography>
                  </>
                )}
              </>
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
