import React from 'react';
import HorizontalBarChart from './icon/horizontalbarchart';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import moment from 'moment';
import { AccessDenied } from 'utils/helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const DailyActivity = ({ setLd, option }: any) => {
  const Today = new Date();
  const navigate = useNavigate();
  const [DailyActivityCardData, setDailyActivityCardData] = React.useState<any>([]);

  const DailyActivityData = () => {
    setLd(true);
    getAllListData(
      `${DASHBOARD_ENDPOINT.Category}?type=${option.toLowerCase()}&fromDate=${moment(Today).format(
        'YYYY-MM-DD',
      )}&toDate=${moment(Today).format('YYYY-MM-DD')}`,
      true,
    )
      .then((val: any) => {
        const data = [
          {
            name: 'CV Uploaded',
            color: '#357AF6',
            value: 0,
          },
          {
            name: 'Candidate Interview Scheduled',
            color: '#F59E0B',
            value: Number(val.data.candidateInterviewScheduled),
          },
          {
            name: 'Joined Deferred',
            color: '#C79767',
            value: Number(val.data.joiningDeferred),
          },
          {
            name: 'CV Validated',
            color: '#5CC8BE',
            value: Number(val.data.crmValidated),
          },
          {
            name: 'Candidate Interview Selected',
            color: '#F042BC',
            value: Number(val.data.candidateInterviewSelected),
          },
          {
            name: 'Not Joined',
            color: '#CBF340',
            value: Number(val.data.notJoined),
          },
          {
            name: 'CV Rejected',
            color: '#AF52DE',
            value: Number(val.data.cvRejected),
          },
          {
            name: 'Candidate Interview Rejected',
            color: '#F3733F',
            value: Number(val.data.candidateInterviewRejected),
          },
          {
            name: 'Joined',
            color: '#48D73D',
            value: Number(val.data.joined),
          },
          {
            name: 'CV Sent to Client',
            color: '#F7CB45',
            value: Number(val.data.cvSent),
          },
          {
            name: 'Offer Accepted',
            color: '#33AAFF',
            value: Number(val.data.candidateOffered),
          },
          {
            name: 'Billed',
            color: '#52E0E0',
            value: Number(val.data.openingsBilled),
          },
          {
            name: 'CV Feedback Pending',
            color: '#5856D6',
            value: Number(val.data.feedbackAwaited),
          },
          {
            name: 'Offer Rejected',
            color: '#FFBDED',
            value: Number(val.data.offerRejected),
          },
        ];
        const filteredData = data.filter((item) => item.value > 0);
        setDailyActivityCardData(filteredData);
        setLd(false);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(false);
      });
  };

  React.useEffect(() => {
    DailyActivityData();
  }, [option]);
  const hasData = DailyActivityCardData && DailyActivityCardData.length > 0;
  return (
    <div className='childcon'>
      <header>
        <div className='title'>Daily Activity</div>
        {/* <div className='viewdetail'>As Is Graph</div> */}
      </header>
      {!hasData ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '250px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          No data available
        </p> // Placeholder message
      ) : (
        <>
          <main>
            <div className='barChart'>
              <HorizontalBarChart DailyActivityCardData={DailyActivityCardData} />
            </div>
          </main>
          <footer>
            <div className='con'>
              {DailyActivityCardData.map((ef: any, idx: number) => {
                return (
                  <>
                    <div className='item' key={idx}>
                      <div
                        className='circle'
                        style={{
                          backgroundColor: ef && ef.color ? ef.color : '#357AF6',
                        }}></div>
                      <div className='txt'>{ef && ef.name ? ef.name : ''}</div>
                    </div>
                  </>
                );
              })}
            </div>
          </footer>
        </>
      )}
    </div>
  );
};
