import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import Loader from 'components/loader';
import React, { useCallback, useRef } from 'react';
import CloseIcon from 'assets/png/close.svg';
import { feedBackForm } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/customInput';
import Dropzone from 'react-dropzone';
import { AllType } from 'utils/helper';
import Fileupload from 'assets/png/upload-cloud.svg';
import Wordpng from 'assets/png/Word.png';
import PDFpng from 'assets/png/pngimg.png';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Deletebucket from 'assets/png/Deletebucket.svg';
import { addNewData } from 'services/CommonServices';
import { FEEDBACK_ENDPOINT } from 'utils/Constant';

export const FeedBackSuggestions = () => {
  const [loader, setLoader] = React.useState<any>(false);
  const [fileUpload, setfileupload] = React.useState<any>();
  const [open, setOpen] = React.useState({ open: false, type: '' });
  const hiddenFileInput: any = useRef(null);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    setError,
    // getValues,
    // watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      fileUpload: '',
      subject: '',
      message: '',
      type: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(feedBackForm),
  });
  const handleOpen = (type: string) => {
    setOpen({ open: true, type: type });
  };
  const handleClose = () => {
    resetForm();
    setOpen({ open: false, type: '' });
    setfileupload('');
  };
  const onSubmitForm = (data: any) => {
    console.log(data);

    if (data.type === 'reportbug') {
      if (!fileUpload) {
        setError('fileUpload', {
          type: 'manual',
          message: 'File is required',
        });
      } else {
        setLoader(true);
        const formdata: any = new FormData();
        if (fileUpload[0]) {
          formdata.append('file', fileUpload[0]);
        }
        formdata.append('subject', data.subject);
        formdata.append('message', data.message);
        formdata.append('type', 'reportBug');
        addNewData(
          formdata,
          FEEDBACK_ENDPOINT.reportBug,
          {
            'Content-Type': 'multipart/form-data',
          },
          true,
        )
          .then(() => {
            setLoader(false);
            setOpen({ open: false, type: '' });
            handleClose();
          })
          .catch(() => {
            setLoader(false);
          });
      }
    } else {
      setLoader(true);
      const formdata: any = new FormData();
      formdata.append('message', data.message);
      formdata.append('type', 'feedback');
      addNewData(
        formdata,
        FEEDBACK_ENDPOINT.reportBug,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then(() => {
          setLoader(false);
          setOpen({ open: false, type: '' });
          handleClose();
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (AllType.includes(file[0].type)) {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 2048) {
          setError('fileUpload', {
            type: 'manual',
            message: 'File size should be less than 2MB',
          });
          return;
        }
      } else {
        setError('fileUpload', {
          type: 'manual',
          message: 'Invalid file type. Only DOCX, DOC, PDF, PNG, JPEG and JPG  are allowed.',
        });
        return;
      }
    }
    setError('fileUpload', {
      type: 'manual',
      message: '',
    });
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };
  const handledelete = () => {
    setfileupload('');
  };

  return (
    <Box
      sx={{
        columnGap: '16px',
        rowGap: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px',
      }}>
      <Typography
        className='footer-item boder-right pointer'
        onClick={() => {
          setValue('type', 'feedback');
          handleOpen('feedback');
        }}>
        Feedback /Suggestions
      </Typography>
      <Typography className='footer-item boder-right'>
        <Typography className='footer-item' component={'span'}>
          |
        </Typography>
      </Typography>
      <Typography
        className='footer-item boder-right pointer'
        onClick={() => {
          setValue('type', 'reportbug');
          handleOpen('reportbug');
        }}>
        Report Bug
      </Typography>
      <Modal
        sx={{ zIndex: 1300 }}
        open={open.open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          className='email-modal'
          sx={{
            width: '600px !important',
            minHeight: '500px !important',
          }}>
          {loader && <Loader style={{ height: '100%' }} />}
          <Typography component={'div'} className='d-flex flex-end '>
            <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Typography>
          <Typography className='d-flex-cc'>
            <Typography className='l-title'>
              {open.type === 'feedback' ? 'Feedback / Suggestions' : 'Report Bug'}
            </Typography>
          </Typography>
          {open.type === 'reportbug' ? (
            <div>
              <Grid container className='mt-10'>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <form
                      className='private-form'
                      id={'uploadResume'}
                      onSubmit={handleSubmit(onSubmitForm)}>
                      <Grid container columnSpacing={4.25}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Subject <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name='subject'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    name={name}
                                    value={value}
                                    className='custom-input'
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    placeHolder='Subject'
                                    error={errors.subject?.message}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Message<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name='message'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    isTextArea={true}
                                    className='custom-input custom-input-position'
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    placeHolder='Message'
                                    error={errors.message?.message}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Upload files <span>*</span>
                            </Typography>
                            <Dropzone onDrop={onDrop}>
                              {({ getRootProps, getInputProps }) => {
                                return (
                                  <div
                                    {...getRootProps({
                                      className: `dropzone container ${
                                        errors && errors?.fileUpload && errors?.fileUpload?.message
                                          ? 'container-position-error'
                                          : ''
                                      } container-position pointer`,
                                      onClick: () => {
                                        hiddenFileInput.current?.click();
                                      },
                                    })}>
                                    <div>
                                      <input {...getInputProps()} />
                                      <p className='img-container'>
                                        <img src={Fileupload} alt='file upload' />
                                      </p>
                                      <div className='fil-uplod-desc'>
                                        <p className='first-heading'>
                                          <span className='frst-span' role='button'>
                                            Click to upload
                                          </span>{' '}
                                          <span className='drago'>or drag and drop</span>
                                        </p>
                                        <p className='docx'>
                                          DOCX, DOC, PDF, PNG, JPEG and JPG (up to 2MB)
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }}
                            </Dropzone>
                            {errors?.fileUpload && (
                              <Typography component={'span'} className='Custom-error'>
                                {errors.fileUpload.message?.toString()}
                              </Typography>
                            )}
                          </Typography>
                          <div>
                            {fileUpload && fileUpload.length > 0 ? (
                              <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                                <Typography
                                  component={'div'}
                                  className='custom-field file-view-box'>
                                  <Typography
                                    component={'p'}
                                    className='first-children first-green-children'>
                                    <div className='image-container'>
                                      <span className='image image-success'>
                                        {fileUpload[0]?.type === 'application/pdf' ||
                                        fileUpload[0]?.mimetype === 'application/pdf' ? (
                                          <img
                                            src={PDFpng}
                                            alt='PDFpng'
                                            style={{ height: '20px', width: '20px' }}
                                          />
                                        ) : (
                                          <img src={Wordpng} alt='Wordpng' />
                                        )}
                                      </span>
                                      {/* <span className={success ? 'texted texted-success' : 'texted'}> */}
                                      <span className={'texted texted-success'}>
                                        <TableTooltip
                                          value={
                                            fileUpload && fileUpload[0]
                                              ? fileUpload[0]?.name
                                                ? fileUpload[0]?.name
                                                : fileUpload[0].fileName
                                                  ? fileUpload[0].fileName
                                                  : ''
                                              : ''
                                          }
                                          positionfile={true}></TableTooltip>
                                      </span>
                                    </div>
                                    <div
                                      className='Crossicon Crossicon-deletebucket'
                                      onClick={handledelete}>
                                      <img src={Deletebucket} alt='Crossicon' />
                                    </div>
                                  </Typography>
                                </Typography>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </Grid>
              </Grid>
              <Typography className='d-flex-cc mt-25'>
                <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                  Cancel
                </Button>
                <Button className='l-save-btn' type='submit' form={'uploadResume'}>
                  Submit
                </Button>
              </Typography>
            </div>
          ) : (
            <div>
              <Grid container className='mt-25'>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <form
                      className='private-form'
                      id={'uploadResume'}
                      onSubmit={handleSubmit(onSubmitForm)}>
                      <Grid container columnSpacing={4.25}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Message<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name='message'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    isTextArea={true}
                                    className='custom-input custom-input-position'
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    placeHolder='Message'
                                    error={errors.message?.message}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </Grid>
              </Grid>
              <Typography className='d-flex-cc mt-85'>
                <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                  Cancel
                </Button>
                <Button className='l-save-btn' type='submit' form={'uploadResume'}>
                  Submit
                </Button>
              </Typography>
            </div>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
