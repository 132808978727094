import { Rating, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { PositionENDPOINT } from 'utils/Constant';

export const Consslutant = ({ control, setValue, errors, positionId, clientId, orgId }: any) => {
  const [behaviouralSkills, setBehaviouralSkills] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);

  React.useEffect(() => {
    if (positionId) {
      getAllListData(
        `${PositionENDPOINT.position}?organizationId=${orgId}&clientId=${clientId}&id=${positionId}`,
      )
        .then(async (res: any) => {
          setBehaviouralSkills(res?.data[0]?.behaviouralSkills);
          setTechnicalSkills(res?.data[0]?.technicalSkills);
          const behaviouralSkills = res?.data[0]?.behaviouralSkills.map(
            (re: any, index: number) => {
              if (re) {
                setValue(`behaviouralSkills.${index}.behaviouralSkillsId`, re.id);
              }
              return;
            },
          );
          await Promise.all(behaviouralSkills);
          const technicalSkills = res?.data[0]?.technicalSkills.map((re: any, index: number) => {
            if (re) {
              setValue(`technicalSkills.${index}.technicalSkillsId`, re.id);
            }
            return;
          });
          await Promise.all(technicalSkills);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [positionId]);
  return (
    <Grid container columnSpacing={4.25} className='mt-15'>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>Rate Technical Skills</Typography>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
            columnGap={5}>
            {technicalSkills &&
              technicalSkills.map((re: any, index: number) => {
                return (
                  <div key={index} style={{ width: '30%', marginTop: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                      <Controller
                        defaultValue={0}
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <Rating
                              sx={{ color: '#EE4B22 ', marginRight: '7px' }}
                              name={name}
                              value={value}
                              onChange={(event) => {
                                onChange(event);
                                setValue(`technicalSkills.${index}.technicalSkillsId`, re.id);
                              }}
                            />
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                top: '448px',
                                left: '609px',
                                padding: '0px 6px 5px 6px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                background: '#FDEDE9',
                                fontSize: '15px',
                              }}>
                              {value}
                            </div>
                          </>
                        )}
                        name={`technicalSkills.${index}.rating`}
                        control={control}
                      />
                    </div>
                    <CustomInput
                      disabled
                      value={re.skill}
                      className='custom-input'
                      placeHolder=''
                      error={errors.technicalSkills?.message}
                    />
                  </div>
                );
              })}
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>Rate Behavioural Skills</Typography>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
            columnGap={5}>
            {behaviouralSkills &&
              behaviouralSkills.map((re: any, index: number) => {
                return (
                  <div key={index} style={{ width: '30%', marginTop: '15px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                      }}>
                      <Controller
                        defaultValue={0}
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <Rating
                              sx={{ color: '#EE4B22 ', marginRight: '7px' }}
                              name={name}
                              value={value}
                              onChange={(event) => {
                                onChange(event);
                                setValue(`behaviouralSkills.${index}.behaviouralSkillsId`, re.id);
                              }}
                            />
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                top: '448px',
                                left: '609px',
                                padding: '0px 6px 5px 6px',
                                borderRadius: '4px',
                                border: '1px solid #FDEDE9',
                                gap: '6px',
                                background: '#FDEDE9',
                                fontSize: '15px',
                              }}>
                              {value}
                            </div>
                          </>
                        )}
                        name={`behaviouralSkills.${index}.rating`}
                        control={control}
                      />
                    </div>
                    <CustomInput
                      disabled
                      value={re.skill}
                      className='custom-input'
                      placeHolder=''
                      error={errors.behaviouralSkills?.message}
                    />
                  </div>
                );
              })}
          </Typography>
        </Typography>
      </Grid>
      <Grid container item columnSpacing={4.25}>
        <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>
              Noteworthy Contributions / Achievements <span>*</span>
            </Typography>
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <CustomInput
                  value={value ? value : ''}
                  name={name}
                  isTextArea={true}
                  className='custom-input custom-input-position'
                  placeHolder='Noteworthy Contributions / Achievements'
                  error={errors.achievements?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              name='achievements'
              control={control}
            />
          </Typography>
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>
              Reasons for Shortlisting <span>*</span>
            </Typography>
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <CustomInput
                  value={value ? value : ''}
                  name={name}
                  isTextArea={true}
                  className='custom-input custom-input-position'
                  placeHolder='Reasons for Shortlisting'
                  error={errors.consultantAssessment?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              name='consultantAssessment'
              control={control}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid container item columnSpacing={4.25}>
        <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>
              Interview Availability <span>*</span>
            </Typography>
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <CustomInput
                  value={value ? value : ''}
                  name={name}
                  isTextArea={true}
                  className='custom-input custom-input-position'
                  placeHolder='Interview Availability'
                  error={errors.interviewAvailability?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              name='interviewAvailability'
              control={control}
            />
          </Typography>
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>Any Other Inputs</Typography>
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <CustomInput
                  value={value ? value : ''}
                  name={name}
                  isTextArea={true}
                  className='custom-input custom-input-position'
                  placeHolder='Any Other Inputs'
                  error={errors.comments?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              name='comments'
              control={control}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
