import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import TagsAnnotator from './icon/tagsAnnotator';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Joinee = ({ resumeData }: any) => {
  const JoineesFinalData = resumeData && resumeData.length > 0;

  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Joinees</div>
      </header>

      {!JoineesFinalData ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '225px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          No data available
        </p> // Placeholder message
      ) : (
        <main>
          <div
            className='donutchartout'
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '50px',
              height: '300px',
            }}>
            <ResponsiveContainer width='100%' height='100%'>
              <PieChart>
                <Pie
                  data={resumeData}
                  cx='50%'
                  cy='50%'
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill='#8884d8'
                  dataKey='value'>
                  {resumeData.map((entry: { color: string | undefined }, index: any) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className='labelcon'>
            {resumeData.map(
              (
                er: { name: string; color: string | undefined },
                idx: React.Key | null | undefined,
              ) => (
                <div className='labelItem' key={idx}>
                  <TagsAnnotator txt={er.name} bg={er.color} />
                </div>
              ),
            )}
          </div>
        </main>
      )}
    </div>
  );
};

export default Joinee;
