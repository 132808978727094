// CustomDateRangePicker.tsx
import React, { useState } from 'react';
import { DateRangePicker } from '../DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';
import './customDateRangePicker.scss';

interface CustomDateRangePickerProps {
  onChange?: (dates: (Dayjs | null)[] | null, dateStrings: [string, string]) => void;
  placeholder?: string;
  definedRanges?: Array<{ label: string; startDate: Date; endDate: Date }>;
  value?: any;
  name?: any;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  onChange,
  placeholder = 'From Date - To Date',
  value,
  name,
  definedRanges = [
    {
      label: 'Last 7 days',
      startDate: dayjs().subtract(6, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 14 days',
      startDate: dayjs().subtract(13, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 30 days',
      startDate: dayjs().subtract(29, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 3 months',
      startDate: dayjs().subtract(3, 'months').subtract(1, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 12 months',
      startDate: dayjs().subtract(12, 'months').subtract(1, 'days').toDate(),
      endDate: new Date(),
    },
  ],
}) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{ startDate?: Date; endDate?: Date }>({});
  const [key, setKey] = useState(0); // key to re-render DateRangePicker

  const handleRangeChange = (range: { startDate?: Date; endDate?: Date }) => {
    setDateRange(range);
    if (onChange && range.startDate && range.endDate) {
      onChange(
        [dayjs(range.startDate), dayjs(range.endDate)],
        [dayjs(range.startDate).format('YYYY-MM-DD'), dayjs(range.endDate).format('YYYY-MM-DD')],
      );
    }
    setOpen(false);
  };

  const toggle = () => setOpen(!open);

  const clearDateRange = () => {
    setDateRange({});
    setKey((prevKey) => prevKey + 1); // Update the key to re-render DateRangePicker
    onChange && onChange(null, ['', '']);
  };

  const formatDateRange = () => {
    if (dateRange.startDate && dateRange.endDate) {
      return `${dayjs(dateRange.startDate).format('DD-MM-YYYY')} - ${dayjs(
        dateRange.endDate,
      ).format('DD-MM-YYYY')}`;
    }
    return '';
  };

  return (
    <div className='custom-date-picker-wrapper'>
      <div className='input-with-icon'>
        <input
          type='text'
          value={formatDateRange()}
          onClick={toggle}
          readOnly
          className='custom-date-input'
          placeholder={placeholder}
        />
        {formatDateRange() && <CancelIcon onClick={clearDateRange} className='clear-icon' />}
      </div>
      <DateRangePicker
        initialDateRange={value}
        name={name}
        key={key} // Key to re-render the component
        open={open}
        toggle={toggle}
        onChange={handleRangeChange}
        definedRanges={definedRanges}
        maxDate={new Date()}
        wrapperClassName='custom-date-range-picker'
      />
    </div>
  );
};

export default CustomDateRangePicker;
