import BackImg from 'assets/png/interior.png';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import { Grid, Link, Typography } from '@mui/material';
// import { generateOtp } from 'utils/ValidatorSchema';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { Controller } from 'react-hook-form';
// import CustomInput from 'components/CustomInput/customInput';
// import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addNewData } from 'services/CommonServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';
import { useState } from 'react';
import EmailIcon from 'assets/png/email.svg';
import Loader from 'components/loader';
import CustomInput from 'components/CustomInput/customInput';

const GenerateOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const onSubmit = () => {
    setLoader(true);
    addNewData(
      {
        email: location.state.email,
        resendType: false,
      },
      `${String(MASTER_ENDPOINT.generateOtp)}`,
      {},
      true,
    )
      .then(() => {
        setLoader(false);
        // toast.success('Branch Created Successfully', alertOptions);
        sessionStorage.removeItem('otpTimeCounter');
        navigate(PublicRoutes.VERIFYOTP, {
          state: {
            email: location.state.email,
          },
        });
      })
      .catch(() => {
        setLoader(false);
        // console.log(e);
      });
  };

  return (
    <Typography
      component={'div'}
      sx={{ backgroundImage: `url(${BackImg})` }}
      className='login-banner'>
      {loader && <Loader />}
      <Grid container className='px-40'>
        <Grid item md={6} sm={12} xs={12} className=''>
          <Typography component={'div'} className='pl-15'>
            <Typography className='mb-36 mt-5r'>
              <img src={CTLogo} alt='Carrier Tree' />
            </Typography>
            <Typography className='banner-text'>
              We have the <span>capabilities</span> and <span>experience</span> to navigate you in
              <span>finding solutions</span> you need to move forward
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='login-form d-flex-a'
            style={{ padding: '60px 0px 0px 40px', width: '400px', minHeight: '500px' }}>
            <Typography component={'div'} className='login-box for-log-box'>
              <Typography className='mb-36'>
                <img src={CTLogo} alt='Carrier Tree' style={{ width: '142px', height: '22px' }} />
              </Typography>
              <form>
                <Typography component={'div'} className='custom-field' style={{ width: '100%' }}>
                  <Typography className='otp-text' component={'p'}>
                    Verify your identify.
                  </Typography>
                  {/* <TextField className="custom-input" id="standard-basic" placeholder="Enter your email" variant="standard" /> */}
                  <Typography className='signup-txt d-flex pointer' onClick={onSubmit}>
                    <img src={EmailIcon} alt='email' className='mr-10' />
                    <Link
                      sx={{
                        color: '#ee4b22', // Default text color
                        textDecoration: 'underline',
                        '&:hover': {
                          color: '#ee4b22', // Hover text color
                        },
                      }}>
                      {' '}
                      Click here send code to your email
                    </Link>
                  </Typography>
                  <CustomInput
                    value={location.state.email}
                    className='custom-input'
                    id='standard-basic'
                    disabled
                  />
                </Typography>
                {/* <Typography className='custom-field'>{location.state.email}</Typography> */}
                {/* <Typography className='signup-txt d-flex mb-7 pointer'>
                  <TextsmsOutlinedIcon className='mr-10' />
                  Text XXXXXXXXX05
                </Typography> */}
              </form>
            </Typography>

            {/* <Typography className="w-100 text-left foot-text">© CT - Hiring 2023</Typography> */}
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default GenerateOtp;
