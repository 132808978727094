
function FilterSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="44"
      fill="none"
      viewBox="0 0 56 44"
    >
      <g filter="url(#filter0_d_11958_44440)">
        <rect width="52" height="40" x="2" y="1" fill="#fff" rx="6"></rect>
        <rect
          width="51"
          height="39"
          x="2.5"
          y="1.5"
          stroke="#D0D5DD"
          rx="5.5"
        ></rect>
        <path
          fill="#344051"
          d="M35.803 14.293c.126.305.074.565-.157.78l-5.502 5.503v8.281c0 .313-.145.532-.435.659a.776.776 0 01-.28.056c-.2 0-.367-.071-.501-.212l-2.858-2.857a.687.687 0 01-.212-.503v-5.424l-5.502-5.502c-.23-.216-.283-.476-.156-.781.127-.29.346-.436.659-.436h14.285c.313 0 .532.146.659.436z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_11958_44440"
          width="56"
          height="44"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11958_44440"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_11958_44440"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default FilterSvgIcon;
