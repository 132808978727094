import { Drawer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MyplanFilterSchema } from 'utils/ValidatorSchema';
import { MyplanFilterType } from 'types';
import { PlanFilterForm } from './PlanFilterForm';

interface ClientFilterDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  setParams?: any;
  setcommonstate?: any;
  commonState?: any;
  setLoading?: any;
  isLoading?: any;
  setpagetotal?: any;
  listdataapi?: any;
}

export const PlanFilterDrawer: React.FC<ClientFilterDrawerProps> = ({
  isOpen,
  closeDrawer,
  setParams,
  // setcommonstate,
  // setLoading,
  // setpagetotal,
  listdataapi,
}) => {
  const [key, setKey] = useState(0);
  const { control, handleSubmit, reset, formState, watch, trigger } = useForm<MyplanFilterType>({
    criteriaMode: 'all',
    defaultValues: {
      fromDate: undefined,
      toDate: undefined,
      session: undefined,
      workType: undefined,
      clientId: undefined,
      workPlan: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(MyplanFilterSchema),
  });

  const closeSideDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const resetFilters = useCallback(() => {
    setParams((prev: any) => ({
      ...prev,
      fromDate: null,
      toDate: null,
      session: null,
      workType: null,
      clientId: null,
      workPlan: null,
    }));
    reset();
    listdataapi();
    setKey((prev: number) => prev + 1);
  }, [reset]);

  const onSubmit = (data: any) => {
    if (
      data.fromDate ||
      data.toDate ||
      data.session ||
      data.workType ||
      data.clientId ||
      data.workPlan
    ) {
      setParams((prev: any) => ({
        ...prev,
        fromDate: data.fromDate ? data.fromDate : null,
        toDate: data.toDate ? data.toDate : null,
        session: data.session ? data.session : null,
        workType: data.workType ? data.workType : null,
        clientId: data.clientId ? data.clientId : null,
        workPlan: data.workPlan ? data.workPlan : null,
      }));
    }
  };

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        ['& .MuiDrawer-paper']: {
          boxSizing: 'border-box',
          marginTop: '72px',
          width: '300px',
          padding: '30px',
          paddingTop: '0px',
        },
        ['& .MuiModal-backdrop ']: { backgroundColor: 'transparent' },
      }}>
      <header className='clientfilter-header'>
        <img
          src={CloseIcon}
          alt='closeicon'
          className='clientfilter-close-ico'
          onClick={closeSideDrawer}
        />
        <div className='heading'>Filters</div>
        <span
          className='clearall'
          style={{
            ...(!(
              watch('fromDate') ||
              watch('toDate') ||
              watch('clientId') ||
              watch('employee') ||
              watch('session') ||
              watch('workPlan') ||
              watch('workType')
            )
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}
          onClick={() =>
            watch('fromDate') ||
            watch('toDate') ||
            watch('clientId') ||
            watch('employee') ||
            watch('session') ||
            watch('workPlan') ||
            watch('workType')
              ? resetFilters()
              : ''
          }>
          Clear All
        </span>
      </header>
      <div className='clientfilter-divider'></div>
      <PlanFilterForm
        setParams={setParams}
        key={key}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        onSubmit={onSubmit}
        watch={watch}
        trigger={trigger}
      />
    </Drawer>
  );
};
