import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Controller, useForm } from 'react-hook-form';
import { DesignationYup } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
// import { toast } from 'react-toastify';
import { updateData, addNewData } from 'services/CommonServices';
// import { alertOptions } from 'utils/Config';
import { AdminPrivateRoutes } from 'routes/routeConstans';
// import { toast } from 'react-toastify';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const DesignationAdd = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const subtgref: React.MutableRefObject<any> = useRef();
  const [Ld, setLd] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { editId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationstate = location.state;
  const locationpathname = location.pathname;
  const locationbool: boolean =
    locationpathname === AdminPrivateRoutes.SETTINGS.Designation.DesignationEDIT;
  const gotoNavigate = (link: string) => {
    navigate(link);
  };
  const {
    handleSubmit,
    control,
    // reset: resetForm,
    formState: { errors },
    setValue,
  } = useForm<{
    organizationId: string;
    name: string;
    type: any;
    status: NonNullable<boolean | undefined>;
  }>({
    defaultValues: {
      organizationId: orgdy ? orgdy : '',
      name: undefined,
      type: undefined,
      status: !false,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(DesignationYup),
  });

  const onSubmitForm = async (data: any) => {
    // console.log(data, 'data');
    setLd(true);
    try {
      const datas =
        locationstate && Object.keys(locationstate).length > 0 && locationbool
          ? await updateData(locationstate.id, data, 'designation', true)
          : await addNewData(data, 'designation', '', true);
      // console.log(datas, 'datsd');
      if (datas && datas.status >= 200 && datas.status < 300) {
        gotoNavigate(AdminPrivateRoutes.SETTINGS.Designation.DesignationLIST);
        // toast.success(datas && datas.message ? datas.message : '', alertOptions);
      }
    } catch (e: any) {
      if (e === 'Forbidden resource') {
        toast.error(AccessDenied);
        navigate('/dashboard');
      }
      // toast.error(er || String(er), alertOptions);
      // console.log(er, 'er');
    } finally {
      setLd(!true);
    }
  };

  useEffect(() => {
    // if (errors) {
    //   console.log(errors, 'errors');
    // }
    if (locationstate && Object.keys(locationstate).length > 0 && locationbool) {
      // console.log(locationstate, 'locationstate');
      setValue('organizationId', locationstate.organizationId);
      setValue('name', locationstate.name);
      setValue('type', locationstate.type);
      setValue('status', locationstate.status);
    }

    if (!locationstate && locationbool) {
      gotoNavigate(AdminPrivateRoutes.SETTINGS.Designation.DesignationLIST);
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href={AdminPrivateRoutes.SETTINGS.Designation.DesignationLIST}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Designation
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {locationbool ? 'Edit' : 'Add'} Designation
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>
              {locationbool ? 'Edit' : 'Add'} Designation
            </Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => navigate(AdminPrivateRoutes.SETTINGS.Designation.DesignationLIST)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  subtgref.current.click();
                }}
                className='s-add-btn'
                type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form
                className='private-form'
                id={'branch-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Designation Name <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='name'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Designation name'
                                  error={errors.name?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Designation Type <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='type'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={[
                                    { label: 'Client', value: 'client' },
                                    { label: 'Candidate', value: 'candidate' },
                                  ]}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Select your designation type'
                                  error={errors.type?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Status <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='status'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <RadioGroup
                                  className='custom-radio'
                                  row
                                  aria-labelledby=''
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='Active'
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='Inactive'
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* this is for dum,m,y to trigger form */}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  ref={subtgref}
                  sx={{ display: 'none' }}>
                  Submit
                </Button>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};
export default DesignationAdd;
